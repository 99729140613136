import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export const capitalizeFirstLetter = (string) => {
  if (string == "") return;
  else {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
};

export const stringLimit = (string) => {
  if (string.length >= 25) {
    return string.substring(0, 100) + "...";
  } else {
    return string.substring(0, 100);
  }
};
export const stringLimit1 = (string) => {
  if (string.length >= 25) {
    return string.substring(0, 30) + "...";
  } else {
    return string.substring(0, 100);
  }
};
export const downloadExcel = (data, name) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, `${name}.xlsx`);
};

export const getPermissionDetails = (modulePath, permissions) => {
  const data = permissions.find(
    (item) => item.modulePath === modulePath.slice(1, modulePath.length)
  );
  return data || false;
};

export const downloadExcel1 = (dataArray, namesArray, headingsArray) => {
  const workbook = XLSX.utils.book_new();

  dataArray.forEach((data, index) => {
    // Calculate the start row of the table
    const startRow = index === 0 ? 0 : dataArray[index - 1].length + 2;

    const worksheet = XLSX.utils.json_to_sheet(data, {
      header: headingsArray[index],
    });

    // Add the worksheet with appropriate start row
    XLSX.utils.book_append_sheet(workbook, worksheet, namesArray[index], {
      origin: `A${startRow}`,
    });
  });

  XLSX.writeFile(workbook, "MultiTableReport.xlsx");
};
