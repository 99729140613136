import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import { Link } from "react-router-dom";
import {
  capitalizeFirstLetter,
  downloadExcel1,
  getPermissionDetails,
} from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import { useSelector } from "react-redux";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { BASEURL } from "../../constant/api";

const OrderList = (props) => {
  const { userId, name } = useParams();
  const { request: request, response: response } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Booking Id" },
    { title: "Outlet Id" },
    { title: "Bookin Type" },
    { title: "Address" },
    { title: "Date" },
    { title: "City" },
    { title: "Country" },
    { title: "Cover Image" },
    { title: "is Feedback" },
    { title: "is Verified" },
    { title: "Status" },
    { title: "Action" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Users - Sportyfy";
    request(
      "GET",
      `admin/user/orderHistory?userId=${userId}&page=${page}&per_page=${perPage}`
    );
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    let searchString = search;

    if (e.target.name == "search") {
      setSearch(e.target.value);
      searchString = e.target.value;
    }
    searchString = searchString ?? "";
    request(
      "GET",
      `admin/user/orderHistory?userId=${userId}&bookingId=${searchString}&page=${page}&per_page=${perPage}`
    );
  };

  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        setData(response.data);
        setTotalDocuments(response.totalDocuments);
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request(
      "GET",
      `admin/user/orderHistory?userId=${userId}&searchString=${search}&page=${
        selected + 1
      }&per_page=${perPage}`
    );
  };

  const handleStatus = (id, status) => {
    if (status == false) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to unblock this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Unblock!",
      }).then(function (result) {
        if (result.value) {
          requestStatus("POST", "admin/user/blockUser", {
            userId: id,
            status: false,
          });
        }
      });
    } else if (status == true) {
      Swal.fire({
        title: "Are you sure you want to block user?",
        text: "Enter reason to block",
        input: "text",
        inputPlaceholder: "Enter text here",
        inputAttributes: {
          //   maxlength: 8,
          autocapitalize: "off",
          autocorrect: "off",
          required: "true",
        },
        showCancelButton: true,
        inputValidator: (value) => {
          if (value === "") {
            return "Please enter reason!";
          }
        },
        confirmButtonText: "Yes, Block",
        confirmButtonColor: "#3ea99f",
      }).then((result) => {
        if (result.value) {
          const blockReason = result.value;
          requestStatus("POST", "admin/user/blockUser", {
            userId: id,
            status: "blocked",
            blockReason: blockReason,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.status) {
        const { id, updatedStatus } = responseStatus;
        toast.success("User status has been updated successfully.");

        const oldRecords = data;
        const indexToChange = oldRecords.findIndex((r) => r._id == id);
        oldRecords[indexToChange].status = updatedStatus;

        setData(oldRecords);
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);

  const DownloadExcel = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      const response = await axios.get(
        `${
          BASEURL.PORT
        }/admin/user/orderHistory?userId=${userId}&page=${""}&per_page=${""}&isExcelDownload=${true}`,
        config
      );
      if (response?.data?.status == `success`) {
        let Ranking = response?.data?.data?.map((item, index) => ({
          "Sr.": index + 1,
          "Booking Id": item?.bookingId,
          "Booking Type": item?.bookingType,
          city: item.city,
          country: item.country,
          "Date Time": item.dateTime,
          "Is Feedback": item.isFeedback ? "Yes" : "No",
          "Is Verified": item.isVerified,
          "outlet Id": item.outletId,
          status: item.status,
        }));

        const dataArray = [Ranking];
        const namesArray = ["Orders"];
        const headingsArray = [null, null]; // Assuming no headers for the data

        downloadExcel1(dataArray, namesArray, headingsArray);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={`${name}'s Order`}
            links={[{ to: "/", name: "Home" }]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-6">
                  <h6>{`${name}'s Order`}</h6>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      name="search"
                      placeholder="Search By Booking Id"
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex">
                    <button
                      className="mx-3 btn btn-primary burron"
                      onClick={DownloadExcel}
                    >
                      Download Excel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data?.length > 0 &&
                      data.map((item, i) => (
                        <tr key={i}>
                          <th scope="row">{i + startIndex}</th>
                          <td>{item.bookingId}</td>
                          <td>{item.outletId}</td>

                          <td>{item.bookingType}</td>
                          <td>{item.address}</td>
                          <td>{item.dateTime}</td>
                          <td>{item.city}</td>
                          <td>{item.country}</td>

                          <td>
                            <img
                              src={item.coverImage}
                              className="img-fluid"
                              height={"100px"}
                              width={"100px"}
                            />
                          </td>

                          <td>{item.isFeedback ? "Yes" : "No"}</td>

                          <td>{item.isVerified}</td>
                          <td>
                            {item.status == "blocked" ? "Blocked" : "Unblocked"}
                          </td>

                          <td>
                            <Link to={`/BookingDetail/${item.bookingId}`}>
                              <i
                                title="View"
                                className="flaticon-information"
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No users has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default OrderList;
