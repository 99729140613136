import React, { Component } from "react";

const Trendingorder = (props) => {
    return (
        <div className="ms-panel">
            <div className="ms-panel-header">
                <h6>Trending {props.name}</h6>
            </div>
            <div className="ms-panel-body">
                <div className="row">
                    {props.data.map((item, i) => (
                        <div key={i} className={item.resposnivecls}>
                            <div className="ms-card no-margin" style={{ marginLeft: "30px" }}>
                                {item.coverImage && (
                                    <div className="ms-card-img" style={{ height: "125px", width: "186px" }}>
                                        <img src={item.coverImage} alt="card_img" />
                                    </div>
                                )}
                             
                                <div className="ms-card-body">
                                    <div className="ms-card-heading-title">
                                        <h6>{item.name}</h6>
                                        <span className="green-text">
                                            <strong>{item.address}</strong>
                                        </span>
                                    </div>
                                  
                                        <div className="ms-card-heading-title">
                                            <p>
                                               <span className="red-text"></span>
                                            </p>
                                            <p>
                                            Rating <span className="red-text">{item.rating ? item.rating:''}</span>
                                            </p>
                                        </div>
                                   
                                    
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Trendingorder;
