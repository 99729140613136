import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Recentorder from './Recentorder';
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";
// import Chart from './Chart';
import Trendingorder from "./Trendingorder";
// import Orderchart from './Orderchart';
// import Restaurantlisting from './Restaurantlisting';
import { useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import Chart from "./Chart";

import Barchart from "../../components/Barchart/Barchart";
import Groupedbarchart from "../../components/Groupedbarchart/Groupedbarchart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import LineChart from "./LineChart";
import { downloadAllData, downloadExcel, downloadExcel1 } from "../../util/fn";

const Dashboard = () => {
  const [rankingGraph, setRankingGraph] = useState([]);
  const [rankinglabel, setRankingLabel] = useState([]);
  const [commissiondata, setCommissionData] = useState([]);
  const [commissionLabel, setCommissionLabel] = useState([]);
  const [promotionOultet, setpromotionOutlet] = useState([]);
  const [promotionLabel, setPromotionLabel] = useState([]);
  const [discountOultet, setDiscontOutlet] = useState([]);
  const [discountLabels, setDiscountLables] = useState([]);
  const { request, response } = useRequest();
  const { request: requestCities, response: responseCities } = useRequest();
  const { name } = useSelector((state) => state.auth);
  const [data, setData] = useState({});
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [oneYearDate, setOneYearDate] = useState("");
  const history = useHistory();
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");

  useEffect(() => {
    requestCities("GET", "admin/vendor/getCity");
    request("POST", "admin/dashboard/getAllData", {
      cityId: "",
      startDate: "",
      endDate: "",
    });
    // request("GET", "admin/dashboard/getAllData?cityId=f&startDate=&endDate=");
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        setData(response?.data ? response.data : {});
        let data = response.data?.topRankingOutlet?.map(
          (val, i) => val?.totalStar
        );
        let data1 = response.data?.topCommissionOutlet?.map(
          (val, i) => val?.commission
        );
        let data2 = response.data?.topPromotionOutlet?.map((val, i) =>
          Number(val?.count)
        );
        let data3 = response.data?.topDiscountOutlet?.map((val, i) =>
          Number(val?.maxDiscount)
        );

        let lablel1 = response.data?.topRankingOutlet?.map(
          (val, i) => val?.outletName
        );
        let lablel2 = response.data?.topPromotionOutlet?.map(
          (val, i) => val?.data
        );
        let lablel3 = response.data?.topCommissionOutlet?.map(
          (val, i) => val?.name
        );
        let lablel4 = response.data?.topDiscountOutlet?.map(
          (val, i) => val?.name
        );
        setRankingGraph(data);
        setCommissionData(data1);
        setDiscontOutlet(data3);
        setpromotionOutlet(data2);

        setCommissionLabel(lablel3);
        setRankingLabel(lablel1);
        setDiscountLables(lablel4);
        setPromotionLabel(lablel2);
      }
    }
  }, [response]);

  const handleSearch = () => {
    // e.preventDefault();
    let searchCity = city;

    searchCity = searchCity ?? "";
    const startDate = maxStartDate;
    const endDate = minEndDate;
    request("POST", "admin/dashboard/getAllData", {
      cityId: searchCity,
      startDate,
      endDate,
      area: area,
    });
  };

  useEffect(() => {
    handleSearch();
  }, [city, minEndDate, maxStartDate, area]);

  const handleEndDate = (e) => {
    const selectedStartDate = e.target.value;
    setMinEndDate(selectedStartDate);
  };

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setMaxStartDate(selectedStartDate);

    const oneYearLater = new Date(selectedStartDate);
    oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

    // Format the date to YYYY-MM-DD
    const formattedMaxDate = oneYearLater.toISOString().split("T")[0];
    setOneYearDate(formattedMaxDate);
  };
  const handleNavigate = (navigateTo) => {
    switch (navigateTo) {
      case "totalAccepted":
        history.push({
          pathname: "/vendors",
          state: { status: "accepted", cityId: city },
        });
        break;
      case "totalRegister":
        history.push({
          pathname: "/vendors",
          state: { status: "", cityId: city },
        });
        break;
      case "totalRejected":
        history.push({
          pathname: "/vendors",
          state: { status: "rejected", cityId: city },
        });
        break;
      case "totalUser":
        history.push({
          pathname: "/users",
          state: { cityId: city },
        });
        break;

      default:
        break;
    }
  };

  const DownloadExcel = async () => {
    let Ranking = response.data?.topRankingOutlet?.map((obj, index) => ({
      "Sr.": index + 1,
      OutletId: obj?.outletId,
      OutletName: obj?.outletName,
      TotalStar: obj?.totalStar,
    }));
    let Commission = response.data?.topCommissionOutlet?.map((obj, index) => ({
      "Sr.": index + 1,
      Name: obj?.name,
      Commission: obj?.commission,
    }));

    const dataArray = [Ranking, Commission];
    const namesArray = ["Ranking", "Commission"];
    const headingsArray = [null, null]; // Assuming no headers for the data

    downloadExcel1(dataArray, namesArray, headingsArray);
  };

  const handleArea = async (e) => {
    setArea(e.target.value);
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-4">
          <h1 className="db-header-title">Welcome, {name}</h1>
        </div>
        <div
          className="col-md-8"
          //  style={{ textAlign: "end" }}
        >
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="cities">Select City</label>
              <Select
                options={
                  cities &&
                  cities.length > 0 &&
                  cities.map((obj) => ({
                    label: obj.name,
                    value: obj._id,
                  }))
                }
                name="cities"
                value={
                  cities &&
                  cities.length > 0 &&
                  cities
                    .filter((item) => item?._id === city)
                    .map((item) => {
                      return { label: item.name, value: item?._id };
                    })[0]
                }
                onChange={(value) => {
                  const startDate = document.getElementById("startDate").value;
                  const endDate = document.getElementById("endDate").value;
                  setCity(value.value);
                  request("POST", "admin/dashboard/getAllData", {
                    cityId: value.value,
                    startDate,
                    endDate,
                  });
                }}
              />
            </div>

            <div className="col-md-3">
              <div className="startDate">
                <label htmlFor="validationCustom01" class="form-label">
                  Area
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="area"
                  name="area"
                  placeholder="area"
                  max={area}
                  onChange={handleArea}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="startDate">
                {/* date picker input */}
                <label htmlFor="validationCustom01" class="form-label">
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  name="startDate"
                  placeholder="Start Date"
                  max={maxStartDate}
                  onChange={handleStartDateChange}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="endDate">
                <label htmlFor="validationCustom01" class="form-label">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="endDate"
                  disabled={maxStartDate ? false : true}
                  name="endDate"
                  placeholder="End Date"
                  max={oneYearDate}
                  value={minEndDate}
                  onChange={handleEndDate}
                  // onChange={filterData}
                />
              </div>
              <button className="mx-3 btn btn-primary" onClick={DownloadExcel}>
                Download Excel
              </button>
            </div>
          </div>
        </div>
        <div className="col-12" style={{ height: "60px" }}></div>
        <div className="col-12">
          <Chart dashboardData={data} handleNavigate={handleNavigate} />
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <Link to={`/vendors?query=ranking`}>
                <h6>Ranking</h6>
              </Link>
            </div>
            {rankingGraph && (
              <div className="ms-panel">
                <LineChart data={rankingGraph} labels={rankinglabel} />
              </div>
            )}
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Commission</h6>
            </div>
            {commissiondata && (
              <div className="ms-panel">
                <LineChart
                  data={commissiondata}
                  labels={commissionLabel}
                  name="Commission"
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-xl-12 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Top OutLet Discount</h6>
            </div>
            {commissiondata && (
              <div className="ms-panel">
                <LineChart
                  data={commissiondata}
                  labels={rankinglabel}
                  name="Commission"
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-xl-12 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Top Promotion Outlet</h6>
            </div>
            {commissiondata && (
              <div className="ms-panel">
                <LineChart
                  data={commissiondata}
                  labels={commissionLabel}
                  name="Commission"
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-xl-6 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Revenue</h6>
            </div>
            {data?.revenueGraph && (
              <div className="ms-panel">
                <Barchart
                  data={data?.revenueGraph}
                  about={"Revenue"}
                  isHoriz={false}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>User Growth</h6>
            </div>
            {data?.userGrowthGraph && (
              <div className="ms-panel">
                <Barchart
                  data={data?.userGrowthGraph}
                  about={"User Growth"}
                  isHoriz={false}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Vendor Growth</h6>
            </div>
            {data?.vendorGrowthGraph && (
              <div className="ms-panel">
                <Barchart
                  data={data?.vendorGrowthGraph}
                  about={"Vendor Growth"}
                  isHoriz={false}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <Trendingorder
            data={data?.topOutlets ? data.topOutlets : []}
            name={"Top Outlets"}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
