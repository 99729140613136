import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AddCommsionControl = ({ show, handleClose, handleSave, initialData }) => {
  const [percent, setAmount] = useState(initialData?.percent || "");

  const handleSubmit = () => {
    const formData = { percent };
    handleSave(formData);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Comission Control Panel</Modal.Title>
        <span onClick={handleClose} style={{ cursor: "pointer" }}>
          X
        </span>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formAmount">
            <Form.Label>Percent</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter percent"
              value={percent}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCommsionControl;
