import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        resetField
      } = useForm();

    const dispatch = useDispatch();

    const { response, request } = useRequest();
    const [touchedError, setTouchedError]               = useState(false)

    useEffect(() => {
        document.title = "Forgot Password - Sportyfy";
      }, []);

    const onSubmit = (data) => {
        const { email } = data;
        request("POST", "admin/forgotPassword", { email});
    };

    useEffect(() => {
        if (response) {
            if(response.status){
                toast.success(response.message);
                resetField("email");
            }
        }
      }, [response]);

    return (
        <div className="ms-content-wrapper ms-auth">
        <div className="ms-auth-container">
        
            <div className="ms-auth-col">
                <div className="ms-auth-form">
                    <form className="needs-validation" noValidate  onSubmit={handleSubmit(onSubmit)}>
                        {/* <div className="mb-3"><img src={"/assets/img/sortyfy2.jpeg"} /></div> */}
                        <h3>Forgot Password?</h3>
                        <p>No Worries! Just enter your email and we'll send you a reset password link.</p>
                        <div className="mb-3">
                            <label htmlFor="email">Email Address<span className="field-required">*</span></label>
                            {/* className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 ${
                    errors.email && "is-invalid"
                  }`} */}
                            <div className="input-group">
                                <input type="email" name="email"  id="email" placeholder="Email Address" 
                                className={`form-control ${
                                    touchedError ? ( errors.email ? "is-invalid" : "is-valid" ): "" 
                                  }`}
                                {...register("email", {
                                    required: true,
                                    pattern:
                                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                  })}
                                />
                                {errors.email?.type === "required" && (
                                    <div className="invalid-field">
                                        The email field is required.
                                    </div>
                                )}
                                {errors.email?.type === "pattern" && (
                                    <div className="invalid-field">
                                        The email must be a valid email address.
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-primary mt-4 d-block w-100" type="submit" onClick={() => setTouchedError(true)}>Submit</button>
                            </div>
                            <div className="col-md-3">
                                <Link className="btn btn-primary mt-4 d-block w-100" to="/login">Cancel</Link>
                            </div>
                            <div className="col-md-8"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ForgotPassword
