import React from 'react'
import ReactPaginate from "react-paginate";

const Pagination = ({
    page,
    totalDocuments,
    getNewData,
    perPage
  }) => {
  return (
    <nav aria-label="Page navigation" style={{float:"right"}}>
            <ReactPaginate
              forcePage={page - 1}
              pageCount={Math.ceil(totalDocuments / perPage)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              previousLabel={"«"}
              nextLabel={"»"}
              breakLabel={"..."}
              onPageChange={getNewData}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination pagination-round has-gap"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
     </nav>
  )
}

export default Pagination
