// BarChart.js

import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({ categories, series, title }) => {

  const [seriess, setSeriesData] = useState([])
  useEffect(() => {
    setSeriesData([series])
  }, [series,categories])

  // Chart options
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar:false
    },
    xaxis: {
      categories: categories,
    },
    
  };


  return (
    <div>
      <ReactApexChart options={options} series={seriess} type="bar" height={350} />
    </div>
  );
};

export default BarChart;
