import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import Barchart from '../../Barchart/Barchart';


const ViewAll = (props) => {
  const {vendorId} = props?.location?.state;
  const { id:outletId } = props.match.params;
  const {request:request, response: response}                           = useRequest();

  const [trendsReports, setTrendsReports]                               = useState([]);
                                                        
  useEffect(() => {
    document.title = "Reports - Sportyfy";
    request("POST",`admin/report/trendReport`,{
        outletId,
    })
  },[])

  useEffect(() => {
    if(response){
      if(response.status){
        setTrendsReports(response.data);
      }
    }
  },[response])

 
  return (
   
    <div className="ms-content-wrapper">
    <Breadcrumb
          title="Trends Reports"
          links={[
                { to: "/dashboard", name: "Home" },
                { to: "/vendors", name: "Vendors" },
                { to: `/vendor/total-outlets/${vendorId}`, name: "Outlets" },
            ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Trends Reports</h6>
        
            </div>
            <div className="row">
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Feedback</h6>
                  </div>
                  {trendsReports?.businessTrend && (
                    <div className="ms-panel">
                      {
                    //   loader ? (
                    //     <div
                    //       style={{
                    //         flex: 1,
                    //         alignItems: "center",
                    //         justifyContent: "center",
                    //       }}
                    //     >
                    //       <Spinner
                    //         animation="border"
                    //         className="d-flex justify-content-center"
                    //       />
                    //     </div>
                    //   ) : (
                    //     outletId != "" && (
                          <Barchart
                            data={trendsReports?.businessTrend}
                            about={"Feedback"}
                          />
                    //     )
                    //   )
                }
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Cuisine</h6>
                  </div>
                  {trendsReports?.cuisineTrend && (
                    <div className="ms-panel">
                      {
                    //   loader ? (
                    //     <Spinner
                    //       animation="border"
                    //       className="d-flex justify-content-center"
                    //     />
                    //   ) : (
                    //     outletId != "" && (
                          <Barchart
                            data={trendsReports?.cuisineTrend}
                            about={"Cuisine Trend"}
                          />
                    //     )
                    //   )
                      }
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Dishes</h6>
                  </div>
                  {trendsReports?.dishesTrend && (
                    <div className="ms-panel">
                      {
                    //   loader ? (
                    //     <Spinner
                    //       animation="border"
                    //       className="d-flex justify-content-center"
                    //     />
                    //   ) : (
                        // outletId != "" && (

                          <Barchart
                            data={trendsReports?.dishesTrend}
                            about={"Dishes Trend"}
                            isHoriz={true}
                          />
                    //     )
                    //   )
                      }
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Promotions</h6>
                  </div>
                  {trendsReports?.promotions && (
                    <div className="ms-panel">
                      <Barchart
                        data={trendsReports?.promotions}
                        about={"Promotions"}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Session</h6>
                  </div>
                  {trendsReports?.sessionTrend && (
                    <div className="ms-panel">
                      <Barchart
                        data={trendsReports?.sessionTrend}
                        about={"Session"}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Booking Trend</h6>
                  </div>
                  {trendsReports?.bookingTrend && (
                    <div className="ms-panel">
                      <Barchart
                        data={trendsReports?.bookingTrend}
                        about={"Booking Trend"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewAll
