import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import Pagination from '../../Pagination/Pagination';

const ViewAll = (props) => {
  const { vendorId } = props?.location?.state;
  const { request: request, response: response } = useRequest();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Name" },
    { title: "Net Amount" },
    { title: "Rest Amount" },
    { title: "Bookings" },
    { title: "Paid To Vendor" },
  ]);
  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  const [search, setSearch] = useState();

  useEffect(() => {
    document.title = "Finance Report - Sportyfy";
    request("POST", `admin/report/financeReportVendor`, {
      perPage,
      page,
      vendorId
    })
  }, [])


  useEffect(() => {
    if (response) {
      if (response.status && response?.data) {
        // if(response.data && response.data.length>0){
        setData(response.data);
        setTotalDocuments(response.totalDocument)
        // }
      }
    }
  }, [response])


  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1)
    } else {
      setStartIndex(selected * perPage + 1)
    }
    request("POST", `admin/report/financeReportSortyfy`, {
      perPage,
      page: selected + 1,
      searchString: search,
      vendorId
    })
  }

  const handleSearch = (e) => {
    e.preventDefault();
    let searchString = search;
    if (e.target.name == "search") {
      setSearch(e.target.value);
      searchString = e.target.value;
    }
    searchString = searchString ?? "";
    request("POST", `admin/report/financeReportVendor`, {
      perPage,
      page,
      vendorId,
      searchString: searchString
    })
  }
  return (

    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Finance Report"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/vendors", name: "Vendors" },
              { to: `/vendor/total-outlets/${vendorId}`, name: "Outlets" },
            ]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-10">
                  <h6>{"Finance Report"}</h6>
                </div>

                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">Search:</label>
                    <input type="text" className="form-control" id="search" name="search" placeholder="Search by Name" onChange={handleSearch} />
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns && columns.length > 0 && columns.map((obj) => (
                        <th scope="col">{obj?.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length > 0 && data.map((item, i) => (
                      <tr key={item.id}>
                        <th scope="row">{i + startIndex}</th>
                        <td>{item.name}</td>
                        <td>{item.netAmount}</td>
                        <td>{item.restAmount}</td>
                        <td>{item.bookingCount}</td>
                        <td>{item.paidToVendor}</td>
                      </tr>
                    ))}
                    {data.length == 0 && (
                      <tr><td colspan="6" style={{ textAlign: "center" }}> No finance report has found.</td></tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 && data.length != totalDocuments && data.length != 0 && (
            <Pagination
              page={page}
              totalDocuments={totalDocuments}
              getNewData={fetchMoreData}
              perPage={perPage}
              currentDocLength={data.length}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ViewAll
