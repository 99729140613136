import React, { Component } from "react";
import { Bar as BarChart, HorizontalBar } from "react-chartjs-2";

// Bar chart
function chartData1(props) {
  return {
    labels: props.data.map((item) => item[Object.keys(props.data[0])[0]]),
    datasets: [
      {
        label: props.about,
        borderWidth: 1,
        width: 1,
        backgroundColor: "#3ea99f",
        data: props.data.map((item) => item[Object.keys(props.data[0])[1]]),
      },
    ],
  };
}

class Barchart extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data1: chartData1(props),
      open: true,
      values: props.data
        ? props.data.map((item) => item[Object.keys(props.data[0])[1]])
        : [],
    };
  }
  render() {
    return (
      <div>
        {this.props.isHoriz ? (
          <HorizontalBar
            data={this.state.data1}
            options={{
              indexAxis: "y",
              legend: { display: false },
              title: {
                display: true,
                text: this.props.about,
              },
            }}
          />
        ) : (
          <BarChart
            data={this.state.data1}
            options={{
              indexAxis: "y",
              elements: {
                bar: {
                  borderWidth: 2,
                },
              },
              legend: { display: false },
              title: {
                display: true,
                text: this.props.about,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      max: Math.max(...this.state.values),
                      min: 0,
                      //  stepSize: 5
                    },
                  },
                ],
              },
            }}
          />
        )}
      </div>
    );
  }
}

export default Barchart;
