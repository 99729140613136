import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AddRewardModal = ({ show, handleClose, handleSave, initialData }) => {
  const [amount, setAmount] = useState(initialData?.amount || "");
  const [point, setPoint] = useState(initialData?.point || "");

  const handleSubmit = () => {
    const formData = { amount, point };
    handleSave(formData);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Reward System Control Panel</Modal.Title>
        <span onClick={handleClose} style={{cursor:"pointer"}}>X</span>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formAmount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formPoint">
            <Form.Label>Point</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter point"
              value={point}
              onChange={(e) => setPoint(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddRewardModal;
