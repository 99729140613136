import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { CKEditor } from "ckeditor4-react";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ImagePreview from "../ImagePreview/ImagePreview";
import useRequest from "../../hooks/useRequest";
import { toast } from "react-toastify";
import Select from "react-select";

const Edit = (props) => {
  const { id: recordId } = props.match.params;
  const history = useHistory();
  const { response, request } = useRequest();
  const { response: responseUpdate, request: requestUpdate } = useRequest();
  const { request: requestCities, response: responseCities } = useRequest();

  const [cities, setCities] = useState([]);
  const [touchedError, setTouchedError] = useState(false);
  const [displayImage, setDisplayImage] = useState();
  const [image, setImage] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [ckcontent, setCkContent] = useState();
  const [eventDate, setEventDate] = useState();
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    control,
    clearErrors,
  } = useForm();

  useEffect(() => {
    document.title = "Edit City Event - Sportyfy";
    requestCities("GET", "admin/vendor/getCity");
    register("description", {
      required: true,
    });
    request("GET", `admin/vendor/getEventAttractionDetail?dataId=${recordId}`);
  }, [recordId]);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);
  useEffect(() => {
    if (response) {
      if (response.status && response.data) {
        setValue("city", response.data.cityId);
        setValue("title", response.data.title);
        setValue("eventVenue", response.data.eventVenue);
        setValue("eventAddress", response.data.eventAddress);
        setValue(
          "startDate",
          new Date(response.data?.fromDate)
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, "-")
        );
        setValue(
          "endDate",
          new Date(response.data?.toDate)
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, "-")
        );
        setValue("description", response.data.description);
        setEventDate(
          new Date(response.data?.eventDate)
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, "-")
        );
        setImage(response.data?.image[0]);
        setSelectedCity(response.data.cityId);
        setCkContent(response.data.description);
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    const { city, title, description, eventVenue, eventAddress } = data;
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    const formData = new FormData();
    formData.append("cityId", city);
    formData.append("title", title);
    formData.append("eventVenue", eventVenue);
    formData.append("eventAddress", eventAddress);
    formData.append("eventDate", eventDate);
    formData.append("fromDate", startDate);
    formData.append("toDate", endDate);
    formData.append("description", description);
    formData.append("type", "city_event");
    formData.append("dataId", recordId);

    if (displayImage) {
      if (data.image) {
        formData.append("image", data.image);
      }
    } else {
      if (image == null || image == "undefined") {
        formData.append("oldImage", "");
      } else {
        formData.append("oldImage", image);
      }
    }

    requestUpdate("POST", "admin/vendor/editCityEventAttraction", formData);
  };

  useEffect(() => {
    if (responseUpdate) {
      if (responseUpdate.status && responseUpdate.data) {
        toast.success(responseUpdate.message);
        history.push("/city-events");
      }
    }
  }, [responseUpdate]);

  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      setError("image", "");
      setValue("image", event.target.files[0]);
    } else {
      setDisplayImage("");
      setImage("");
      setValue("image", "");
    }
  };

  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value);
    } else {
      setMinEndDate("");
    }
  };

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value);
    } else {
      setMaxStartDate("");
    }
  };

  const handleRemoveImage = () => {
    if (document.getElementById("image")) {
      document.getElementById("image").value = "";
    }
    setDisplayImage(null);
    setValue("image", null);
    setImage("");
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Edit"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/city-events", name: "City Events" },
            ]}
          />
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Edit City Event</h6>
            </div>
            <div className="ms-panel-body">
              <form
                className="needs-validation clearfix"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="city">
                      City<span className="field-required">*</span>
                    </label>
                    <Select
                      // {...register("city", {
                      //   required: true,
                      // })}
                      value={
                        cities &&
                        cities.length > 0 &&
                        cities
                          .filter((item) => item?._id === getValues("city"))
                          .map((item) => {
                            return { label: item.name, value: item?._id };
                          })[0]
                      }
                      options={
                        cities &&
                        cities.length > 0 &&
                        cities.map((obj) => ({
                          label: obj.name,
                          value: obj._id,
                        }))
                      }
                      required={true}
                      name="city"
                      onChange={(value) => {
                        setValue("city", value.value);
                      }}
                    />
                    {errors.city && (
                      <div
                        className="invalid-field"
                        style={{ position: "initial" }}
                      >
                        The City field is required.
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="title">
                      Title<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="title"
                        placeholder="Title"
                        className={`form-control ${
                          touchedError
                            ? errors.title
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("title", {
                          required: true,
                        })}
                      />
                      {errors.title?.type === "required" && (
                        <div className="invalid-field">
                          The title field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="eventDate">
                      {/* date picker input */}
                      <label htmlFor="validationCustom01" class="form-label">
                        Event Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="eventDate"
                        name="eventDate"
                        placeholder="Event Date"
                        value={eventDate}
                        min={new Date()}
                        onChange={(e) => setEventDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="eventVenue">
                      Event Venue<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="eventVenue"
                        placeholder="Event venue"
                        className={`form-control ${
                          touchedError
                            ? errors.eventVenue
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("eventVenue", {
                          required: true,
                        })}
                      />
                      {errors.eventVenue?.type === "required" && (
                        <div className="invalid-field">
                          The Event venue field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="startDate">
                      {/* date picker input */}
                      <label htmlFor="validationCustom01" class="form-label">
                        Start Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="startDate"
                        name="startDate"
                        placeholder="Start Date"
                        max={maxStartDate}
                        onChange={handleStartDate}
                        {...register("startDate", {
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="endDate">
                      <label htmlFor="validationCustom01" class="form-label">
                        End Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="endDate"
                        name="endDate"
                        placeholder="End Date"
                        min={minEndDate}
                        onChange={handleEndDate}
                        {...register("endDate", {
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3 pt-3">
                    <label htmlFor="eventAddress">
                      Event Address<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <textarea
                        id="eventAddress"
                        placeholder="Event address"
                        className={`form-control ${
                          touchedError
                            ? errors.eventAddress
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("eventAddress", {
                          required: true,
                        })}
                      />
                      {errors.eventAddress?.type === "required" && (
                        <div className="invalid-field">
                          The Event Address field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="description">
                      Description<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      {ckcontent && (
                        <CKEditor
                          initData={getValues("description")}
                          config={{
                            extraAllowedContent:
                              "p(*)[*]{*};div(*)[*]{*};li(*)[*]{*};ul(*)[*]{*};i(*)[*]{*}",
                            allowedContent: true,
                            protectedSource: [/<i[^>]*><\/i>/g],
                            // removeEmpty: { i: false },
                          }}
                          onChange={({ editor }) => {
                            const data = editor.getData();
                            setValue("description", data);
                            clearErrors("description");
                          }}
                        />
                      )}

                      {errors.description?.type === "required" && (
                        <div className="invalid-field">
                          The description field is required.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label htmlFor="image"> Image</label>
                    <div className="custom-file">
                      <Controller
                        control={control}
                        name="image"
                        render={({ field: { onChange, value, ref } }) => (
                          <input
                            type="file"
                            id="image"
                            onChange={handleImage}
                            style={{ display: "none" }}
                            inputRef={ref}
                            accept="image/*"
                            className="custom-file-input"
                          />
                        )}
                      />
                      <label className="custom-file-label" htmlFor="image">
                        Images
                      </label>
                      <div className="invalid-feedback">
                        Example invalid custom file feedback
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary mt-4 d-block w-100"
                    onClick={() => setTouchedError(true)}
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {(displayImage || image) && (
          <div className="col-xl-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Image </h6>
                  </div>
                  <div className="ms-panel-body">
                    <ImagePreview displayImage={displayImage} image={image} />
                  </div>

                  <div className="ms-panel-header ">
                    <label
                      className="btn btn-primary mx-2"
                      onClick={handleRemoveImage}
                    >
                      Remove
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Edit;
