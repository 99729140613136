// import React, { useEffect, useState } from 'react'
// import Breadcrumb from '../../Breadcrumb/Breadcrumb';
// import useRequest from '../../../hooks/useRequest';
// import Pagination from '../../Pagination/Pagination';

// const ViewAll = (props) => {
//   const { vendorId } = props?.location?.state;
//   const { request: request, response: response } = useRequest();

//   const [data, setData] = useState([]);
//   const [columns, setColumns] = useState([
//     { title: "S.No." },
//     { title: "Name" },
//     { title: "Net Amount" },
//     { title: "Rest Amount" },
//     { title: "Bookings" },
//     { title: "Paid To Vendor" },
//   ]);
//   const perPage = 10;
//   const [totalDocuments, setTotalDocuments] = useState(10);
//   const [page, setPage] = useState(1);
//   const [startIndex, setStartIndex] = useState(1);
//   const [search, setSearch] = useState();

//   useEffect(() => {
//     document.title = "Finance Report - Sportyfy";
//     request("POST", `admin/report/financeReportVendor`, {
//       perPage,
//       page,
//       vendorId
//     })
//   }, [])

//   useEffect(() => {
//     if (response) {
//       if (response.status && response?.data) {
//         // if(response.data && response.data.length>0){
//         setData(response.data);
//         setTotalDocuments(response.totalDocument)
//         // }
//       }
//     }
//   }, [response])

//   const fetchMoreData = ({ selected }) => {
//     setPage(selected + 1);
//     if (selected + 1 == 1) {
//       setStartIndex(1)
//     } else {
//       setStartIndex(selected * perPage + 1)
//     }
//     request("POST", `admin/report/financeReportSortyfy`, {
//       perPage,
//       page: selected + 1,
//       searchString: search,
//       vendorId
//     })
//   }

//   const handleSearch = (e) => {
//     e.preventDefault();
//     let searchString = search;
//     if (e.target.name == "search") {
//       setSearch(e.target.value);
//       searchString = e.target.value;
//     }
//     searchString = searchString ?? "";
//     request("POST", `admin/report/financeReportVendor`, {
//       perPage,
//       page,
//       vendorId,
//       searchString: searchString
//     })
//   }
//   return (

//     <div className="ms-content-wrapper">
//       <div className="row">
//         <div className="col-md-12">
//           <Breadcrumb
//             title={"Finance Report"}
//             links={[
//               { to: "/dashboard", name: "Home" },
//               { to: "/vendors", name: "Vendors" },
//               { to: `/vendor/total-outlets/${vendorId}`, name: "Outlets" },
//             ]}
//           />
//           <div className="ms-panel">
//             <div className="ms-panel-header">
//               <div className="row">
//                 <div className="col-md-10">
//                   <h6>{"Finance Report"}</h6>
//                 </div>

//                 <div className="col-md-2">
//                   <div className="form-group">
//                     <label htmlFor="search">Search:</label>
//                     <input type="text" className="form-control" id="search" name="search" placeholder="Search by Name" onChange={handleSearch} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="ms-panel-body">
//               <div className="table-responsive">
//                 <table className="table table-hover thead-primary">
//                   <thead>
//                     <tr>
//                       {columns && columns.length > 0 && columns.map((obj) => (
//                         <th scope="col">{obj?.title}</th>
//                       ))}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {data && data.length > 0 && data.map((item, i) => (
//                       <tr key={item.id}>
//                         <th scope="row">{i + startIndex}</th>
//                         <td>{item.name}</td>
//                         <td>{item.netAmount}</td>
//                         <td>{item.restAmount}</td>
//                         <td>{item.bookingCount}</td>
//                         <td>{item.paidToVendor}</td>
//                       </tr>
//                     ))}
//                     {data.length == 0 && (
//                       <tr><td colspan="6" style={{ textAlign: "center" }}> No finance report has found.</td></tr>
//                     )}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="col-md-12">
//           {perPage !== 0 && data.length != totalDocuments && data.length != 0 && (
//             <Pagination
//               page={page}
//               totalDocuments={totalDocuments}
//               getNewData={fetchMoreData}
//               perPage={perPage}
//               currentDocLength={data.length}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   )
// }

// export default ViewAll

import React, { useEffect, useRef, useState } from "react";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";



import { useDispatch, useSelector } from "react-redux";

import { Col, Container, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
// import { downloadExcel11 } from "../../../util/fn";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { FaUpload } from "react-icons/fa6";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BASEURL } from "../../../constant/api";
// import CustomFileUpload from "./CustomFileUpload";

const ViewAll = () => {
  const { request: requestReport, response: responseReport } = useRequest(true);
  const { request: requestReportOther, response: responseReportOther } =
    useRequest(true);
  const [outletList, setOutletList] = useState([]);
  const dispatch = useDispatch();

  const itemsPerPage = 10;
  const [overallData, setOverallData] = useState({
    totalBooking: 0,
    totalPerson: 0,
    totalRevenue: 0,
  });
  const [currentItems, setCurrentItems] = useState(null);
  const [currentItems1, setCurrentItems1] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageCount1, setPageCount1] = useState(0);
  const [page, setPage] = useState(1);
  const [page1, setPage1] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);

  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % billingList.length;
  //   setItemOffset(newOffset);
  // };
  const handlePageClick = (event) => {
    console.log("event.selected", event.selected + 1);
    setPage(event.selected + 1);
    // const newOffset = (event.selected * itemsPerPage) % menuList?.data.length;
    // setItemOffset(newOffset);
  };

  const handlePageClick1 = (event) => {
    console.log("event.selected", event.selected + 1);
    setPage1(event.selected + 1);
    // const newOffset = (event.selected * itemsPerPage) % menuList?.data.length;
    // setItemOffset(newOffset);
  };
  const tableRef = useRef(null);

  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: "",
    startDate: "",
    endDate: "",
  });
  const [token, setToken] = useState(localStorage.getItem("user"));
  const { id } = useParams();

  const [selectedOutletId, setSelectedOutletId] = useState(id);
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [reportId, setReportId] = React.useState("");

  const DownloadExcel = async (e) => {
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      responseType: "blob",
    };

    try {
      const response = await axios.post(
        `${BASEURL.PORT}/admin/download/downloadFinanceReport`,
        {
          status: "",
          startDate,
          endDate,
          outletId: e.target.value,
          page,
          limit: itemsPerPage,
        },
        config
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "vendors.xlsx"); // specify the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const filterHandler = () => {
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    const status = "";
    // const status = "";

    requestReport("POST", "admin/report/financeReportVendor", {
      status,
      startDate,
      endDate,
      outletId: selectedOutletId,
      page,
      limit: itemsPerPage,
    });
    requestReportOther("POST", "admin/report/financeReportOther", {
      status,
      startDate,
      endDate,
      outletId: selectedOutletId,
      page: page1,
      limit: itemsPerPage,
    });

    // dispatch(
    //   getBillingList({
    //     status,
    //     startDate,
    //     endDate,
    //     outletId: selectedOutletId,
    //   })
    // ).then((res) => {
    //   paginateEffect(res.payload.data);
    // });
  };

  useEffect(() => {
    filterHandler();
  }, [page, page1]);
  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value);
    } else {
      setMinEndDate("");
    }
  };

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value);
    } else {
      setMaxStartDate("");
    }
  };

  useEffect(() => {
    if (responseReportOther) {
      setCurrentItems1([]);
      if (responseReportOther.status == "success") {
        setCurrentItems1(responseReportOther?.data);
        setPageCount1(responseReportOther?.totalPage);
      }
    }
  }, [responseReportOther]);

  useEffect(() => {
    if (responseReport) {
      if (responseReport.status || responseReport.status == "success") {
        setOverallData({
          ...overallData,
          totalBooking: responseReport?.totalBooking ?? 0,
          totalPerson: responseReport?.totalPerson ?? 0,
          totalRevenue: responseReport?.totalRevenue ?? 0,
        });
        setCurrentItems(responseReport?.data);
        setPageCount(responseReport?.totalPage);
      }
    }
  }, [responseReport]);

  // const paginateEffect = (rep) => {
  //   if (billingList.length) {
  //     const endOffset = itemOffset + itemsPerPage;
  //     console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //     setCurrentItems(rep.slice(itemOffset, endOffset));
  //     setPageCount(Math.ceil(billingList.length / itemsPerPage));
  //   }
  // };

  useEffect(() => {}, []);

  return (
    <>
      <div className="ms-content-wrapper">
        <Breadcrumb
          title="Finance Reports"
          links={[{ to: "/", name: "Home" }]}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                {/* <div className="date-filter"> */}
                <div className="">
                  <Row>
                    <Col md={2}>
                      <div>
                        <h6>Finance Reports</h6>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="startDate">
                        {/* date picker input */}
                        <label
                          htmlFor="validationCustom01"
                          className="form-label"
                        >
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="startDate"
                          name="startDate"
                          placeholder="Start Date"
                          max={maxStartDate}
                          onChange={handleStartDate}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="endDate">
                        <label
                          htmlFor="validationCustom01"
                          className="form-label"
                        >
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="endDate"
                          name="endDate"
                          placeholder="End Date"
                          min={minEndDate}
                          onChange={handleEndDate}
                          // onChange={filterData}
                        />
                      </div>
                    </Col>
                    {/* <Col md={2}>
                      <div className="endDate">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-control"
                          id="status"
                          name="status"
                        // onChange={filterData}
                        >
                          <option value={""}>Please Select</option>
                          <option value={"accepted"}>Accepted</option>
                          <option value={"pending"}>Pending</option>
                          <option value={"decline"}>Decline</option>
                        </select>
                      </div>
                    </Col> */}
                    <Col md={2}>
                      <button
                        type="button"
                        onClick={filterHandler}
                        className="excle-dowm"
                      >
                        {" "}
                        Filter{" "}
                      </button>
                    </Col>
                    <Col md={2}>
                      <button
                        type="button"
                        onClick={DownloadExcel}
                        className="excle-dowm"
                      >
                        {" "}
                        Export excel{" "}
                      </button>
                    </Col>

                    {/* <Col md={12}>
                      <div className="text-center border-classss mt-4">
                        <h5 className="mt-4">Payment Gateway</h5>
                      </div>

                      <div className="table-responsive mt-5">
                        <table
                          className="table table-hover thead-primary"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th scope="col">S.No</th>
                              <th scope="col">Booking ID</th>
                              <th scope="col">Username</th>
                              <th scope="col">Guest Name</th>
                              <th scope="col">Booking Made On</th>
                              <th scope="col">Date of Booking</th>
                              <th scope="col">Number of Guests</th>
                              <th scope="col">Booked For</th>
                              <th scope="col">Billing Amount</th>
                              <th scope="col">Outlet Discount</th>
                              <th scope="col">Sortyfy Discount</th>
                              <th scope="col">Billed Amount</th>
                              <th scope="col">Tax </th>
                              <th scope="col">Tax Amount</th>
                              <th scope="col">Final Amount After Tax</th>
                              <th scope="col">Reward Points Accrued</th>
                              <th scope="col">Reward Amount</th>
                              <th scope="col">Guest Payable Bill Amount</th>
                              <th scope="col">
                                Advanced Table Amount Adjusted
                              </th>
                              <th scope="col">Payment Done by Guest</th>
                              <th scope="col">Paid Through</th>
                              <th scope="col">Payment Cleared At</th>
                              <th scope="col">Outlet Payment Status</th>
                              <th scope="col">Sortyfy Commission</th>
                              <th scope="col">Commission Amount</th>
                              <th scope="col">Payment Gateway Charge</th>
                              <th scope="col">Payment Gateway Amount</th>
                              <th scope="col">
                                Payment Made to Outlet through Sortyfy
                              </th>
                              <th scope="col">No-Show</th>
                              <th scope="col">No-Show Amount</th>
                              <th scope="col">
                                Full & Final Payment Settlement
                              </th>
                              <th scope="col">Payment Status</th>
                              <th scope="col">Commission Payment Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems?.length ? (
                              currentItems.map((report, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{report?.bookingNumber}</td>
                                    <td>{report?.userName}</td>
                                    <td>{report?.guestName}</td>
                                    <td>{report?.bookingMadeOn}</td>
                                    <td>{report?.dateOfBooking}</td>
                                    <td>{report?.numberOfGuest}</td>
                                    <td>{report?.bookedFor}</td>
                                    <td>{report?.billingAmount}</td>

                                    <td>
                                      {report?.outletDiscount > 0
                                        ? report?.outletDiscount
                                        : "0"}
                                    </td>
                                    <td>{report?.sortyfyDiscount}</td>
                                    <td>{report?.billedAmount}</td>
                                    <td>
                                      {report?.tax?.map((vals, i) => (
                                        <span key={i}>
                                          {`${vals?.title}: ${vals?.amount}`},
                                        </span>
                                      ))}
                                    </td>
                                    <td>{report?.taxAmount}</td>
                                    <td>{report?.finalAmountAfterTax}</td>
                                    <td>{report?.rewardPoint}</td>
                                    <td>{report?.rewardAmount}</td>
                                    <td>{report?.billedAmountAfterReward}</td>
                                    <td>{report?.advanceTableAmount}</td>
                                    <td>{report?.paymentDoneByGuest}</td>
                                    <td>{report?.paidThrough}</td>
                                    <td>{report?.paymentClearedAt}</td>
                                    <td>{report?.outletPaymentStatus}</td>
                                    <td>{report?.sortyfyCommission}</td>
                                    <td>{report?.commissionAmount}</td>
                                    <td>{report?.paymentGatewayCharge}</td>
                                    <td>{report?.paymentGatewayAmount}</td>
                                    <td>
                                      {report?.paymentMadeToOutletFromSortyfy}
                                    </td>
                                    <td>{report?.noShowPercent}</td>
                                    <td>{report?.noShowAmount}</td>
                                    <td>
                                      {report?.fullFinalPaymentSettlement}
                                    </td>
                                    <td>{report?.paymentStatus}</td>
                                    <td>{report?.commissionPaymentDetails}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan="7">
                                  No Reports Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {currentItems?.length ? (
                          <ReactPaginate
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col> */}

                    <Col md={12}>
                      <div className="text-center border-classss mt-4">
                        <h5 className="mt-4">Payment Gateway </h5>
                      </div>

                      <div className="table-responsive mt-5">
                        <table
                          className="table table-hover thead-primary"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th scope="col">S.No</th>
                              <th scope="col">Booking ID</th>
                              <th scope="col">Username</th>
                              <th scope="col">Guest Name</th>
                              <th scope="col">Booking Made On</th>
                              <th scope="col">Date of Booking</th>
                              <th scope="col">Number of Guests</th>
                              <th scope="col">Booked For</th>
                              <th scope="col">Billing Amount</th>
                              <th scope="col">Outlet Discount</th>
                              <th scope="col">Sortyfy Discount</th>
                              <th scope="col">Billed Amount</th>
                              <th scope="col">Tax </th>
                              <th scope="col">Tax Amount</th>
                              <th scope="col">Final Amount After Tax</th>
                              <th scope="col">Reward Points Accrued</th>
                              <th scope="col">Reward Amount</th>
                              <th scope="col">Guest Payable Bill Amount</th>
                              <th scope="col">
                                Advanced Table Amount Adjusted
                              </th>
                              <th scope="col">Payment Done by Guest</th>
                              <th scope="col">Paid Through</th>
                              <th scope="col">Payment Cleared At</th>
                              <th scope="col">Outlet Payment Status</th>
                              <th scope="col">Sortyfy Commission</th>
                              <th scope="col">Commission Amount</th>
                              <th scope="col">Payment Gateway Charge</th>
                              <th scope="col">Payment Gateway Amount</th>
                              {/* <th scope="col">Platform Charge</th> */}
                              <th scope="col">Gateway Charge</th>
                              <th scope="col">Tech Assistance</th>
                              <th scope="col">
                                Payment Made to Outlet through Sortyfy
                              </th>
                              <th scope="col">No-Show</th>
                              <th scope="col">No-Show Amount</th>
                              <th scope="col">
                                Full & Final Payment Settlement
                              </th>
                              <th scope="col">Payment Status</th>
                              <th scope="col">Commission Payment Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems?.length ? (
                              currentItems.map((report, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{report?.bookingNumber}</td>
                                    <td>{report?.userName}</td>
                                    <td>{report?.guestName}</td>
                                    <td>{report?.bookingMadeOn}</td>
                                    <td>{report?.dateOfBooking}</td>
                                    <td>{report?.numberOfGuest}</td>
                                    <td>{report?.bookedFor}</td>
                                    <td>{report?.billingAmount}</td>

                                    <td>
                                      {report?.outletDiscount > 0
                                        ? report?.outletDiscount
                                        : "0"}
                                    </td>
                                    <td>{report?.sortyfyDiscount}</td>
                                    <td>{report?.billedAmount}</td>
                                    <td>
                                      {report?.tax?.map((vals, i) => (
                                        <span key={i}>
                                          {`${vals?.title}: ${vals?.amount}`},
                                        </span>
                                      ))}
                                    </td>
                                    <td>{report?.taxAmount}</td>
                                    <td>{report?.finalAmountAfterTax}</td>
                                    <td>{report?.rewardPoint}</td>
                                    <td>{report?.rewardAmount}</td>
                                    <td>{report?.billedAmountAfterReward}</td>
                                    <td>{report?.advanceTableAmount}</td>
                                    <td>{report?.paymentDoneByGuest}</td>
                                    <td>{report?.paidThrough}</td>
                                    <td>{report?.paymentClearedAt}</td>
                                    <td>{report?.outletPaymentStatus}</td>
                                    <td>{report?.sortyfyCommission}</td>
                                    <td>{report?.commissionAmount}</td>
                                    <td>{report?.paymentGatewayCharge}</td>
                                    <td>{report?.paymentGatewayAmount}</td>
                                    {/* <td>{report?.platformcharge}</td> */}
                                    <td>{report?.gatewayCharge}</td>
                                    <td>{report?.techAssistance}</td>
                                    <td>
                                      {report?.paymentMadeToOutletFromSortyfy}
                                    </td>
                                    <td>{report?.noShowPercent}</td>
                                    <td>{report?.noShowAmount}</td>
                                    <td>
                                      {report?.fullFinalPaymentSettlement}
                                    </td>
                                    <td>{report?.paymentStatus}</td>
                                    <td>{report?.commissionPaymentDetails}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan="7">
                                  No Reports Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {currentItems?.length ? (
                          <ReactPaginate
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="text-center border-classss mt-4">
                        <h5 className="mt-4">Only Table & Direct Payment</h5>
                      </div>

                      <div className="table-responsive mt-5">
                        <table className="table table-hover thead-primary">
                          <thead>
                            <tr>
                              <th scope="col">S.No</th>
                              <th scope="col">Booking Number</th>
                              <th scope="col">User Name</th>
                              <th scope="col">Guest Name</th>
                              <th scope="col">Booking Made On</th>
                              <th scope="col">Date of Booking</th>
                              <th scope="col">Number of Guest</th>
                              <th scope="col">Booked For</th>
                              <th scope="col">Table Amount</th>
                              <th scope="col">Total Billed Amount</th>
                              <th scope="col">Bill Copy Shared</th>
                              <th scope="col">
                                Payment Done by Guest at Outlet
                              </th>
                              <th scope="col">Tax</th>
                              <th scope="col">Tax Amount</th>
                              <th scope="col">Net Amount Before Tax</th>
                              <th scope="col">Paid Through</th>
                              <th scope="col">Payment Cleared At</th>
                              <th scope="col">Outlet Payment Status</th>
                              <th scope="col">Sortyfy Commission</th>
                              <th scope="col">Commission Amount</th>
                              <th scope="col">Table Amount Forfitted</th>
                              <th scope="col">
                                Commission Payment Made to Sortyfy From Outlet
                              </th>
                              <th scope="col">Comission Status</th>
                              <th scope="col">Comission Payment Details</th>

                              <th scope="col">Upload Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems1?.length ? (
                              currentItems1?.map((report, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{report?.bookingNumber}</td>
                                    <td>{report?.userName}</td>
                                    <td>{report?.guestName}</td>
                                    <td>{report?.bookingMadeOn}</td>
                                    <td>{report?.dateOfBooking}</td>
                                    <td>{report?.numberOfGuest}</td>
                                    <td>{report?.bookedFor}</td>
                                    <td>{report?.tableAmount}</td>
                                    <td>{report?.totalBilledAmount}</td>
                                    <td>{report?.billCopyShared}</td>
                                    <td>
                                      {report?.paymentDoneByGuestAtOutlet}
                                    </td>

                                    <td>{report?.tax}</td>
                                    <td>{report?.taxAmount}</td>
                                    <td>{report?.netAmountBeforeTax}</td>
                                    <td>{report?.paidThrough}</td>
                                    <td>{report?.paymentClearedAt}</td>

                                    <td>{report?.outletPaymentStatus}</td>
                                    <td>{report?.sortyfyCommission}</td>
                                    <td>{report?.commissionAmount}</td>
                                    <td>{report?.tableAmountForfitted}</td>
                                    <td>
                                      {
                                        report?.commissionPaymentToSortyfyFromOutlet
                                      }
                                    </td>
                                    <td>{report?.commissionStatus}</td>
                                    <td>{report?.commissionPaymentDetails}</td>

                                    <td>
                                      {report?.billCopyShared == "No" && (
                                        <div
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setReportId(report?.orderId);
                                            setModalShow(true);
                                          }}
                                        >
                                          <FaUpload />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan="7">
                                  No Reports Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {currentItems1?.length ? (
                          <ReactPaginate
                            nextLabel="next >"
                            onPageChange={handlePageClick1}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount1}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* 
                <Container>
                  <div className="dh-section mt-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Person</strong>
                              </span>
                              <h2>{overallData.totalPerson}</h2>
                            </div>
                          </div>
                          <LineChart data={this.state.data1} options={options} />
                        </div>
                      </div>
                      <div className=" col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                         
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Revenue</strong>
                              </span>
                              <h2>{overallData.totalRevenue}</h2>
                            </div>
                          </div>
                          <LineChart data={this.state.data2} options={options} />
                        </div>
                      </div>

                      <div className=" col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">

                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Booking</strong>
                              </span>
                              <h2>{overallData.totalBooking}</h2>
                            </div>
                          </div>
                          <LineChart data={this.state.data4} options={options} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Container> */}
              </div>
            </div>
          </div>
        </div>
        {/* <CustomFileUpload
          show={modalShow}
          selectedOutletId={selectedOutletId}
          reportId={reportId}
          setModalShow={setModalShow}
          onHide={() => setModalShow(false)}
        /> */}
      </div>
    </>
  );
};

export default ViewAll;
