import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  downloadExcel1,
  getPermissionDetails,
  stringLimit,
  stringLimit1,
} from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import Select from "react-select";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";

import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { BASEURL } from "../../constant/api";
import axios from "axios";

const ViewAll = (props) => {
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: request, response: response } = useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Title" },
    { title: "Description" },
    { title: "Event Address" },
    { title: "Event Venue" },
    { title: "Event Date" },
    { title: "Total Amount" },
    { title: "Start Date" },
    { title: "End Date" },
    { title: "Image" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);

  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "City Events - Sportyfy";
    requestCities("GET", `admin/vendor/getCity`);
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this city event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        requestDelete("POST", "admin/vendor/deleteEventAttraction", {
          dataId: id,
        });
      }
    });
  };

  useEffect(() => {
    if (startDate || endDate) {
      request(
        "GET",
        `admin/vendor/getCityEventAttraction?cityId=${cityId}&type=city_event&page=${page}&per_page=${perPage}&startDate=${startDate}&endDate=${endDate}`
      );
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (response) {
      if (response.status) {
        // if(response.data && response.data.length>0){
        setData(response.data);
        setTotalDocuments(response.totalDocument);
        // }
      }
    }
  }, [response]);

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status) {
        toast.success("City event has been deleted successfully.");
        if (responseDelete.id) {
          let newRecord = data;
          newRecord = newRecord.filter((r) => r.id != responseDelete.id);
          setData(newRecord);

          let newTotal = totalDocuments;
          setTotalDocuments(newTotal - 1);
          if (newRecord.length == 0) {
            setPage(page - 1);

            request(
              "GET",
              `admin/vendor/getCityEventAttraction?cityId=${cityId}&type=city_event&page=${
                page > 0 ? page - 1 : 1
              }&per_page=${perPage}&startDate=${startDate}&endDate=${endDate}`
            );
          }
        }
      }
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }

    request(
      "GET",
      `admin/vendor/getCityEventAttraction?cityId=${cityId}&type=city_event&page=${
        selected + 1
      }&per_page=${perPage}&startDate=${startDate}&endDate=${endDate}`
    );
  };

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);

  const DownloadExcel = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      const response = await axios.get(
        `${BASEURL.PORT}/admin/vendor/getCityEventAttraction?cityId=${cityId}&type=city_event&page={''}&per_page={''}
      `,
        config
      );
      if (response?.data?.status == true) {
        let Ranking = response?.data?.data?.map((item, index) => ({
          "Sr.": index + 1,
          Title: item.name,
          Description: item.description,
          "Event Address": item.eventAddress,
          "Event Venue": item.eventVenue,
          "Event Date": item.eventDate,
          // "Total Amount": item.totalAmount,
          "Start Date": item.fromDate,
          "End Date": item.toDate,
          // 'Image': item.image
        }));

        const dataArray = [Ranking];
        const namesArray = ["City Events"];
        const headingsArray = [null, null]; // Assuming no headers for the data

        downloadExcel1(dataArray, namesArray, headingsArray);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderTooltip = (props, content) => (
    <Tooltip id="button-tooltip" {...props}>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </Tooltip>
  );
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"City Events"}
            links={[{ to: "/", name: "Home" }]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-1">
                  <h6>{"City Events"}</h6>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="cities">City:</label>
                    <Select
                      options={
                        cities &&
                        cities.length > 0 &&
                        cities.map((obj) => ({
                          label: obj.name,
                          value: obj._id,
                        }))
                      }
                      name="cities"
                      onChange={(value) => {
                        setCityId(value.value);
                        request(
                          "GET",
                          `admin/vendor/getCityEventAttraction?cityId=${value.value}&startDate=${startDate}&endDate=${endDate}&type=city_event&page=${page}&per_page=${perPage}`
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">From Date:</label>
                    <input
                      type="date"
                      value={startDate}
                      className="form-control"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </div>
                </div>{" "}
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">End Date:</label>
                    <input
                      type="date"
                      value={endDate}
                      className="form-control"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                  </div>
                </div>{" "}
                {permission?.webAccessWrite && (
                  <>
                    <div className="col-md-2 my-4">
                      <div className="form-group">
                        <Link to="/city-event/add" className="btn btn-primary">
                          {" "}
                          Add New Event
                        </Link>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-2">
                  <div className="d-flex">
                    <button
                      className="btn btn-primary burron"
                      onClick={DownloadExcel}
                    >
                      Download Excel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>{item.name}</td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip({}, item.description)}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                    ? stringLimit1(item.description)
                                    : "",
                                }}
                              ></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip({}, item.eventAddress)}
                            >
                              <span>{stringLimit1(item.eventAddress)}</span>
                            </OverlayTrigger>
                          </td>
                          <td>{item.eventVenue}</td>
                          <td>{item.eventDate}</td>
                          <td>{item.totalAmoun}</td>
                          <td>{item.fromDate}</td>
                          <td>{item.toDate}</td>
                          <td>
                            {item.coverImage && (
                              <img
                                src={item.coverImage}
                                alt={item.coverImage}
                              />
                            )}
                          </td>
                          {permission?.webAccessEdit && (
                            <td>
                              <Link to={`city-event/edit/${item.id}`}>
                                <i
                                  title="Edit"
                                  className="fas fa-pencil-alt text-secondary"
                                />
                              </Link>
                              <Link
                                to="#"
                                onClick={() => handleDelete(item.id)}
                              >
                                <i
                                  title="Delete"
                                  className="far fa-trash-alt ms-text-danger"
                                />
                              </Link>
                            </td>
                          )}
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="10" style={{ textAlign: "center" }}>
                          {" "}
                          No city event has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length != 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
