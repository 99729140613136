import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";

import { IoDocumentsSharp } from "react-icons/io5";

import {
  capitalizeFirstLetter,
  downloadExcel1,
  getPermissionDetails,
} from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import useRequest from "../../hooks/useRequest";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { BASEURL } from "../../constant/api";
import PDFGenerator from "./PdfGenerator";
import { BsChat, BsChatFill } from "react-icons/bs";

const ViewAll = (props) => {
  const params = useLocation();
  let pages = params?.search?.split("?page=")[1];

  const { request: requestCities, response: responseCities } = useRequest();
  const { request: request, response: response } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();
  const { request: requestStatus1, response: responseStatus1 } = useRequest();
  const { request: UpdateNameAPI, response: responseNameAPI } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [popup, setPopup] = useState(false);
  const [popup1, setPopup1] = useState(false);
  const [popup2, setPopup2] = useState(false);
  const [bankDetails, setShowBankDetails] = useState("");
  const [mouData, setMouData] = useState("");
  const [cities, setCities] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [filterData, setFilterData] = useState({
    cityId: "",
    name: "",
    type: "",
    area: "",
    sort_by: "",
    referralBy: "",
  });
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Action" },
    { title: "Chat" },
    { title: "Restaurent Name" },
    { title: "Type & No." },
    { title: "Registration Date & Time" },
    { title: "Contact Person" },
    { title: "Bank Status" },
    { title: "Phone Number / Email" },
    { title: "Referal By" },
    { title: "City" },
    { title: "Reason" },
    { title: "Status" },
    { title: "Registration Score" },
    // { title: "Area / Address" },
    // { title: "Discount" },
    // { title: "Commission" },
    // { title: "Reward Partner" },
    // { title: "City Rank" },
    // { title: "Star Rank" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(parseInt(pages) ? parseInt(pages) : 1);
  const [city, setCity] = useState("");
  const [vendorData, setVendorData] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [name, setName] = useState("");
  const [referralBy, setReferalBy] = useState("");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filtertrue, setFilterTrue] = useState("");
  const [area, setArea] = useState("");
  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Vendors - Sportyfy";
    requestCities("GET", "admin/vendor/getCity");

    if (props.location.state?.cityId) {
      request(
        "GET",
        `admin/vendor/getAllVendor?cityId=${props.location.state?.cityId}&name=&type=&page=${page}&per_page=${perPage}&status=${props.location.state?.status}`
      );
      setCity(props.location?.state?.cityId);
    }
  }, [responseNameAPI]);

  useEffect(() => {
    if (responseNameAPI) {
      if (responseNameAPI.status) {
        toast.success("Vendor status has been updated successfully.");
      }
      setPopup2(false);
      setVendorData("");
      setVendorName("");
    }
  }, [responseNameAPI]);
  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
        // if (props.location.state?.cityId) {
        //   setCity(props.location?.state?.cityId);
        // }
      }
    }
  }, [responseCities]);

  useEffect(() => {
    if (filterData?.cityId) {
      handleFilter({ ...filterData });
    }
    // else {
    //   handleFilter("", "", "", "", "");
    // }
  }, [responseCities, filtertrue]);

  useEffect(() => {
    setFilterTrue("fdfd");
  }, []);
  const handleSearch = (e) => {
    e.preventDefault();
    let searchCity = city;
    let searchName = name;
    let searchType = type;
    let searchreferralBy = referralBy;
    const updatedFilterData = { ...filterData };

    if (e.target.name === "name") {
      setName(e.target.value);
      updatedFilterData.name = e.target.value;
      searchName = e.target.value;
    } else if (e.target.name === "area") {
      setArea(e.target.value);
      updatedFilterData.area = e.target.value;
      searchName = e.target.value;
    } else if (e.target.name === "referralBy") {
      setReferalBy(e.target.value);
      updatedFilterData.referralBy = e.target.value;
      searchreferralBy = e.target.value;
    } else if (e.target.name === "type") {
      setType(e.target.value);
      updatedFilterData.type = e.target.value;
      searchType = e.target.value;
    } else if (e.target.name === "sort_by") {
      setType(e.target.value);
      updatedFilterData.sort_by = e.target.value;
      searchType = e.target.value;
    }

    setFilterData(updatedFilterData);

    // Save updated filter data to local storage
    localStorage.setItem("filterData", JSON.stringify(updatedFilterData));
  };

  const handleFilter = ({
    cityId,
    type,
    name,
    area,
    sort_by,
    referralBy,
    startDate,
    endDate,
  }) => {
    let url = `admin/vendor/getAllVendor?cityId=${cityId}&name=${name}&type=${type}&area=${area}&page=${page}&per_page=${perPage}&sort_by=${sort_by}&status=${status}&referralBy=${
      referralBy ? referralBy : ""
    }&startDate=${startDate ? startDate : ""}&endDate=${
      endDate ? endDate : ""
    }`;
    request("GET", url);
  };

  useEffect(() => {
    const savedFilterData = JSON.parse(localStorage.getItem("filterData"));
    if (savedFilterData) {
      // setFilterData(savedFilterData);

      setCity(savedFilterData.cityId || "");
      setName(savedFilterData.name || "");
      setReferalBy(savedFilterData.referralBy || "");
      setArea(savedFilterData.area || "");
      setType(savedFilterData.type || "");
      setStatus(savedFilterData.status || "");

      setFilterData({
        ...savedFilterData,
      });
      handleFilter({ ...savedFilterData });
    }
  }, []);

  useEffect(() => {
    if (pages) {
      handleFilter({ ...filterData });
    }
  }, [pages]);
  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDocument);
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    const city = filterData.cityId;
    request(
      "GET",
      `admin/vendor/getAllVendor?cityId=${city ? city : ""}&name=${
        name ? name : ""
      }&type=${type ? type : ""}&page=${
        selected + 1
      }&per_page=${perPage}&status=${
        status ? status : ""
      }&referralBy=${referralBy}&startDate=${
        startDate ? startDate : ""
      }&endDate=${endDate ? endDate : ""}`
    );
  };

  const handleStatus = (id, status) => {
    if (status == "accepted") {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to accept this vendor request?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Accept!",
      }).then(function (result) {
        if (result.value) {
          requestStatus("POST", "admin/vendor/updateVendorStatus", {
            vendorId: id,
            status: status,
          });
        }
      });
    } else if (status == "rejected") {
      Swal.fire({
        title: "Are you sure you want to reject this vendor request?",
        text: "Enter reason to reject",
        input: "text",
        inputPlaceholder: "Enter text here",
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
          required: "true",
        },
        showCancelButton: true,
        inputValidator: (value) => {
          if (value === "") {
            return "Please enter reason!";
          }
        },
        confirmButtonText: "Yes Reject",
        confirmButtonColor: "#3ea99f",
      }).then((result) => {
        if (result.value) {
          const rejectReason = result.value;
          requestStatus("POST", "admin/vendor/updateVendorStatus", {
            vendorId: id,
            status: status,
            rejectReason: rejectReason,
          });
        }
      });
    }
  };
  const handleDelte = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this vendor request?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then(function (result) {
      if (result.value) {
        requestStatus1("POST", "admin/vendor/removeVendor", {
          vendorId: id,
        });
      }
    });
  };

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.status) {
        const { id, updatedStatus } = responseStatus;
        toast.success("Vendor status has been updated successfully.");

        const oldRecords = data;
        const indexToChange = oldRecords.findIndex((r) => r.id == id);
        oldRecords[indexToChange].status = updatedStatus;

        setData(oldRecords);
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    if (responseStatus1) {
      if (responseStatus1.status) {
        toast.success("Vendor Deleted Successfully");
        request(
          "GET",
          `admin/vendor/getAllVendor?cityId=${
            filterData.cityId ? filterData.cityId : ""
          }&name=&type=&page=${page}&per_page=${perPage}&status=${status}&referralBy=${
            referralBy ? referralBy : ""
          }&startDate=${startDate ? startDate : ""}&endDate=${
            endDate ? endDate : ""
          }`
        );
      }
    }
  }, [responseStatus1]);

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns]);
      }
    }
  }, []);

  const DownloadExcel = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      responseType: "blob",
    };

    try {
      const response = await axios.get(
        `${BASEURL.PORT}/admin/download/downloadVendors?city=${
          filterData.cityId ? filterData.cityId : ""
        }&name=${name}&type=${type}&area=${area}&referralBy=${
          referralBy ? referralBy : ""
        }&page=${page}&per_page=${"10"}&isExcelDownload=${true}&status=${status}&startDate=${
          startDate ? startDate : ""
        }&endDate=${endDate ? endDate : ""}`,
        config
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "vendors.xlsx"); // specify the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderCommisionModal = () => {
    return (
      <Modal
        show={popup}
        onHide={() => setPopup(false)}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <div className="d-flex gap-2 align-items-baseline">
                <h6>Bank Name : </h6>{" "}
                <p className="bank-text">{bankDetails?.bankName}</p>
              </div>
            </Col>

            <Col md={6}>
              <div className="d-flex gap-2 align-items-baseline">
                <h6>Bank Account No. : </h6>{" "}
                <p className="bank-text">{bankDetails?.accountNumber}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex gap-2 align-items-baseline">
                <h6>Bank Branch : </h6>{" "}
                <p className="bank-text">{bankDetails?.branchName}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex gap-2 align-items-baseline">
                <h6>Ifsc : </h6>{" "}
                <p className="bank-text">{bankDetails?.ifscCode}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex gap-2 align-items-baseline">
                <h6>Account Holder Name : </h6>{" "}
                <p className="bank-text">
                  {bankDetails?.accountHolder ? bankDetails?.accountHolder : ""}
                </p>
              </div>
            </Col>
            <Col md={10}>
              <div className="d-flex gap-2 align-items-baseline">
                <h6>Image : </h6>{" "}
                <div className="bank-textx">
                  {/* {bankDetails?.accountHolder ? bankDeta  ils?.accountHolder : ""} */}
                  {bankDetails?.image && (
                    <Link to={`${bankDetails?.image}`} target="_blank">
                      <img src={bankDetails?.image} alt="img" />
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  const REnderMou = () => {
    return (
      <Modal
        show={popup1}
        onHide={() => setPopup1(false)}
        // style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        className="mou-pdf"
        fullscreen={true}
        // size="lg"
        // centered
      >
        <Modal.Header>
          <Modal.Title>Mou</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PDFGenerator ids={mouData} />
        </Modal.Body>
      </Modal>
    );
  };

  const UpdateName = () => {
    return (
      <Modal
        show={popup2}
        onHide={() => setPopup2(false)}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Update Restaurant Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <div className="d-flex gap-5 align-items-center justify-content-around">
                <div>
                  <label>Name:</label>
                </div>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    value={vendorName}
                    name="vendorName"
                    onChange={(e) => {
                      e.preventDefault();
                      setVendorName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <Button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  UpdateNameAPI("POST", "admin/vendor/updateVendorName", {
                    name: vendorName,
                    vendorId: vendorData?.id,
                  });
                }}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  const history = useHistory();

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Vendors"} links={[{ to: "/", name: "Home" }]} />
        </div>
      </div>
      {/* <div className="row"> */}
      <div className="ms-panel">
        <div className="ms-panel-header">
          <div className="row">
            <div className="col-md-1">
              <h6>{"Vendors"}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="cities">City:</label>
                <Select
                  options={
                    cities &&
                    cities.length > 0 &&
                    cities.map((obj) => ({
                      label: obj.name,
                      value: obj._id,
                    }))
                  }
                  name="cities"
                  value={
                    city
                      ? cities &&
                        cities.length > 0 &&
                        cities
                          .filter((item) => item?._id == city)
                          .map((item) => {
                            return { label: item.name, value: item?._id };
                          })[0]
                      : null
                  }
                  onChange={(value) => {
                    setCity(value ? value.value : "");
                  }}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  placeholder="Name"
                  onChange={handleSearch}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="name">Referral By:</label>
                <input
                  type="text"
                  className="form-control"
                  name="referralBy"
                  value={referralBy}
                  placeholder="Referral By"
                  onChange={handleSearch}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="area">Area:</label>
                <input
                  type="text"
                  className="form-control"
                  name="area"
                  value={area}
                  placeholder="Area"
                  onChange={handleSearch}
                />
              </div>
            </div>

            <div className="col-md-1">
              <div className="form-group">
                <label htmlFor="type">Type:</label>
                <select
                  className="form-control"
                  id="type"
                  value={type}
                  name="type"
                  onChange={handleSearch}
                >
                  <option value={""}>All</option>
                  <option value={"single"}>Single</option>
                  <option value={"multiple"}>Multiple</option>
                </select>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="type">Status:</label>
                <select
                  onChange={(e) => {
                    setStatus(e.target.value);
                    const updatedFilterData = {
                      ...filterData,
                      status: e.target.value,
                    };
                    setFilterData(updatedFilterData);

                    localStorage.setItem(
                      "filterData",
                      JSON.stringify({
                        ...filterData,
                        status: e.target.value,
                      })
                    );
                  }}
                  value={status}
                  className="form-control"
                >
                  <option value={""}>Select Status </option>
                  <option value={"accepted"}>Accepted </option>
                  <option value={"rejected"}>Rejected </option>
                  <option value={"pending"}>Pending </option>
                </select>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="startDate">Start Date:</label>
                <input
                  type="date"
                  className="form-control"
                  name="startDate"
                  value={startDate}
                  placeholder="startDate"
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="endDate">End Date:</label>
                <input
                  type="date"
                  className="form-control"
                  name="endDate"
                  value={endDate}
                  placeholder="endDate"
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 my-1">
              <div className="d-flex align-items-center gap-1 justify-space-around">
                <div className="form-group p-1">
                  <Button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      const updatedFilterData = {
                        ...filterData,
                        cityId: city,
                      };
                      setFilterData(updatedFilterData);
                      localStorage.setItem(
                        "filterData",
                        JSON.stringify(updatedFilterData)
                      );
                      handleFilter({
                        ...updatedFilterData,
                        startDate,
                        endDate,
                      });
                    }}
                  >
                    Filter
                  </Button>
                </div>
                <div className="form-group">
                  <Button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(1);
                      setFilterData({
                        cityId: "",
                        name: "",
                        status: "",
                        sort_by: "",
                        type: "",
                        area: "",
                      });

                      setCity("");
                      setArea("");
                      setName("");
                      setStatus("");
                      setType("");
                      setReferalBy("");
                      setCities([]);
                      localStorage.setItem(
                        "filterData",
                        JSON.stringify({
                          cityId: "",
                          name: "",
                          status: "",
                          sort_by: "",
                          type: "",
                          area: "",
                          referralBy: "",
                        })
                      );
                      requestCities("GET", "admin/vendor/getCity");
                      handleFilter({
                        cityId: "",
                        name: "",
                        type: "",
                        area: "",
                        type: "",
                        status: "",
                        sort_by: "",
                        referralBy: "",
                      });
                      history.push("/vendors");
                    }}
                  >
                    Clear Filter
                  </Button>
                </div>
                <div className="form-group ml-1">
                  <Button className="btn btn-primary" onClick={DownloadExcel}>
                    Download
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-panel-body">
          <div className="table-responsive">
            <table className="table table-hover thead-primary">
              <thead>
                <tr>
                  {columns &&
                    columns.length > 0 &&
                    columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((item, i) => (
                    <tr key={item.id}>
                      <th scope="row">{i + startIndex}</th>
                      {permission?.webAccessEdit ? (
                        <td>
                          {item.status == "pending" ? (
                            <>
                              <Link
                                to="#"
                                onClick={() =>
                                  handleStatus(item.id, "accepted")
                                }
                              >
                                <i
                                  title="Accept"
                                  className="flaticon-tick-inside-circle"
                                ></i>
                              </Link>
                              <Link
                                to="#"
                                onClick={() =>
                                  handleStatus(item.id, "rejected")
                                }
                              >
                                <i
                                  title="Reject"
                                  className="fa fa-ban ms-text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </>
                          ) : item.status == "accepted" ? (
                            <Link
                              to="#"
                              onClick={() => handleStatus(item.id, "rejected")}
                            >
                              <i
                                title="Reject"
                                className="fa fa-ban ms-text-danger"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          ) : item.status == "rejected" ? (
                            <>
                              <Link
                                to="#"
                                onClick={() =>
                                  handleStatus(item.id, "accepted")
                                }
                              >
                                <i
                                  title="Accept"
                                  className="flaticon-tick-inside-circle"
                                ></i>
                              </Link>

                              <span
                                onClick={() => {
                                  handleDelte(item.id, "delete");
                                }}
                              >
                                <i
                                  title="Delete"
                                  className="fa fa-trash ms-text-danger"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </>
                          ) : (
                            ""
                          )}

                          <Link
                            to={`/vendor/total-outlets/${item.id}?page=${page}`}
                          >
                            <i
                              title="View"
                              className="flaticon-information"
                            ></i>
                          </Link>

                          <Link to={`/vendor/documents/${item.id}`}>
                            <IoDocumentsSharp fontSize={"20px"} />
                          </Link>
                          <Link
                            to={"#"}
                            className="ml-3"
                            onClick={() => {
                              setMouData(item?.id);
                              setPopup1(true);
                            }}
                          >
                            <img
                              src={
                                "https://media.istockphoto.com/id/610692498/vector/two-businessman-handshake-on-mou-memorandum.jpg?s=612x612&w=0&k=20&c=XVN8ac9PjJqT2mev1tbIUQLNo39ye_KWWQ2T-VnwOsU="
                              }
                              className="img-icons"
                            />
                          </Link>

                          <Link
                            to={"#"}
                            onClick={() => {
                              setShowBankDetails(item?.bankDetail[0]);
                              setPopup(true);
                            }}
                          >
                            <img
                              src={
                                "https://cdn-icons-png.freepik.com/256/8176/8176383.png?semt=ais_hybrid"
                              }
                              className="img-icons-new"
                            />
                          </Link>
                        </td>
                      ) : (
                        <td>
                          <Link to={`/vendor/total-outlets/${item.id}`}>
                            <i
                              title="View"
                              className="flaticon-information"
                            ></i>
                          </Link>

                          <Link
                            to={"#"}
                            className="ml-3"
                            onClick={() => {
                              setMouData(item?.id);
                              setPopup1(true);
                            }}
                          >
                            <img
                              src={
                                "https://media.istockphoto.com/id/610692498/vector/two-businessman-handshake-on-mou-memorandum.jpg?s=612x612&w=0&k=20&c=XVN8ac9PjJqT2mev1tbIUQLNo39ye_KWWQ2T-VnwOsU="
                              }
                              className="img-icons"
                            />
                          </Link>

                          <Link
                            to={"#"}
                            onClick={() => {
                              setShowBankDetails(item?.bankDetail[0]);
                              setPopup(true);
                            }}
                          >
                            <img
                              src={
                                "https://cdn-icons-png.freepik.com/256/8176/8176383.png?semt=ais_hybrid"
                              }
                              className="img-icons-new"
                            />
                          </Link>
                        </td>
                      )}
                      <td>
                        <Link to={`/vendor/chat/${item?.id}`}>
                          <BsChat fontSize={"20px"} />
                        </Link>
                      </td>
                      <td>
                        <div className="d-flex gap-3 align-items-center justify-content-between">
                          <div>
                            <p>{item.name}</p>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              setVendorData(item);
                              setVendorName(item?.name);
                              setPopup2(true);
                            }}
                          >
                            <p>
                              <MdEdit />
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        {item.vendorType
                          ? capitalizeFirstLetter(item.vendorType)
                          : ""}
                      </td>
                      <td>{item.date}</td>
                      <td>{item.contractPerson}</td>
                      <td>{item.cashFreeStatus}</td>
                      <td>{item?.phone + " / " + item?.email}</td>
                      <td>{item?.referralBy}</td>
                      <td>
                        {
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${item.id}`}>
                                {item.address}
                              </Tooltip>
                            }
                          >
                            <span>
                              {item.city}
                              <i
                                title="View"
                                className="flaticon-information ml-3 mt-1"
                              ></i>
                            </span>
                          </OverlayTrigger>
                        }
                      </td>
                      {/* <td>{item.address}</td> */}
                      <td>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${item.id}`}>
                              {item.reason}
                            </Tooltip>
                          }
                        >
                          <span>
                            <i
                              title="View"
                              className="flaticon-information"
                            ></i>
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td>
                        {item.status == "accepted" ? (
                          <span className="badge badge-success">
                            {capitalizeFirstLetter(item.status)}
                          </span>
                        ) : item.status == "rejected" ? (
                          <span className="badge badge-danger">
                            {capitalizeFirstLetter(item.status)}
                          </span>
                        ) : item.status == "pending" ? (
                          <span className="badge badge-primary">
                            {capitalizeFirstLetter(item.status)}
                          </span>
                        ) : (
                          ""
                        )}
                      </td>

                      <td>{item.registrationScore}</td>
                      {/* <td>{item.discount}</td>
                          <td>{item.commission}</td>
                          <td>{item.rewardPartner}</td>
                          <td>{item.cityRank}</td>
                          <td>{item.starRank}</td> */}
                    </tr>
                  ))}
                {data.length == 0 && (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      {" "}
                      No vendor has found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        {perPage !== 0 &&
          data.length != totalDocuments &&
          data.length !== 0 && (
            <Pagination
              page={page}
              totalDocuments={totalDocuments}
              getNewData={fetchMoreData}
              perPage={perPage}
              currentDocLength={data.length}
            />
          )}
      </div>
      {popup && renderCommisionModal()}
      {popup1 && REnderMou()}
      {popup2 && UpdateName()}
    </div>
  );
};

export default ViewAll;
