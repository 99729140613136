import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";

const BookingDetail = () => {
  const { request: request, response: response } = useRequest();

  const [orderDetail, setOrderDetail] = useState({});
  const { bookingId } = useParams();
  const [page, setPage] = useState(1);
  const [perPage, set_perPage] = useState(10);
  const [ids, setIds] = useState("");

  useEffect(() => {
    if (bookingId) {
      request(
        "GET",
        `admin/user/getOrderDetail?bookingId=${bookingId}&page=${page}&per_page=${perPage}`
      );
    }
  }, [bookingId, page]);

  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        setOrderDetail(response.data);
        // setTotalDocuments(response?.totalDocuments ? response?.totalDocuments : 0);
      }
    }
  }, [response]);
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title="Order Details"
            links={[
              { to: "/", name: "Home" },
              { to: `/orders/${ids}`, name: "Orders" },
            ]}
          />
        </div>
        <div className="col-md-12">
          <div className="ms-panel">
            {/* <div className="ms-panel-header header-mini">
              <div className="d-flex justify-content-between">
                <h6>Order</h6>
                <h6>#135178</h6>
              </div>
            </div> */}
            <div className="ms-panel-body">
              {/* Invoice To */}
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="invoice-address">
                    <h3>Order: </h3>
                    {/* <h5>{upperCase(orderDetails?.userName)}</h5> */}
                    <p>
                      Order Status :
                      {orderDetail?.bookingStatus && (
                        <span
                          className={
                            orderDetail.bookingStatus === "decline"
                              ? "badge badge-dark ml-1"
                              : orderDetail.bookingStatus === "accepted"
                              ? "badge badge-success ml-1"
                              : "badge badge-primary ml-"
                          }
                        >
                          {""}
                          {orderDetail.bookingStatus == "cancel"
                            ? "Cancelled"
                            : orderDetail.bookingStatus == "completed" &&
                              orderDetail.isVerified == "no"
                            ? "Upcoming"
                            : orderDetail.bookingStatus}
                        </span>
                      )}
                    </p>
                    <p className="mb-0">{orderDetail?.address}</p>
                    <p className="mb-0">{orderDetail?.city}</p>
                    <p className="mb-0">{orderDetail?.country}</p>
                    {orderDetail?.menu?.image &&
                      orderDetail?.bookingStatus == "completed" && (
                        <p className="mb-0">
                          {" "}
                          <img
                            className="image-display"
                            src={orderDetail?.menu?.image}
                          />
                        </p>
                      )}
                  </div>
                </div>
                <div className="col-md-6 text-md-right">
                  <ul className="invoice-date">
                    <li>Booking Date : {orderDetail?.bookingDateTime}</li>
                    {/* <li>Due Date : Sunday, April 19 2021</li> */}
                  </ul>
                </div>
              </div>
              {/* Invoice Table */}
              <div className="ms-invoice-table table-responsive mt-5">
                <table className="table table-hover text-right thead-light">
                  <thead>
                    <tr className="text-capitalize">
                      <th className="text-center w-5">id</th>
                      <th className="text-left">Outlet</th>
                      <th className="text-left">Guest Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">{1}</td>
                      <td className="text-left">{orderDetail?.outletName}</td>
                      <td>
                        {orderDetail?.userName ? orderDetail.userName : ""}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>Total Guests:</td>
                      <td>{orderDetail?.totalGuests}</td>
                    </tr>
                  </tfoot>
                </table>
                <br />
                {orderDetail &&
                orderDetail?.promotionDetail &&
                Object.keys(orderDetail.promotionDetail).length ? (
                  <>
                    <h3>Promotion Detail: </h3>
                    <div className="ms-invoice-table table-responsive mt-5">
                      <table className="table table-hover text-right thead-light">
                        <thead>
                          <tr className="text-capitalize">
                            <th className="text-center w-5">id</th>
                            <th className="text-left">Title</th>
                            <th className="text-left">Price (per person)</th>
                            <th className="text-left">Discount(%)</th>
                            <th className="text-left">Image</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">{1}</td>
                            <td className="text-left">
                              {orderDetail?.promotionDetail?.promotionTitle
                                ? orderDetail?.promotionDetail?.promotionTitle
                                : ""}
                            </td>
                            <td className="text-left">
                              {orderDetail?.promotionDetail?.promotionPrice
                                ? orderDetail?.promotionDetail?.promotionPrice
                                : ""}
                            </td>
                            <td className="text-left">
                              {orderDetail?.promotionDetail?.promotionDiscount
                                ? orderDetail?.promotionDetail
                                    ?.promotionDiscount
                                : ""}
                            </td>
                            <td className="text-left">
                              {orderDetail?.promotionDetail?.promotionImage ? (
                                <img
                                  src={
                                    orderDetail?.promotionDetail?.promotionImage
                                  }
                                  alt={
                                    orderDetail?.promotionDetail?.promotionImage
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <>
                  {orderDetail?.menu ? (
                    <>
                      <h3>Menu: </h3>
                      <div className="ms-invoice-table table-responsive mt-5">
                        <table className="table table-hover text-right thead-light">
                          <caption>Menus</caption>
                          <thead>
                            <tr className="text-capitalize">
                              <th className="text-center w-5">id</th>
                              <th className="text-left">description</th>
                              <th className="text-left">qty</th>

                              <th className="text-left">total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetail &&
                              orderDetail?.menu?.menuItem?.map(
                                (menu, index) => (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-left">{menu?.name}</td>
                                    <td>{menu?.quantity}</td>
                                    <td>{menu?.amount}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={3}>Gross Amount: </td>
                              <td>{orderDetail?.menu?.grossAmount}</td>
                            </tr>
                            <tr>
                              <td colSpan={3}>Outlet Discount: </td>
                              <td>{orderDetail?.menu?.outletDiscount}</td>
                            </tr>
                            <tr>
                              <td colSpan={3}>Sortyfy Discount: </td>
                              <td>{orderDetail?.menu?.sortyfyDiscount}</td>
                            </tr>
                            <tr>
                              <td colspan={3}>
                                <table className="table table-hover text-right thead-light">
                                  <tbody>
                                    {orderDetail?.menu?.length > 0 &&
                                      orderDetail?.menu?.taxDetail?.map(
                                        (menu, index) => (
                                          <tr>
                                            <td>
                                              {menu?.title} {menu?.amount}%
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table className="table table-hover text-right thead-light">
                                  <tbody>
                                    {orderDetail?.menu?.taxDetail?.length > 0 &&
                                      orderDetail?.menu?.taxDetail?.map(
                                        (menu, index) => (
                                          <tr>
                                            <td>{menu?.charge}</td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>Paid Amount:</td>
                              <td>{orderDetail?.menu?.netAmount}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </>
                  ) : (
                    "No menu items found"
                  )}
                  <br />
                  <br />
                </>

                {orderDetail?.addonMenu?.length ? (
                  <>
                    <h3>Addon Menus: </h3>

                    {orderDetail?.addonMenu.map((addMenu, i) => {
                      return (
                        <div className="ms-invoice-table table-responsive mt-5">
                          <table className="table table-hover text-right thead-light">
                            <caption>Menus</caption>
                            <thead>
                              <tr className="text-capitalize">
                                <th className="text-center w-5">id</th>
                                <th className="text-left">description</th>
                                <th className="text-left">qty</th>

                                <th className="text-left">total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {addMenu.menuItem?.length &&
                                addMenu.menuItem?.map((m, index) => (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-left">{m?.name}</td>
                                    <td>{m?.quantity}</td>
                                    <td>{m?.amount}</td>
                                  </tr>
                                ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={3}>Gross Amount: </td>
                                <td>{addMenu.grossAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Outlet Discount: </td>
                                <td>{addMenu.outletDiscount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Sortyfy Discount: </td>
                                <td>{addMenu.sortyfyDiscount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Tax: </td>
                                <td>{addMenu.taxAmount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Paid Amount:</td>
                                <td>{addMenu.netAmount}</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="invoice-buttons text-right">
                {" "}
                <Link to="#" className="btn btn-primary mr-2">
                  Print Invoice
                </Link>
                <Link to="#" className="btn btn-primary">
                  Send Invoice
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetail;
