// import React, { useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// // import 'react-apexcharts/dist/react-apexcharts.min.css';

// const LineChart = ({name,data}) => {

//   const [series,setSereies] = useState([
//     {
//       name:'',
//       data: []
//     }
//   ]);

//   useEffect(()=>{
//     setSereies([{name,data}])
//   },[name,data])
//   const [options] = useState({
//     chart: {
//       height: 350,
//       type: 'line',
//       dropShadow: {
//         enabled: true,
//         color: '#000',
//         top: 18,
//         left: 7,
//         blur: 10,
//         opacity: 0.2
//       },
//       toolbar: {
//         show: false
//       }
//     },
//     colors: ['#77B6EA', '#545454'],
//     dataLabels: {
//       enabled: true,
//     },
//     stroke: {
//       curve: 'smooth'
//     },
// //     title: {
// //       text: 'Average High & Low Temperature',
// //       align: 'left'
// //     },
//     grid: {
//       borderColor: '#e7e7e7',
//       row: {
//         colors: ['#f3f3f3', 'transparent'],
//         opacity: 0.5
//       },
//     },
//     markers: {
//       size: 1
//     },
// //     xaxis: {
// //       categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
// //       title: {
// //         text: 'Month'
// //       }
// //     },
//     yaxis: {
//       title: {
// //         text: ''
//       },
//       min: 5,
//       max: 40
//     },
//     legend: {
//       position: 'top',
//       horizontalAlign: 'right',
//       floating: true,
//       offsetY: -25,
//       offsetX: -5
//     }
//   });

//   return (
//     <div>
//       <div id="chart">
//         <ReactApexChart options={options} series={series} type="line" height={350} />
//       </div>
//       <div id="html-dist"></div>
//     </div>
//   );
// };

// export default LineChart;

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ name, data, labels }) => {
  const [series, setSeries] = useState([
    {
      name: "",
      data: [],
    },
  ]);

  useEffect(() => {
    setSeries([{ name, data }]);
  }, [name, data]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#77B6EA", "#545454"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: labels, // Set the dynamic labels here
      title: {
        text: "",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      min: 5,
      max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  });

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: labels, // Update x-axis labels dynamically
      },
    }));
  }, [labels]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default LineChart;
