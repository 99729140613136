import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import useRequest from "../../../hooks/useRequest";
import { capitalizeFirstLetter } from "../../../util/fn";
import Pagination from "../../Pagination/Pagination";

const ViewAll = (props) => {
  const { vendorId } = props?.location?.state;
  const { id: outletId } = props.match.params;
  const { request: request, response: response } = useRequest();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Percentage(%)" },
    { title: "Title" },
    { title: "Start Date" },
    { title: "End Date" },
    { title: "Status" },
    { title: "Discount On" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [headerData, setHeaderData] = useState({
    totalActive: 0,
    totalInactive: 0,
    totalDiscount: 0,
  });
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Discounts - Sportyfy";
    request("POST", `admin/discount/allDiscountCoupon`, {
      outletId,
      page,
      per_page: perPage,
    });
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDiscount);
        setHeaderData({
          totalActive: response.totalActive ? response.totalActive : 0,
          totalInactive: response.totalInactive ? response.totalInactive : 0,
          totalDiscount: response.totalDiscount ? response.totalDiscount : 0,
        });
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request("POST", `admin/discount/allDiscountCoupon`, {
      outletId: outletId,
      startDate,
      endDate,
      page: selected + 1,
      per_page: perPage,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (e.target.name == "startDate") {
      setStartDate(e.target.value);
      if (e.target.value) {
        setMinEndDate(e.target.value);
      } else {
        setMinEndDate("");
      }
    } else if (e.target.name == "endDate") {
      setEndDate(e.target.value);
      if (e.target.value) {
        setMaxStartDate(e.target.value);
      } else {
        setMaxStartDate("");
      }
    }
  };

  const filterHandler = () => {
    request("POST", `admin/discount/allDiscountCoupon`, {
      outletId: outletId,
      startDate,
      endDate,
      page,
      per_page: perPage,
    });
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Discounts"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/vendors", name: "Vendors" },
              { to: `/vendor/total-outlets/${vendorId}`, name: "Outlets" },
            ]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-3">
                  <h6>{"Discounts"}</h6>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="startDate">Start Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      name="startDate"
                      placeholder="Start Date"
                      max={maxStartDate}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="endDate">End Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      name="endDate"
                      placeholder="End Date"
                      min={minEndDate}
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                <div className="col-md-3 my-4">
                  <div className="form-group">
                    <button
                      type="button"
                      onClick={filterHandler}
                      className="btn btn-primary"
                    >
                      {" "}
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="ms-panel-header">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                    <div className="ms-card-body media">
                      <div className="media-body">
                        <span className="black-text">
                          <strong>Total Discount</strong>
                        </span>
                        <h2>{headerData.totalDiscount}</h2>
                      </div>
                    </div>
                    {/* <LineChart data={this.state.data1} options={options} /> */}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                    {/* <span className="ms-chart-label bg-red">
                                        <i className="material-icons">arrow_downward</i>{" "}
                                        4.5%
                                    </span> */}
                    <div className="ms-card-body media">
                      <div className="media-body">
                        <span className="black-text">
                          <strong>Active Coupon</strong>
                        </span>
                        <h2>{headerData.totalActive}</h2>
                      </div>
                    </div>
                    {/* <LineChart data={this.state.data2} options={options} /> */}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                    <div className="ms-card-body media">
                      <div className="media-body">
                        <span className="black-text">
                          <strong>InActive Coupon</strong>
                        </span>
                        <h2>{headerData.totalInactive}</h2>
                      </div>
                    </div>
                    {/* <LineChart data={this.state.data3} options={options} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>{item?.discountAmount}</td>
                          <td>
                            {item.title
                              ? capitalizeFirstLetter(item.title)
                              : ""}
                          </td>
                          <td>{item?.startDate}</td>
                          <td>{item?.endDate}</td>
                          <td>
                            {item.status == "active" ? (
                              <span className="badge badge-success">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : item.status == "inactive" ? (
                              <span className="badge badge-danger">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {item.discount_on
                              ? capitalizeFirstLetter(item.discount_on)
                              : ""}
                          </td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="7" style={{ textAlign: "center" }}>
                          {" "}
                          No discounts has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
