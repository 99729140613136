import React,{useEffect, useState} from 'react'
import useRequest from "../../hooks/useRequest";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
// import { API } from '../../constant/api';
import { useDispatch } from "react-redux";
import { authSuccess } from "../../store/auth/action";
import { capitalizeFirstLetter } from '../../util/fn';

const MyProfile = () => {
    
    const dispatch = useDispatch();
    const { response, request }                                                         =   useRequest();
    const { response:responseUpdate, request:requestUpdate }                            =   useRequest();
    const [touchedError, setTouchedError]                                               =   useState(false);
    const [displayImage, setDisplayImage]                                               =   useState();
    const [image, setImage]                                                             =   useState();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        control
    } = useForm();

    useEffect(() => {
        document.title = "My Profile - Sportyfy";
        request("GET", "admin/getProfile");
    },[])

    useEffect(() => {
        if (response) {
            if(response.status && response.data){
                setValue("name",capitalizeFirstLetter(response.data?.name));
                setValue("email",response.data?.email);
                setValue("mobileNo",response.data?.mobile);
                setImage(response.data?.image);
            }
        }
    }, [response]);

    
    const onSubmit = (data) => {
        const {name, email, mobileNo}   =   data;
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("mobile", mobileNo);

        if (displayImage) {
            if (data.profilePic) {
              formData.append("image", data.profilePic);
            }
        } else {
            if(image == null || image == 'undefined'){
              formData.append("oldImage", '');
            }else{
              formData.append("oldImage", image);
            }
        }

        requestUpdate("POST", "admin/updateProfile",formData );
    }

    useEffect(() => {
        if (responseUpdate) {
            if(responseUpdate.status && responseUpdate.data){
                dispatch(
                    authSuccess({
                        image: responseUpdate.data.image,
                        name: responseUpdate.data.name ? capitalizeFirstLetter(responseUpdate.data.name):"",
                        email:responseUpdate.data.email,
                    })
                );
                toast.success(responseUpdate.message);
            }
        }
    }, [responseUpdate]);
    
    const handleImage = (event) => {
        event.preventDefault();
        if (event.target.files[0]) {
          setDisplayImage(URL.createObjectURL(event.target.files[0]));
          setError("profilePic", "");
          setValue("profilePic", event.target.files[0]);
        } else {
          setDisplayImage("");
          setImage("");
          setValue("profilePic", "");
        }
    };

    const handleRemoveImage = () => {
        setDisplayImage(null);
        setValue("profilePic", null);
        setImage("");
    };
    return (
    <div className="ms-content-wrapper">
        <div className="row">
            <div className="col-md-12">
                {/* <Breadcrumb/> */}
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="ms-panel">
                    <div className="ms-panel-header">
                        <h6>My Profile</h6>
                    </div>
                    <div className="ms-panel-body">
                        <form className="needs-validation" noValidate  onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                              <div className="col-md-3 mb-3">
                                  <div className="input-group">
                                     <img
                                        src={
                                        displayImage
                                            ? `${displayImage}`
                                            : image
                                            ? `${image}`
                                            : "/assets/img/avtar.png"
                                        }
                                        alt="admin-profile"
                                        className="ms-user-img ms-profile-img-round"
                                    />

                                     <Controller
                                        control={control}
                                        name="profilePic"
                                        render={({ field: { onChange, value, ref } }) => (
                                        <input
                                            type="file"
                                            id="profilePic"
                                            onChange={handleImage}
                                            style={{ display: "none" }}
                                            inputRef={ref}
                                            accept="image/*"
                                        />
                                        )}
                                    />
                                  </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                  {displayImage || image ? (
                                    <>    
                                        <button
                                            type="button"
                                            onClick={handleRemoveImage}
                                            className="btn btn-primary"
                                        >
                                            Delete
                                        </button>
                                        <label className="btn btn-primary mx-2" htmlFor="profilePic">
                                            Replace
                                        </label>
                                    </>
                                    ) : (
                                    <label className="btn btn-primary" htmlFor="profilePic">
                                        Add New
                                    </label>
                                 )}
                              </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="name">Name<span className="field-required">*</span></label>
                                    <div className="input-group">
                                        <input type="text" id="name" placeholder="Name" 
                                        className={`form-control ${
                                            touchedError ? ( errors.name ? "is-invalid" : "is-valid" ): "" 
                                        }`}
                                        {...register("name", {
                                            required: true,
                                        })}/>
                                         {errors.name?.type === "required" && (
                                            <div className="invalid-field">
                                                The name field is required.
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="email">Email<span className="field-required">*</span></label>
                                    <div className="input-group">
                                        <input type="text" id="email" placeholder="Email" 
                                        // disabled={true}
                                        className={`form-control ${
                                            touchedError ? ( errors.email ? "is-invalid" : "is-valid" ): "" 
                                          }`}
                                        {...register("email", {
                                            required: true,
                                            pattern:
                                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                          })}/>
                                         {errors.email?.type === "required" && (
                                            <div className="invalid-field">
                                                The email field is required.
                                            </div>
                                        )}
                                         {errors.email?.type === "pattern" && (
                                            <div className="invalid-field">
                                                Please enter a valid email.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="mobileNo">Mobile Number<span className="field-required">*</span></label>
                                    <div className="input-group">
                                        <input type="text" id="mobileNo" placeholder="Mobile Number" 
                                        className={`form-control ${
                                            touchedError ? ( errors.mobileNo ? "is-invalid" : "is-valid" ): "" 
                                          }`}
                                        {...register("mobileNo", {
                                            required: true,
                                            pattern: /^[0-9]{10}$/gm,
                                          })}/>
                                         {errors.mobileNo?.type === "required" && (
                                            <div className="invalid-field">
                                                The mobile field is required.
                                            </div>
                                        )}
                                         {errors.mobileNo?.type === "pattern" && (
                                            <div className="invalid-field">
                                                Please enter valid 10 digit Mobile number.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary mt-4 d-block w-50" onClick={() => setTouchedError(true)} type="submit">Update Profile</button>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default MyProfile
