import React, { Component } from 'react';
import { Bar as BarChart } from 'react-chartjs-2';
// import faker from 'faker';
// Bar chart
function chartData1(props) {
    const values = Object.values(props.data)
   

    let yesData = [];
    let noData = []
    let allData = [];
    if(values){
        let data1 = [];
        values.find((obj,i) => {
            const innerKeys = Object.keys(obj)
            if(innerKeys && innerKeys.length>0){
                for(let i=0;i<innerKeys.length;i++){
                    data1[i] = {label:innerKeys[i]};
                    if(innerKeys[i] == "Yes"){
                        yesData.push(obj[innerKeys[i]])
                        if(!allData.includes(obj[innerKeys[i]])){
                            allData.push(obj[innerKeys[i]]);
                        }
                    }else if(innerKeys[i] == "No"){
                        noData.push(obj[innerKeys[i]])
                        if(!allData.includes(obj[innerKeys[i]])){
                            allData.push(obj[innerKeys[i]]);
                        }
                    }
                    
                }
              
            }
        })
    }

    const allDataMax = Math.max(...allData);
    return {
        labels: Object.keys(props.data),
        datasets: [
            {
                label: "Yes",
                backgroundColor: "#3e95cd",
                data: yesData
            }, {
                label: "No",
                backgroundColor: "#8e5ea2",
                data: noData
            }
        ]
    }
}

class Groupedbarchart extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            data1: chartData1(props),
            open: true,
            options:{
                title: {
                    display: true,
                    text: 'Net Promoter Score (NPS)'
                },
                scales: {
                    yAxes: [{
                      ticks: {
                         max: props.totalReview?props.totalReview:0,
                         min: 0,
                        //  stepSize: 5
                       }
                     }]
                },
                
            }
        }
    };
    render() {
        return (
            <BarChart data={this.state.data1} options={this.state.options} />
        );
    }
}

export default Groupedbarchart;