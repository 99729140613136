import React from 'react'

const ImagePreview = ({displayImage,image}) => {
  return (
    <img
        src={
        displayImage
            ? `${displayImage}`
            : image
            ? `${image}`
            : "/assets/img/avtar.png"
        } alt="city-attraction-image"
        style={{height:'200px'}}
        className="d-block w-100"
    />

  )
}

export default ImagePreview
