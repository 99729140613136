import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../util/fn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ViewAll = (props) => {
  const { request: requestPermissions, response: responsePermissions } =
    useRequest();
  const history = useHistory();
  const { request: request, response: response } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();
  const { request: requestRoleData, response: responseRoleData } = useRequest();
  const [data, setData] = useState({
    label: "",
    name: "",
    status: "active",
  });
  const [roleId, setRoleId] = useState();
  const [permissions, setPermissions] = useState([
    // {
    //   moduleName: "moduleName",
    //   showWebAccess: "showWebAccess",
    // },
  ]);

  useEffect(() => {
    document.title = "Banners - Sportyfy";
    requestPermissions("GET", "admin/role/getAllPermissions");
    request("GET", `admin/role/getAllRoles`);
  }, []);

  useEffect(() => {
    if (responsePermissions) {
      if (responsePermissions.status && responsePermissions.data) {
        setPermissions(
          responsePermissions.data.map((item) => {
            return {
              ...item,
              permissionId: item?._id,
            };
          })
        );
      }
    }
  }, [responsePermissions]);
  useEffect(() => {
    if (responseRoleData && responsePermissions) {
      if (responseRoleData.status && responseRoleData.data) {
        setData({
          label: responseRoleData?.data?.label,
          name: responseRoleData?.data?.name,
          status: responseRoleData?.data?.status,
        });

        setPermissions(
          responseRoleData?.data?.permissions.map((item, index) => {
            return {
              ...item,
              _id: item?.permissionId,
              moduleName: responsePermissions.data
                ? responsePermissions.data[index]?.moduleName
                : "",
            };
          })
        );
      }
    }
  }, [responseRoleData]);

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.status) {
        if (roleId) {
          toast.success("Role has been updated successfully.");
        } else {
          toast.success("Role has been added successfully.");
        }
        history.push("/roles");
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    if (props?.location?.state) {
      setRoleId(props?.location?.state?.roleId);
      requestRoleData(
        "GET",
        `admin/role/getRoleById?roleId=${props?.location?.state?.roleId}`
      );
    }
  }, []);
  const handleSearch = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handlePermission = (e, item) => {
    let tempPerm = [...permissions];
    tempPerm.forEach((element, index) => {
      if (element?._id === item?._id) {
        tempPerm[index][e.target.name] = e.target.checked;
      }
    });
    setPermissions(tempPerm);
  };

  const handleAddRole = () => {
    if (roleId) {
      requestStatus("POST", "admin/role/updateRole", {
        ...data,
        roleId,
        permissions,
      });
    } else {
      requestStatus("POST", "admin/role/addRole", { ...data, permissions });
    }
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Roles"} links={[{ to: "/", name: "Home" }]} />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row pb-4">
                <div className="col-md-6">
                  <h6>{roleId ? "Edit Role" : "Add Role"}</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="search">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      value={data?.name}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="search">Label:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="label"
                      value={data?.label}
                      placeholder="label"
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="status">Status:</label>
                    <select
                      className="form-control"
                      id="status"
                      name="status"
                      value={data?.status}
                      onChange={handleSearch}
                    >
                      <option value={"active"}>Active</option>
                      <option value={"inactive"}>InActive</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th
                    rowSpan={2}
                    width="50%"
                    className="text-center align-middle"
                    scope="col"
                  >
                    Modules
                  </th>
                  <th
                    colSpan={3}
                    width="50%"
                    className=" text-center uppercase font-bold text-xl "
                  >
                    Web Access
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Read</th>
                  <th className="text-center">Write</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {permissions?.map((item, index) => {
                  return (
                    <tr className="bg-gray-100" key={index}>
                      <td className="text-left py-4 px-4 uppercase font-bold text-2xl ">
                        {item.moduleName}
                      </td>
                      <td className="text-center align-middle">
                        <input
                          type="checkbox"
                          name={`webAccessRead`}
                          className="w-8 h-8"
                          checked={item?.webAccessRead}
                          onChange={(event) => handlePermission(event, item)}
                          // {...register(`permissions.${index}.webAccessRead`)}
                        />
                      </td>
                      <td className="text-center align-middle">
                        <input
                          type="checkbox"
                          checked={item?.webAccessWrite}
                          name={`webAccessWrite`}
                          onChange={(event) => handlePermission(event, item)}
                          className="w-8 h-8"
                          // {...register(`permissions.${index}.webAccessWrite`)}
                          // onChange={(event) => handelChange(event)}
                        />
                      </td>
                      <td className="text-center align-middle">
                        <input
                          type="checkbox"
                          className="w-8 h-8"
                          checked={item?.webAccessEdit}
                          name={`webAccessEdit`}
                          onChange={(event) => handlePermission(event, item)}
                          // {...register(`permissions.${index}.webAccessEdit`)}
                          // onChange={(event) => handelChange(event)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-4"></div>
              <div className="col-md-2 justify-content-center d-flex ">
                <div className="form-group">
                  <button className="btn btn-primary" onClick={handleAddRole}>
                    {" "}
                    {roleId ? "Update" : "Add Role"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
