import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";

import Dashboard from "../pages/Dashboard/Dashboard";
import ChangePassword from "../components/ChangePassword/ChangePassword";
import MyProfile from "../components/MyProfile/MyProfile";
import ViewAllCityEvents from "../components/CityEvents/ViewAll";
import AddCityEvents from "../components/CityEvents/Add";
import EditCityEvents from "../components/CityEvents/Edit";
import ViewAllCityAttractions from "../components/CityAttractions/ViewAll";
import AddCityAttractions from "../components/CityAttractions/Add";
import EditCityAttractions from "../components/CityAttractions/Edit";
import ViewAllCategory from "../components/Categories/ViewAll";
import AddCategory from "../components/Categories/Add";
import EditCategory from "../components/Categories/Edit";
import AddBanner from "../components/Banners/Add";
import ViewAllBanner from "../components/Banners/ViewAll";
import ViewAllVendor from "../components/Vendors/ViewAll";
import ViewSingleOutlet from "../components/Vendors/SingleOutlet";
import ViewAllOutlet from "../components/Vendors/AllOutlet";
import ViewAllOrders from "../components/Vendors/Orders/ViewAll";
import ViewAllMenus from "../components/Vendors/Menu/ViewAll";
import ViewAllDiscounts from "../components/Vendors/Discounts/ViewAll";
import ViewAllPromotions from "../components/Vendors/Promotions/ViewAll";
import ViewAllUsers from "../components/Users/ViewAll";
import ViewAllUsersOrders from "../components/Users/Orders/ViewAll";
import ViewAllReports from "../components/Vendors/Reports/ViewAll";
import ViewAllTrends from "../components/Vendors/Trends/ViewAllTrends";
import ViewAllFinanceReport from "../components/FinanceReport/ViewAll";
import ViewAllFinanceReportOutlets from "../components/FinanceReport/Outlets/ViewAll";
import ViewAllRegistrations from "../components/Registrations/ViewAll";
import ViewAllReviews from "../components/Reviews/ViewAll";
import ViewAllReplies from "../components/Reviews/Replies/ViewAll";
import ViewAllVendorFinanceReport from "../components/Vendors/FinanceReport/ViewAll";
import ViewAllRoles from "../components/Roles/ViewAll";
import AddRole from "../components/Roles/Add";
import ViewAllStaff from "../components/Staff/ViewAll";
import AddStaff from "../components/Staff/Add";
import Growth from "../components/Reports/Growth";
import Revenue from "../components/Reports/Revenue";
import Commission from "../components/Reports/Commission";
import Discount from "../components/Reports/Discount";
import VendorGrowth from "../components/Reports/VendorGrowht";
import OrderList from "../components/Users/OrderList";
import BoookingDetail from "../components/Users/BookingDetail";
import BookingList from "../pages/Bookings/BookingList";
import BookingDetail from "../pages/Bookings/BookingDetail";
import EditOutlet from "../components/Vendors/EditOutlet";
import EditMenu from "../components/Vendors/Menu/EditMenu";

export const privateRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/change-password", component: ChangePassword },
  { path: "/booking-orders", component: BookingList },
  { path: "/booking-details/:id", component: BookingDetail },
  { path: "/my-profile", component: MyProfile },
  { path: "/city-events", component: ViewAllCityEvents },
  { path: "/city-event/add", component: AddCityEvents },
  { path: "/city-event/edit/:id", component: EditCityEvents },
  { path: "/city-attractions", component: ViewAllCityAttractions },
  { path: "/city-attraction/add", component: AddCityAttractions },
  { path: "/city-attraction/edit/:id", component: EditCityAttractions },
  { path: "/categories", component: ViewAllCategory },
  { path: "/category/add", component: AddCategory },
  { path: "/category/edit/:id", component: EditCategory },
  { path: "/banner/add", component: AddBanner },
  { path: "/banner/update/:id", component: AddBanner },
  { path: "/banners", component: ViewAllBanner },
  { path: "/vendors", component: ViewAllVendor },
  { path: "/vendor/view-outlet/:id", component: ViewSingleOutlet },
  { path: "/vendor/total-outlets/:id", component: ViewAllOutlet },
  { path: "/vendor/edit-menu/:id/:outletId", component: EditMenu },

  { path: "/vendor/edit/:id", component: EditOutlet },
  { path: "/vendor/orders/:id", component: ViewAllOrders },
  { path: "/vendor/menus/:id", component: ViewAllMenus },
  { path: "/vendor/promotions/:id", component: ViewAllPromotions },
  { path: "/vendor/reports/:id", component: ViewAllReports },
  { path: "/vendor/trends/:id", component: ViewAllTrends },
  { path: "/vendor/discounts/:id", component: ViewAllDiscounts },
  { path: "/users", component: ViewAllUsers },
  { path: "/users/orders/:id", component: ViewAllUsersOrders },
  { path: "/finance-report", component: ViewAllFinanceReport },
  {
    path: "/finance-report/outlet/:id",
    component: ViewAllFinanceReportOutlets,
  },
  { path: "/registrations", component: ViewAllRegistrations },
  { path: "/vendor/finance-report/:id", component: ViewAllVendorFinanceReport },
  { path: "/roles", component: ViewAllRoles },
  { path: "/role/add", component: AddRole },
  { path: "/role/edit:id", component: AddRole },
  { path: "/orderList/:userId/:name", component: OrderList },
  { path: "/BookingDetail/:bookingId", component: BoookingDetail },
  { path: "/staffs", component: ViewAllStaff },
  { path: "/staff/add", component: AddStaff },
  { path: "/staff/edit:id", component: AddStaff },
  { path: "/reviews", component: ViewAllReviews },
  { path: "/reviews/reply/:id", component: ViewAllReplies },

  { path: "/growth-report", component: Growth },
  { path: "/revenue-report", component: Revenue },
  { path: "/commision-report", component: Commission },
  { path: "/discount-report", component: Discount },
  { path: "/vendor-report", component: VendorGrowth },
];

export const notPrivateRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token", component: ResetPassword },
];
