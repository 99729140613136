import React,{useEffect, useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import { CKEditor } from 'ckeditor4-react';
import { useHistory } from "react-router-dom";
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import ImagePreview from '../ImagePreview/ImagePreview';
import useRequest from "../../hooks/useRequest";
import { toast } from "react-toastify";

const Edit = (props) => {
  const { id:recordId } = props.match.params;
  const history = useHistory();
  const { response, request }                                                         =   useRequest();
  const { response:responseUpdate, request:requestUpdate }                            =   useRequest();
  const {request:requestMainCat, response: responseMainCat}                             =   useRequest();

  const [mainCat, setMainCat]                                                           =   useState([]);
  const [touchedError, setTouchedError]                                               =   useState(false);
  const [displayImage, setDisplayImage]                                               =   useState();
  const [image, setImage]                                                             =   useState();
  const [selectedCat, setSelectedCat]                                               =   useState();
  const [description, setDescription]                                               =   useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError, 
    getValues,
    control,
    clearErrors
} = useForm();

  useEffect(() => {
    document.title = "Edit Category - Sportyfy";
    requestMainCat("GET","manageCategory/GetAllParentCategory")
   
    request("POST",`manageCategory/getCategoryById`,{catId:recordId})
},[recordId])

useEffect(() => {
  if(responseMainCat){
    if(responseMainCat.status && responseMainCat.data){
      setMainCat(responseMainCat.data);
    }
  }
},[responseMainCat])

useEffect(() => {
  if(response){
    if(response.status && response.data){
      setValue("name",response.data[0]?.name);
      setValue("type",response.data[0]?.type);
      setValue("status",response.data[0]?.status);
      setSelectedCat(response.data[0]?.type);
    }
  }
},[response])



  const onSubmit = (data) => {
    const {type, name, status}   =   data;
    requestUpdate("POST", "manageCategory/updateCategory",{name,status,type,catId:recordId} );
  }

  useEffect(() => {
    if (responseUpdate) {
        if(responseUpdate.status){
            toast.success("Category has been updated successfully.");
            history.push("/categories")
        }
    }
}, [responseUpdate]);


  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
        <Breadcrumb
                  title={"Edit"}
                  links={[
                      { to: "/dashboard", name: "Home" },
                      { to: "/categories", name: "Categories" },
                  ]}
              />
           
        </div>
        <div className="col-xl-12 col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Edit Category</h6>
                </div>
                <div className="ms-panel-body">
                    <form className="needs-validation clearfix" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="type">Main Category<span className="field-required">*</span></label>
                                <div className="input-group">
                                  <select {...register("type", {
                                        required: true,
                                    })}  className={`form-control ${
                                        touchedError ? ( errors.type ? "is-invalid" : "is-valid" ): "" 
                                      }`}  id="city">
                                        <option value={""}>Select</option>
                                        {mainCat && mainCat.length>0 && mainCat.map(obj => (
                                          <option key={obj._id} selected={obj._id == selectedCat ? "selected":""}value={obj._id}>{obj.name}</option>
                                        ))}
                                    </select>
                                    
                                      {errors.type?.type === "required" && (
                                        <div className="invalid-field">
                                            The main category field is required.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="name">Name<span className="field-required">*</span></label>
                                <div className="input-group">
                                    <input type="text" id="name" placeholder="Name" 
                                        className={`form-control ${
                                            touchedError ? ( errors.name ? "is-invalid" : "is-valid" ): "" 
                                        }`}
                                        {...register("name", {
                                            required: true,
                                        })}/>
                                         {errors.name?.type === "required" && (
                                            <div className="invalid-field">
                                                The name field is required.
                                            </div>
                                        )}
                                </div>
                            </div>
                      
                            <button className="btn btn-primary mt-4 d-block w-50" onClick={() => setTouchedError(true)} type="submit">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Edit
