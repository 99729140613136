import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import useRequest from "../../hooks/useRequest";

const ChangePassword = () => {
    const { response, request }                         = useRequest();
    const [touchedError, setTouchedError]               = useState(false)
    const [isPasswordVisible, setIsPasswordVisible]     = useState({
                                                            currentPassword:false,
                                                            newPassword:false,
                                                            confirmPassword:false
                                                        });
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError
    } = useForm();

    useEffect(() => {
        document.title = "Change Password - Sportyfy";
    },[])
    const onSubmit = (data) => {
        const {currentPassword, newPassword, confirmPassword}   =   data;
        if(newPassword != confirmPassword){
            setError("confirmPassword",{type:"manual"});
            return;
        }
        request("POST", "admin/changePassword", { oldPassword:currentPassword,newPassword});
    }

    useEffect(() => {
        if (response) {
            if(response.status){
                toast.success(response.message);
            }
        }
      }, [response]);

    return (
    <div className="ms-content-wrapper">
    <div className="row">
        <div className="col-md-12">
            {/* <Breadcrumb/> */}
        </div>

        <div className="col-xl-12 col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Change Password</h6>
                </div>
                <div className="ms-panel-body">
                    <form className="needs-validation clearfix" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="col-xl-4 col-md-12 mb-3">
                                <label htmlFor="currentPassword">Current Password<span className="field-required">*</span></label>
                                <div className="input-group">
                                    <input type={isPasswordVisible.currentPassword ? "text" : "password"} id="currentPassword" placeholder="Current Password" className={`form-control ${
                                        touchedError ? ( errors.currentPassword ? "is-invalid" : "is-valid" ): "" 
                                      }`} {...register("currentPassword", {
                                        required: true,
                                        // pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                                      })} />
                                    <Link to="#" onClick={() => {
                                        const update = {...isPasswordVisible, currentPassword : isPasswordVisible.currentPassword ? false :true}
                                        setIsPasswordVisible(update)
                                        }}>
                                        {isPasswordVisible.currentPassword? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                                    </Link>
                                    {errors.currentPassword?.type === "required" && (
                                        <div className="invalid-field">
                                            The current password field is required.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-12 mb-3">
                                <label htmlFor="newPassword">New Password<span className="field-required">*</span></label>
                                <div className="input-group">
                                    <input type={isPasswordVisible.newPassword ? "text" : "password"} id="newPassword" placeholder="New Password" className={`form-control ${
                                        touchedError ? ( errors.newPassword ? "is-invalid" : "is-valid" ): "" 
                                      }`} {...register("newPassword", {
                                        required: true,
                                      })} />
                                    <Link to="#" onClick={() => {
                                        const update = {...isPasswordVisible, newPassword : isPasswordVisible.newPassword ? false :true}
                                        setIsPasswordVisible(update)
                                        }}>
                                        {isPasswordVisible.newPassword? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                                    </Link>
                                    {errors.newPassword?.type === "required" && (
                                        <div className="invalid-field">
                                            The new password field is required.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-12 mb-3">
                                <label htmlFor="confirmPassword">Confirm Password<span className="field-required">*</span></label>
                                <div className="input-group">
                                    <input type={isPasswordVisible.confirmPassword ? "text" : "password"} id="confirmPassword" placeholder="Confirm Password" className={`form-control ${
                                        touchedError ? ( errors.confirmPassword ? "is-invalid" : "is-valid" ): "" 
                                      }`} {...register("confirmPassword", {
                                        required: true,
                                        // pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                                      })} />
                                    <Link to="#" onClick={() => {
                                        const update = {...isPasswordVisible, confirmPassword : isPasswordVisible.confirmPassword ? false :true}
                                        setIsPasswordVisible(update)
                                        }}>
                                        {isPasswordVisible.confirmPassword? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                                    </Link>
                                    {errors.confirmPassword?.type === "required" && (
                                        <div className="invalid-field">
                                            The confirm password field is required.
                                        </div>
                                    )}
                                      {errors.confirmPassword?.type === "manual" && (
                                        <div className="invalid-field">
                                            New password and confirm password does not match.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-primary float-right" type="submit" onClick={() => setTouchedError(true)}>Update</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default ChangePassword
