import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { capitalizeFirstLetter, downloadExcel1, getPermissionDetails } from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import Select from "react-select";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASEURL } from "../../constant/api";
import { Button } from "react-bootstrap";

const ViewAll = (props) => {
  const { request: request, response: response } = useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const [permission, setPermissions] = useState();
  const [data, setData] = useState([]);
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Name" },
    { title: "Email" },
    { title: "Role" },
    { title: "Mobile" },
    { title: "Status" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);

  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Staff - Sportyfy";
    request("GET", `admin/staff/getAllStaff`);
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this staff?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        requestDelete("POST", "admin/staff/deleteStaff", { staffId: id });
      }
    });
  };

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDocument);
      }
    }
  }, [response]);

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status) {
        toast.success("Staff has been deleted successfully.");
        if (responseDelete.id) {
          let newRecord = data;
          newRecord = newRecord.filter((r) => r._id != responseDelete.id);
          setData(newRecord);

          let newTotal = totalDocuments;
          setTotalDocuments(newTotal - 1);
          if (newRecord.length == 0) {
            setPage(page - 1);
            request(
              "GET",
              `admin/staff/getAllStaff?${page - 1}&per_page=${perPage}`
            );
          }
        }
      }
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request("GET", `admin/staff/getAllStaff?${page - 1}&per_page=${perPage}`);
  };

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);

  const DownloadExcel = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("token")
      }
    };

    try {
      const response = await axios.get(`${BASEURL.PORT}/admin/staff/getAllStaff?isExcelDownload=${true}`, config);
      if (response?.data?.status == true) {
        let Ranking = response?.data?.data?.map((item, index) => ({
          'Sr.': index + 1,
          'Name': item.name,
          'Email': item.email,
          'Role': item.role,
          'Mobile': item.mobile,


        }));



        const dataArray = [Ranking];
        const namesArray = ["Venodrs"];
        const headingsArray = [null, null]; // Assuming no headers for the data

        downloadExcel1(dataArray, namesArray, headingsArray);
      }

    } catch (error) {
      toast.error(error.message)
    }

  }
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Staffs"} links={[{ to: "/", name: "Home" }]} />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-4">
                  <h6>{"Staffs"}</h6>
                </div>
                <div className="col-md-2"></div>
                {permission?.webAccessWrite && (
                  <div className="col-md-4 justify-content-end d-flex">
                    <div className="form-group">
                      <Link to="/staff/add" className="btn btn-primary">
                        {" "}
                        Add Staff
                      </Link>
                    </div>
                  </div>
                )}

                <div className="col-md-2">
                  <div className="form-group">
                    <Button className="btn btn-primary" onClick={DownloadExcel}>
                      {" "}
                      Download
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item._id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.role}</td>
                          <td>{item.mobile}</td>
                          <td>
                            {item.status == "active" ? (
                              <span className="badge badge-success">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : item.status == "inactive" ? (
                              <span className="badge badge-danger">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          {permission?.webAccessEdit && (
                            <td>
                              <Link
                                to={{
                                  pathname: `/staff/edit:id${item._id}`,
                                  state: { staffId: item._id },
                                }}
                              >
                                <i
                                  title="Status"
                                  className="fas fa-pencil-alt text-secondary"
                                ></i>
                              </Link>
                              <Link
                                to="#"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i
                                  title="Delete"
                                  className="far fa-trash-alt ms-text-danger"
                                />
                              </Link>
                            </td>
                          )}
                          {/* {onSerialInc()} */}
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No staff has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
