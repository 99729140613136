import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { capitalizeFirstLetter, downloadExcel1, getPermissionDetails } from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { BASEURL } from "../../constant/api";
import axios from "axios";

const ViewAll = (props) => {
  const { request: requestCat, response: responseCat } = useRequest();
  const { request: request, response: response } = useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [mainCat, setMainCat] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Main Category" },
    { title: "Name" },
    { title: "Status" },
  ]);
  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [mainCategory, setMainCategory] = useState();
  const [startIndex, setStartIndex] = useState(1);

  let serialCount = 0;
  useEffect(() => {
    document.title = "Categories - Sportyfy";
    requestCat("GET", "manageCategory/GetAllParentCategory");
    request("POST", "manageCategory/getAllCategory", {
      type: "",
      page: page,
      per_page: perPage,
    });
  }, []);

  useEffect(() => {
    if (responseCat) {
      if (responseCat.status && responseCat.data) {
        setMainCat(responseCat.data);
      }
    }
  }, [responseCat]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        requestDelete("POST", "manageCategory/removeCategory", { catId: id });
      }
    });
  };

  useEffect(() => {
    if (response) {
      if (response.status) {
        // if(response.data && response.data.length>0){
        setData(response.data);
        setTotalDocuments(response.totalDocument);
        // }
      }
    }
  }, [response]);

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status) {
        toast.success("Category has been deleted successfully.");
        if (responseDelete.id) {
          let newRecord = data;
          newRecord = newRecord.filter((r) => r._id != responseDelete.id);
          setData(newRecord);
          let newTotal = totalDocuments;
          setTotalDocuments(newTotal - 1);
          if (newRecord.length == 0) {
            setPage(page - 1);
            const mainCat = document.getElementById("mainCat").value;
            request("POST", "manageCategory/getAllCategory", {
              type: mainCat,
              page: page - 1,
              per_page: perPage,
            });
          }
        }
      }
    }
  }, [responseDelete]);

  const mainCatHandler = (e) => {
    e.preventDefault();
    setMainCategory(e.target.value);
    request("POST", "manageCategory/getAllCategory", {
      type: e.target.value,
      page: page,
      per_page: perPage,
    });
  };

  const handleStatus = (id, status) => {
    let newStatus = status == "active" ? "inactive" : "active";
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change!",
    }).then(function (result) {
      if (result.value) {
        requestStatus("POST", "manageCategory/updateCategoryStatus", {
          catId: id,
          status: newStatus,
        });
      }
    });
  };

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.status) {
        const { id, updatedStatus } = responseStatus;
        toast.success("Category status has been changed successfully.");

        const oldRecords = data;
        const indexToChange = oldRecords.findIndex((r) => r._id == id);
        oldRecords[indexToChange].status = updatedStatus;

        setData(oldRecords);
      }
    }
  }, [responseStatus]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    const mainCat = document.getElementById("mainCat").value;
    request("POST", "manageCategory/getAllCategory", {
      type: mainCat,
      page: selected + 1,
      per_page: perPage,
    });
  };

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);

  const DownloadExcel = async () => {


    const data = {

      type: mainCategory ? mainCategory : "",
      isExcelDownload: true,
      page:'',
      per_page:""

    }
 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("token")
      }
    };

    try {
      const response = await axios.post(`${BASEURL.PORT}/manageCategory/getAllCategory`, data, config);
      if (response?.data?.status == true) {
        let Ranking = response?.data?.data?.map((item, index) => ({
          'Sr.': index + 1,
          'Name': item.name,
          'Vendor Type': item.vendorType,
          'Status': item.status,

        }));



        const dataArray = [Ranking];
        const namesArray = ["Categories"];
        const headingsArray = [null, null]; // Assuming no headers for the data

        downloadExcel1(dataArray, namesArray, headingsArray);
      }

    } catch (error) {
      toast.error(error.message)
    }

  }

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Categories"}
            links={[{ to: "/", name: "Home" }]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-4">
                  <h6>{"Categories"}</h6>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="cities">Main Category:</label>
                    <select
                      className="form-control"
                      id="mainCat"
                      onChange={mainCatHandler}
                    >
                      <option value={""}>All</option>
                      {mainCat &&
                        mainCat.length > 0 &&
                        mainCat.map((obj) => (
                          <option key={obj._id} value={obj._id}>
                            {obj.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {permission?.webAccessWrite && (
                  <div className="col-md-2 my-4">
                    <div className="form-group">
                      <Link to="/category/add" className="btn btn-primary">
                        {" "}
                        Add New Category
                      </Link>
                    </div>
                  </div>
                )}
                <div className="col-md-2">
                  <div className='d-flex'>
                    <button className="mx-3 btn btn-primary burron" onClick={DownloadExcel}>Download Excel</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={i}>
                          <th scope="row">{i + startIndex}</th>
                          <td>
                            {item.type ? capitalizeFirstLetter(item.type) : ""}
                          </td>
                          <td>
                            {item.name ? capitalizeFirstLetter(item.name) : ""}
                          </td>
                          <td>
                            {item.status == "active" ? (
                              <span className="badge badge-success">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : item.status == "inactive" ? (
                              <span className="badge badge-danger">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          {permission?.webAccessEdit && (
                            <td>
                              <Link
                                to="#"
                                onClick={() =>
                                  handleStatus(item._id, item.status)
                                }
                              >
                                <i
                                  title="Status"
                                  className="flaticon-tick-inside-circle"
                                ></i>
                              </Link>
                              <Link to={`category/edit/${item._id}`}>
                                <i
                                  title="Edit"
                                  className="fas fa-pencil-alt text-secondary"
                                />
                              </Link>
                              <Link
                                to="#"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i
                                  title="Delete"
                                  className="far fa-trash-alt ms-text-danger"
                                />
                              </Link>
                            </td>
                          )}
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="5" style={{ textAlign: "center" }}>
                          {" "}
                          No categories has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length != 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
