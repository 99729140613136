import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  capitalizeFirstLetter,
  downloadExcel1,
  getPermissionDetails,
} from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BASEURL } from "../../constant/api";
import axios from "axios";

const ViewAll = (props) => {
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: request, response: response } = useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Banner" },
    { title: "Start Date" },
    { title: "End Date" },
    { title: "Amount" },
    { title: "Total Amount" },
    { title: "Status" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);

  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Banners - Sportyfy";
    requestCities("GET", "admin/vendor/getCity");
    request("GET", `admin/getBanner?page=${page}&per_page=${perPage}`);
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  const cityHandler = (e) => {
    e.preventDefault();

    request(
      "GET",
      `admin/getBanner?city=${e.target.value}&page=${page}&per_page=${perPage}`
    );
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this banner?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        requestDelete("POST", "admin/removeBanner", { bannerId: id });
      }
    });
  };

  const handleStatus = (id, status) => {
    let newStatus = status == "active" ? "inactive" : "active";
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change!",
    }).then(function (result) {
      if (result.value) {
        requestStatus("POST", "admin/updateBannerStatus", {
          bannerId: id,
          status: newStatus,
        });
      }
    });
  };

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDocument);
      }
    }
  }, [response]);

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status) {
        toast.success("Banner has been deleted successfully.");
        if (responseDelete.id) {
          let newRecord = data;
          newRecord = newRecord.filter((r) => r.bannerId != responseDelete.id);
          setData(newRecord);

          let newTotal = totalDocuments;
          setTotalDocuments(newTotal - 1);
          if (newRecord.length == 0) {
            setPage(page - 1);
            const city = document.getElementById("cities").value;
            request(
              "GET",
              `admin/getBanner?city=${city}&page=${
                page - 1
              }&per_page=${perPage}`
            );
          }
        }
      }
    }
  }, [responseDelete]);

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.status) {
        const { id, updatedStatus } = responseStatus;
        toast.success("Banner status has been changed successfully.");

        const oldRecords = data;
        const indexToChange = oldRecords.findIndex((r) => r.bannerId == id);
        oldRecords[indexToChange].status = updatedStatus;

        setData(oldRecords);
      }
    }
  }, [responseStatus]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }

    const city = document.getElementById("cities").value;
    request(
      "GET",
      `admin/getBanner?city=${city}&page=${selected + 1}&per_page=${perPage}`
    );
  };

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);
  const DownloadExcel = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      const response = await axios.get(
        `${
          BASEURL.PORT
        }/admin/getBanner?city=${cityId}&page=${""}&per_page=${""}&isExcelDownload=${true}`,
        config
      );
      if (response?.data?.status == true) {
        let Ranking = response?.data?.data?.map((item, index) => ({
          "Sr.": index + 1,

          "Banner Image URL": item.banner[0],
          "From Date": item.fromDate,
          "To Date": item.toDate,
          Status: item.status,
        }));

        const dataArray = [Ranking];
        const namesArray = ["Banners"];
        const headingsArray = [null, null]; // Assuming no headers for the data

        downloadExcel1(dataArray, namesArray, headingsArray);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Banners"} links={[{ to: "/", name: "Home" }]} />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-4">
                  <h6>{"Banners"}</h6>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="cities">City:</label>
                    <Select
                      options={
                        cities &&
                        cities.length > 0 &&
                        cities.map((obj) => ({
                          label: obj.name,
                          value: obj._id,
                        }))
                      }
                      name="cities"
                      onChange={(value) => {
                        setCityId(value.value);
                        request(
                          "GET",
                          `admin/getBanner?city=${value.value}&page=${page}&per_page=${perPage}`
                        );
                      }}
                    />
                  </div>
                </div>
                {permission?.webAccessWrite && (
                  <div className="col-md-2 my-4">
                    <div className="form-group">
                      <Link to="/banner/add" className="btn btn-primary">
                        {" "}
                        Add New Banner
                      </Link>
                    </div>
                  </div>
                )}

                <div className="col-md-2">
                  <div className="d-flex">
                    <button
                      className="mx-3 btn btn-primary burron"
                      onClick={DownloadExcel}
                    >
                      Download Excel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>
                            {item.banner &&
                              item.banner.map((bannerImg) => {
                                return (
                                  <img
                                    src={bannerImg}
                                    alt={""}
                                    style={{
                                      height: "50px",
                                      maxWidth: "50px",
                                      borderRadius: "0%",
                                    }}
                                  />
                                );
                              })}
                          </td>
                          <td>{item.fromDate}</td>
                          <td>{item.toDate}</td>
                          <td>{item.amount}</td>
                          <td>{item?.totalAmount}</td>
                          <td>
                            {item.status == "active" ? (
                              <span className="badge badge-success">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : item.status == "inactive" ? (
                              <span className="badge badge-danger">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          {permission?.webAccessEdit && (
                            <td>
                              <Link
                                to="#"
                                onClick={() =>
                                  handleStatus(item.bannerId, item.status)
                                }
                              >
                                <i
                                  title="Status"
                                  className="flaticon-tick-inside-circle"
                                ></i>
                              </Link>
                              <Link
                                to={{
                                  pathname: `/banner/update/${item?.bannerId}`,
                                  state: {
                                    data: {
                                      ...item,
                                    },
                                  },
                                }}
                              >
                                <i
                                  title="View"
                                  className="flaticon-information"
                                ></i>
                              </Link>
                              <Link
                                to="#"
                                onClick={() => handleDelete(item.bannerId)}
                              >
                                <i
                                  title="Delete"
                                  className="far fa-trash-alt ms-text-danger"
                                />
                              </Link>
                            </td>
                          )}
                          {/* {onSerialInc()} */}
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No banner has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
