import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import "./App.css";

import useRequest from "./hooks/useRequest";
import { authSuccess, logout } from "./store/auth/action";

import { privateRoutes, notPrivateRoutes } from "./util/routes";
import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header/Header";
import Loading from "./components/Loading/Loading";
import ViewAll from "./components/Vendors/ViewAll";
import List from "./components/Vendors/Rewards/List";
import CommissionList from "./components/Vendors/CommissonControl/List";

const App = () => {
  const [token, setToken] = useState(null);

  const dispatch = useDispatch();
  const routePath = useLocation();
  const { loggedIn, loading, permissions } = useSelector((state) => state.auth);
  const { request, response } = useRequest();

  useEffect(() => {
    onTop();
  }, [routePath]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(authSuccess({ loggedIn: false }));
    } else {
      setToken(token);
    }
  }, []);

  useEffect(() => {
    // dispatch(logout());
    if (token) {
      request("POST", "admin/verify", { token });
    }
  }, [token]);
  useEffect(() => {
    if (response) {
      if (!response.status) {
        // dispatch(logout());
      } else {
        dispatch(
          authSuccess({
            loggedIn: true,
            token: response.data.accessToken,
            userId: response.data.userId,
            name: response.data.name,
            email: response.data.email,
            image: response.data.image,
            permissions: JSON.parse(localStorage.getItem("permissions") || []),
          })
        );
      }
    }
  }, [response]);

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      {/* {loading && <Loading />} */}
      {loggedIn !== null && (
        <>
          {loggedIn ? (
            <>
              <Sidebar />
              <main className="body-content">
                <Header />
                <Switch>
                  {privateRoutes.map((route, index) => (
                    <Route key={index} exact {...route} />
                  ))}
                  <Route path="/vendors?type=ranking" component={ViewAll} />
                  <Route path="/rewards" component={List} />
                  <Route path="/
                  " component={CommissionList} />
                  <Redirect to="/dashboard" />
                </Switch>
              </main>
            </>
          ) : (
            <Switch>
              {notPrivateRoutes.map((route, index) => (
                <Route key={index} exact {...route} />
              ))}
              <Redirect to="/login" />
            </Switch>
          )}
          <ToastContainer />
        </>
      )}
    </div>
  );
};

export default App;
