export const API = {
  PORT:
    process.env.NODE_ENV == "development" ? "https://api.sortyfy.com/api" : "",
};

export const BASEURL = {
  PORT:
    process.env.NODE_ENV == "development" ? "https://api.sortyfy.com/api" : "",
  // process.env.NODE_ENV == "development" ? "http://sortyfy.com:4000/api" : "",
};

export const upperCase = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1)
}