import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const Add = (props) => {
  const history = useHistory();

  const { response: responseUpdate, request: requestUpdate } = useRequest();
  const { response: responseStaffData, request: requestStaffData } =
    useRequest();
  const { request: requestRoles, response: responseRoles } = useRequest();
  const [touchedError, setTouchedError] = useState(false);
  const [roles, setMainRoles] = useState([]);
  const [staffId, setStaffId] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    control,
    clearErrors,
    watch,
  } = useForm();

  useEffect(() => {
    document.title = "Add Staff - Sportyfy";
    requestRoles("GET", "admin/role/getAllRoles");
  }, []);

  useEffect(() => {
    if (responseRoles) {
      if (responseRoles.status && responseRoles.data) {
        setMainRoles(responseRoles.data);
      }
    }
  }, [responseRoles]);

  const onSubmit = (data) => {
    const { name, email, mobile, roleId, password } = data;
    if (staffId) {
      requestUpdate("POST", `admin/staff/updateStaff`, {
        name,
        email,
        mobile,
        roleId,
        password,
        staffId,
      });
    } else {
      requestUpdate("POST", `admin/staff/addStaff`, {
        name,
        email,
        mobile,
        roleId,
        password,
      });
    }
  };

  useEffect(() => {
    if (responseUpdate) {
      if (responseUpdate.status) {
        if (staffId) {
          toast.success("Staff has been updated successfully.");
        } else {
          toast.success("Staff has been added successfully.");
        }
        history.push("/staffs");
      }
    }
  }, [responseUpdate]);

  useEffect(() => {
    if (responseStaffData) {
      if (responseStaffData.status && responseStaffData.data) {
        const tempData = responseStaffData?.data;
        setValue("name", tempData?.name);
        setValue("email", tempData?.email);
        setValue("mobile", tempData?.mobile);
        setValue("roleId", tempData?.roleId);
      }
    }
  }, [responseStaffData]);

  useEffect(() => {
    if (props?.location?.state) {
      setStaffId(props?.location?.state?.staffId);
      requestStaffData(
        "GET",
        `admin/staff/getStaffById?staffId=${props?.location?.state?.staffId}`
      );
    }
  }, []);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={staffId ? "Edit" : "Add"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/staffs", name: "Staffs" },
            ]}
          />
        </div>
        <div className="col-xl-12 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>{staffId ? "Edit Staff" : "Add Staff"}</h6>
            </div>
            <div className="ms-panel-body">
              <form
                className="needs-validation clearfix"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name">
                      Name<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="name"
                        placeholder="Name"
                        className={`form-control ${
                          touchedError
                            ? errors.name
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("name", {
                          required: true,
                        })}
                      />
                      {errors.name?.type === "required" && (
                        <div className="invalid-field">
                          The name field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name">
                      Email<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="email"
                        id="email"
                        placeholder="Email"
                        className={`form-control ${
                          touchedError
                            ? errors.email
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("email", {
                          required: true,
                        })}
                      />
                      {errors.email?.type === "required" && (
                        <div className="invalid-field">
                          The email field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name">
                      Mobile<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        id="mobile"
                        placeholder="Mobile"
                        className={`form-control ${
                          touchedError
                            ? errors.mobile
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("mobile", {
                          required: true,
                        })}
                      />
                      {errors.mobile?.type === "required" && (
                        <div className="invalid-field">
                          The mobile field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="roleId">
                      Role<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <select
                        {...register("roleId", {
                          required: true,
                        })}
                        className={`form-control ${
                          touchedError
                            ? errors.roleId
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        id="roles"
                      >
                        <option value={""}>Select</option>
                        {roles &&
                          roles.length > 0 &&
                          roles.map((obj) => (
                            <option key={obj._id} value={obj._id}>
                              {obj.name}
                            </option>
                          ))}
                      </select>

                      {errors.roleId?.type === "required" && (
                        <div className="invalid-field">
                          The role field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="password">
                      Password<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="password"
                        autocomplete="off"
                        id="password"
                        placeholder="Password"
                        className={`form-control ${
                          touchedError
                            ? errors.password
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("password", {
                          required: true,
                        })}
                      />
                      {errors.password?.type === "required" && (
                        <div className="invalid-field">
                          The password field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="confirmPassword">
                      Password<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="password"
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        className={`form-control ${
                          touchedError
                            ? errors.confirmPassword
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("confirmPassword", {
                          required: true,
                          validate: (val) => {
                            if (watch("password") != val) {
                              return "Your passwords do no match";
                            }
                          },
                        })}
                      />
                      {errors.confirmPassword?.message && (
                        <div className="invalid-field">
                          {errors.confirmPassword.message}
                        </div>
                      )}
                      {errors.confirmPassword?.type === "required" && (
                        <div className="invalid-field">
                          The confirm password field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    className="btn btn-primary mt-4 d-block w-50d"
                    onClick={() => setTouchedError(true)}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
