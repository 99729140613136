import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import useRequest from "../../../hooks/useRequest";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter, getPermissionDetails } from "../../../util/fn";
import Pagination from "../../Pagination/Pagination";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ViewAll = (props) => {
  const { reviewId } = props?.location?.state;
  const { request: request, response: response } = useRequest();
  const { request: requestAdd, response: responseAdd } = useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [data, setData] = useState([]);

  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Outlet" },
    { title: "User" },
    { title: "Date" },
    { title: "Rating" },
    { title: "Message" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Review History - Sportyfy";
    request("GET", `admin/feedback/getReviewHistory?reviewId=${reviewId}`);
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.userReview);
        setTotalDocuments(response.totalDocuments);
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request(
      "GET",
      `admin/feedback/getReviewHistory?reviewId=${reviewId}&page=${
        selected + 1
      }&per_page=${perPage}`
    );
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this reply?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        requestDelete("POST", "admin/feedback/removeReviewReply", {
          replyId: id,
        });
      }
    });
  };

  const handleReply = (id) => {
    Swal.fire({
      title: "Reply",
      text: "Enter reply",
      input: "text",
      inputPlaceholder: "",
      inputAttributes: {
        //   maxlength: 8,
        autocapitalize: "off",
        autocorrect: "off",
        required: "true",
      },
      showCancelButton: true,
      inputValidator: (value) => {
        if (value === "") {
          return "Please enter reply!";
        }
      },
      confirmButtonText: "Send",
      confirmButtonColor: "#3ea99f",
    }).then((result) => {
      if (result.value) {
        const message = result.value;
        requestAdd("POST", "admin/feedback/addUserReview", {
          reviewId,
          message,
          toUserId: id,
        });
      }
    });
  };

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status) {
        toast.success("Review has been deleted successfully.");
        if (responseDelete._id) {
          let newRecord = data;
          newRecord = newRecord.filter((r) => r._id != responseDelete._id);
          setData(newRecord);

          let newTotal = totalDocuments;
          setTotalDocuments(newTotal - 1);
          if (newRecord.length == 0) {
            setPage(page - 1);
            const city = document.getElementById("cities").value;
            request(
              "GET",
              `admin/feedback/getReviewHistory?reviewId=${reviewId}&page=${
                page - 1
              }&per_page=${perPage}`
            );
          }
        }
      }
    }
  }, [responseDelete]);

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Review History"}
            links={[
              { to: "/", name: "Home" },
              { to: "/reviews", name: "Reviews" },
            ]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-6">
                  <h6>{"Review History"}</h6>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>
                            {item.outlet
                              ? capitalizeFirstLetter(item?.outlet?.name)
                              : ""}
                          </td>
                          <td>
                            {item.user
                              ? capitalizeFirstLetter(item?.user?.name)
                              : ""}
                          </td>
                          <td>{item.booking ? item?.booking?.date : ""}</td>
                          <td>{item.rating}</td>
                          <td>{item.message}</td>

                          {/* {permission?.webAccessEdit && ( */}
                          <td>
                            <i
                              onClick={() => handleReply(item?.toUserId)}
                              title="View"
                              className="fas fa-pencil-alt text-secondary"
                            />
                            <Link
                              to="#"
                              onClick={() => handleDelete(item?._id)}
                            >
                              <i
                                title="Delete"
                                className="far fa-trash-alt ms-text-danger"
                              />
                            </Link>
                          </td>
                          {/* )} */}
                        </tr>
                      ))}
                    {data?.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No history has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
