import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import { Link } from "react-router-dom";
import {
  capitalizeFirstLetter,
  downloadExcel1,
  getPermissionDetails,
} from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BASEURL } from "../../constant/api";
import axios from "axios";
import { Button } from "react-bootstrap";

const ViewAll = (props) => {
  const { request: request, response: response } = useRequest();
  const { request: request1, response: response1 } = useRequest();
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cities, setCities] = useState([]);
  const [outletList, setOutletList] = useState([]);
  const [cityId, setCityId] = useState("");
  const [outletId, setOutletId] = useState("");

  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Outlet" },
    { title: "User" },
    { title: "Date" },
    { title: "Rating" },
    { title: "Message" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Reviews - Sportyfy";
    request(
      "GET",
      `admin/feedback/getAllReviews?city=${cityId}&startDate=${startDate}&endDate=${endDate}&outletId=${outletId}`
    );
    requestCities("GET", `admin/vendor/getCity`);
  }, [startDate, endDate, outletId]);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  useEffect(() => {
    if (response1) {
      if (response1.status) {
        setOutletList(response1.data);
      }
    }
  }, [response1]);
  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDocuments);
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    const city = document.getElementById("cities").value;
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request(
      "GET",
      `admin/feedback/getAllReviews?cityId=${city}&page=${
        selected + 1
      }&per_page=${perPage}&endDate=${endDate}&startDate=${startDate}`
    );
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this review?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        requestDelete("POST", "admin/feedback/removeReview", {
          reviewId: id,
        });
      }
    });
  };

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status) {
        toast.success("Review has been deleted successfully.");
        if (responseDelete._id) {
          let newRecord = data;
          newRecord = newRecord.filter((r) => r._id != responseDelete._id);
          setData(newRecord);

          let newTotal = totalDocuments;
          setTotalDocuments(newTotal - 1);
          if (newRecord.length == 0) {
            setPage(page - 1);
            const city = document.getElementById("cities").value;
            request(
              "GET",
              `admin/feedback/getAllReviews?cityId=${city}&page=${
                page - 1
              }&per_page=${perPage}&endDate=${endDate}&startDate=${startDate}`
            );
          }
        }
      }
    }
  }, [responseDelete]);

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);

  const DownloadExcel = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      const response = await axios.get(
        `${
          BASEURL.PORT
        }/admin/feedback/getAllReviews?isExcelDownload=${true}&cityId=${cityId}&outletId=${outletId}`,
        config
      );

      if (response?.data?.status == "success") {
        let Ranking = response?.data?.data?.map((item, index) => ({
          "Sr.": index + 1,
          Booking: item.booking?.date,
          Outlet: item.outlet?.name,
          Message: item.message,
          Rating: item.rating,
          User: item.user?.name,
          // 'status': item.status,
        }));

        const dataArray = [Ranking];
        const namesArray = ["Reviews"];
        const headingsArray = [null, null]; // Assuming no headers for the data

        downloadExcel1(dataArray, namesArray, headingsArray);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Reviews"} links={[{ to: "/", name: "Home" }]} />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-2">
                  <h6>{"Reviews"}</h6>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="cities">City:</label>
                    <Select
                      options={
                        cities &&
                        cities.length > 0 &&
                        cities.map((obj) => ({
                          label: obj.name,
                          value: obj._id,
                        }))
                      }
                      name="cities"
                      onChange={(value) => {
                        setCityId(value.value);
                        request(
                          "GET",
                          `admin/feedback/getAllReviews?cityId=${value.value}&endDate=${endDate}&startDate=${startDate}&page=${page}&per_page=${perPage}`
                        );
                        request1(
                          "GET",
                          `admin/vendor/outletByCity?cityId=${value.value}`
                        );
                      }}
                    />
                  </div>
                </div>
                {cityId && (
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="cities">Outlet:</label>
                      <select
                      className="form-control"

                        value={outletId}
                        onChange={(e) => {
                          setOutletId(e.target.value);
                        }}
                      >
                        <option value={""}>--Select outlet--</option>
                        {outletList &&
                          outletList?.map((val, i) => (
                            <option value={val._id}>{val.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">From Date:</label>
                    <input
                      type="date"
                      value={startDate}
                      className="form-control"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </div>
                </div>{" "}
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">End Date:</label>
                    <input
                      type="date"
                      value={endDate}
                      className="form-control"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                  </div>
                </div>{" "}
                <div className="col-md-2">
                  <label htmlFor="cities mt-1"></label>

                  <div className="form-group">
                    <Button className="btn btn-primary" onClick={DownloadExcel}>
                      {" "}
                      Download
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>
                            {item.outlet
                              ? capitalizeFirstLetter(item?.outlet?.name)
                              : ""}
                          </td>
                          <td>
                            {item.user
                              ? capitalizeFirstLetter(item?.user?.name)
                              : ""}
                          </td>
                          <td>{item.booking ? item?.booking?.date : ""}</td>
                          <td>{item.rating}</td>
                          <td>{item.message}</td>

                          {/* {permission?.webAccessEdit && ( */}
                          <td>
                            <Link
                              to={{
                                pathname: `reviews/reply/${item._id}`,
                                state: { reviewId: item._id },
                              }}
                            >
                              <i
                                title="View"
                                className="fas fa-eye text-secondary"
                              />
                            </Link>
                            <Link
                              to="#"
                              onClick={() => handleDelete(item?._id)}
                            >
                              <i
                                title="Delete"
                                className="far fa-trash-alt ms-text-danger"
                              />
                            </Link>
                          </td>
                          {/* )} */}
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No reviews has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
