import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import ImageSlider from "../ImageSlider/ImageSlider";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import moment from "moment";

const Add = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const { response: responseUpdate, request: requestUpdate } = useRequest();
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: requestVendors, response: responseVendors } = useRequest();
  const [touchedError, setTouchedError] = useState(false);
  const [isImageUpdate, setIsImageUpdate] = useState(true);
  const [displayImage, setDisplayImage] = useState([]);
  const [image, setImage] = useState([]);
  const [image2, setImage2] = useState();
  const [cities, setCities] = useState([{ name: "All", _id: "all" }]);
  const [city, setCity] = useState();
  const [cityId, setCityId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [vendors, setVendors] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    control,
    clearErrors,
  } = useForm();

  useEffect(() => {
    document.title = "Add Banner - Sportyfy";
    requestCities("GET", "admin/vendor/getCity");
    requestVendors(
      "GET",
      `admin/vendor/getAllVendor?cityId=${cityId}&name=&type=&page=1&per_page=10`
    );
  }, [cityId]);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities((prevCities) => {
          return [...prevCities, ...responseCities.data];
        });
      }
    }
  }, [responseCities]);

  useEffect(() => {
    if (responseVendors) {
      if (responseVendors.status && responseVendors.data) {
        setVendors(responseVendors.data);
      }
    }
  }, [responseVendors]);

  const onSubmit = (data) => {
    const { vendor, amount } = data;
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);

    const differenceInDays = (endDateTime - startDateTime) / (1000 * 3600 * 24);
    console.log("differenceInDays", differenceInDays);
    const formData = new FormData();
    if (differenceInDays < 0) {
      return toast.error("Please Select Correct Dates");
    } else if (amount < 0) {
      return toast.error("Enter Amount Greater Than 0");
    } else if (startDate || endDate) {
      formData.append("city", cityId);
      formData.append("amount", amount);
      formData.append("vendorId", vendor);
      formData.append("fromDate", startDate);
      formData.append("toDate", endDate);
      formData.append("imageUpdate", isImageUpdate);
      formData.append("totalAmount", (differenceInDays + 1) * amount);
      // if (startDate) {
      // }
      // if (endDate) {

      // }
      if (location.state) {
        formData.append("bannerId", location.state?.data?.bannerId);
      }
      for (let i = 0; i < city?.length; i++) {
        formData.append("city", city[i]?.value);
      }
      if (displayImage) {
        for (let i = 0; i < image2.length; i++) {
          formData.append("image", image2[i]);
        }
      } else {
        if (image == null || image == "undefined") {
          formData.append("oldImage", "");
        } else {
          formData.append("oldImage", image);
        }
      }

      requestUpdate("POST", "admin/addBanner", formData);
    } else {
      toast.error("Please add start date and end date");
    }
  };

  useEffect(() => {
    if (responseUpdate) {
      if (responseUpdate.status) {
        if (location.pathname.includes("update")) {
          toast.success("Banner has been updated successfully.");
        } else {
          toast.success("Banner has been added successfully.");
        }
        history.push("/banners");
      }
    }
  }, [responseUpdate]);

  useEffect(() => {
    if (location.state && location.pathname.includes("update")) {
      const { data } = location.state;
      const citiesVal = data?.city.map((item) => {
        return { label: item?.name, value: item?.id };
      });

      setCity(citiesVal);
      setIsImageUpdate(false);
      setValue("city", citiesVal);

      setValue(
        "endDate",
        moment(data?.toDate, "DD-MM-YYYY").format("YYYY-MM-DD")
      );

      setVendorId(data?.vendorId);
      setValue("vendor", data?.vendorId);
      setValue("amount", data?.amount);
      setValue(
        "startDate",
        moment(data?.fromDate, "DD-MM-YYYY").format("YYYY-MM-DD")
      );
      setValue("image", data?.banner);
      setDisplayImage(data?.banner);
      setImage2(data?.banner);
    }
  }, [location, props]);

  const handleImage = (event) => {
    event.preventDefault();
    let displayImages = [];
    let images = [];

    if (event.target.files.length > 0) {
      setIsImageUpdate(true);
      setImage2(event.target.files);
      for (let i = 0; i < event.target.files.length; i++) {
        displayImages.push(URL.createObjectURL(event.target.files[i]));
        images.push(event.target.files[i]);
      }
      setDisplayImage(displayImages);
      setError("image", "");
      setValue("image", images);
    } else {
      setImage2();
      setDisplayImage([]);
      setImage([]);
      setValue("image", "");
    }
  };

  const handleRemoveImage = () => {
    if (document.getElementById("image")) {
      document.getElementById("image").value = "";
    }
    setImage2();
    setDisplayImage([]);
    setValue("image", null);
    setImage([]);
  };
  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value);
    } else {
      setMinEndDate("");
    }
  };

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value);
    } else {
      setMaxStartDate("");
    }
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Add"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/banners", name: "Banners" },
            ]}
          />
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Add Banner</h6>
            </div>
            <div className="ms-panel-body">
              <form
                className="needs-validation clearfix"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="city">
                      City<span className="field-required">*</span>
                    </label>
                    <Select
                      options={
                        cities &&
                        cities.length > 0 &&
                        cities.map((obj) => ({
                          label: obj.name,
                          value: obj._id,
                        }))
                      }
                      name="cities"
                      value={city}
                      onChange={(value) => {
                        setCity(value);

                        setCityId(value?.value);
                        setValue(value.value);
                        // setValue(
                        //   "city",
                        //   value.map((item) => item.value)
                        // );
                      }}
                      isMulti={false}
                      required={true}
                    />
                    <div className="input-group">
                      {/* <select
                        {...register("city", {
                          required: true,
                        })}
                        onChange={(e) => console.log(e.target.value)}
                        className={`form-control ${
                          touchedError
                            ? errors.city
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        id="cities"
                        multiple
                      >
                        {cities &&
                          cities.length > 0 &&
                          cities.map((obj) => (
                            <option key={obj._id} value={obj._id}>
                              {obj.name}
                            </option>
                          ))}
                      </select> */}

                      {errors.city?.type === "required" && (
                        <div className="invalid-field">
                          The city field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="vendor">Vendor</label>
                    <div className="input-group">
                      <select
                        {...register("vendor", {
                          required: false,
                        })}
                        className={`form-control ${
                          touchedError
                            ? errors?.vendor
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        id="vendor"
                        onChange={(e) => {
                          setValue("vendor", e.target.value);
                          setVendorId(e.target.value);
                        }}
                        value={vendorId}
                      >
                        <option value={""}>Select</option>
                        {vendors &&
                          vendors.length > 0 &&
                          vendors.map((obj) => (
                            <option key={obj.id} value={obj.id}>
                              {obj.name}
                            </option>
                          ))}
                      </select>

                      {errors?.vendor?.type === "required" && (
                        <div className="invalid-field">
                          The vendor field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="amount">Per Day Amount</label>
                    <div className="input-group">
                      <input
                        type="number"
                        id="amount"
                        placeholder="Amount"
                        className={`form-control ${
                          touchedError
                            ? errors.amount
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("amount", {
                          required: false,
                        })}
                      />
                      {errors.amount?.type === "required" && (
                        <div className="invalid-field">
                          The amount field is required.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="startDate">
                      {/* date picker input */}
                      <label htmlFor="validationCustom01" class="form-label">
                        Start Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="startDate"
                        name="startDate"
                        placeholder="Start Date"
                        max={maxStartDate}
                        onChange={handleStartDate}
                        {...register("startDate", {
                          required: true,
                        })}
                      />
                      {errors.startDate?.type === "required" && (
                        <div className="invalid-field">
                          The Start Date field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="endDate">
                      <label htmlFor="validationCustom01" class="form-label">
                        End Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="endDate"
                        name="endDate"
                        placeholder="End Date"
                        min={minEndDate}
                        onChange={handleEndDate}
                        {...register("endDate", {
                          required: true,
                        })}
                        // onChange={filterData}
                      />
                    </div>
                    {errors.endDate?.type === "required" && (
                      <div className="invalid-field">
                        The End Date field is required.
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="image">
                      {" "}
                      Image<span className="field-required">*</span>
                    </label>
                    <div className="custom-file">
                      <Controller
                        control={control}
                        name="image"
                        {...register("image", {
                          required: true,
                        })}
                        render={({ field: { onChange, value, ref } }) => (
                          <input
                            type="file"
                            id="image"
                            onChange={handleImage}
                            style={{ display: "none" }}
                            inputRef={ref}
                            accept="image/*"
                            multiple
                            className="custom-file-input"
                          />
                        )}
                      />
                      <label className="custom-file-label" htmlFor="image">
                        Images
                      </label>
                      {errors.image?.type === "required" && (
                        <div className="invalid-field">
                          The image field is required.
                        </div>
                      )}
                    </div>
                  </div>

                  {id ? (
                    ""
                  ) : (
                    <button
                      className="btn btn-primary mt-4 d-block w-100"
                      onClick={() => {
                        setTouchedError(true);
                      }}
                      type="submit"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {(displayImage.length > 0 || image.length > 0) && (
          <div className="col-xl-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Image </h6>
                  </div>
                  <div className="ms-panel-body">
                    <ImageSlider displayImage={displayImage} image={image} />
                  </div>
                  {id ? (
                    ""
                  ) : (
                    <div className="ms-panel-header ">
                      <label
                        className="btn btn-primary mx-2"
                        onClick={handleRemoveImage}
                      >
                        Remove
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Add;
