import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import Select from "react-select";

import { Link } from "react-router-dom";
import {
  capitalizeFirstLetter,
  downloadExcel1,
  getPermissionDetails,
} from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { BASEURL } from "../../constant/api";

const ViewAll = (props) => {
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: request, response: response } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Name" },
    { title: "Email" },
    { title: "Phone" },
    { title: "Device" },
    { title: "Reward Point" },
    { title: "Status" },
    { title: "Registration Date" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startIndex, setStartIndex] = useState(1);

  const [cities, setCities] = useState([]);
  const [deviceType, setDeviceType] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cityId, setCityId] = useState("");

  useEffect(() => {
    document.title = "Users - Sportyfy";
    request(
      "GET",
      `admin/user/getAllUser?status=${status}&city=${cityId}&page=${page}&per_page=${perPage}&startDate=${startDate}&endDate=${endDate}&deviceType=${deviceType}`
    );
  }, [cityId, startDate, endDate, deviceType, status]);

  const handleSearch = (e) => {
    e.preventDefault();
    let searchString = search;

    if (e.target.name == "search") {
      setSearch(e.target.value);
      searchString = e.target.value;
    }
    searchString = searchString ?? "";
    request(
      "GET",
      `admin/user/getAllUser?status=${status}&searchString=${searchString}&city=${cityId}&page=${page}&per_page=${perPage}&startDate=${startDate}&endDate=${endDate}&deviceType=${deviceType}`
    );
  };

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDocuments);
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request(
      "GET",
      `admin/user/getAllUser?status=${status}&searchString=${search}&page=${
        selected + 1
      }&city=${cityId}&per_page=${perPage}&startDate=${startDate}&endDate=${endDate}&deviceType=${deviceType}`
    );
  };

  const handleStatus = (id, status) => {
    if (status == false) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to unblock this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Unblock!",
      }).then(function (result) {
        if (result.value) {
          requestStatus("POST", "admin/user/blockUser", {
            userId: id,
            status: false,
          });
        }
      });
    } else if (status == true) {
      Swal.fire({
        title: "Are you sure you want to block user?",
        text: "Enter reason to block",
        input: "text",
        inputPlaceholder: "Enter text here",
        inputAttributes: {
          //   maxlength: 8,
          autocapitalize: "off",
          autocorrect: "off",
          required: "true",
        },
        showCancelButton: true,
        inputValidator: (value) => {
          if (value === "") {
            return "Please enter reason!";
          }
        },
        confirmButtonText: "Yes, Block",
        confirmButtonColor: "#3ea99f",
      }).then((result) => {
        if (result.value) {
          const blockReason = result.value;
          requestStatus("POST", "admin/user/blockUser", {
            userId: id,
            status: "blocked",
            blockReason: blockReason,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.status) {
        const { id, updatedStatus } = responseStatus;
        toast.success("User status has been updated successfully.");

        const oldRecords = data;
        const indexToChange = oldRecords.findIndex((r) => r._id == id);
        oldRecords[indexToChange].status = updatedStatus;

        setData(oldRecords);
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);

  const DownloadExcel = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      const response = await axios.get(
        `${
          BASEURL.PORT
        }/admin/user/getAllUser?status=${status}&city=${cityId}&page=${""}&per_page=${""}&startDate=${startDate}&endDate=${endDate}&deviceType=${deviceType}&isExcelDownload=${true}`,
        config
      );
      if (response?.data?.status == true) {
        let Ranking = response?.data?.data?.map((item, index) => ({
          "Sr.": index + 1,
          Device: item?.device,
          Email: item?.email,
          Name: item.name,
          "Reward Point": item.rewardPoint,
          Phone: item.phone,
        }));

        const dataArray = [Ranking];
        const namesArray = ["Users"];
        const headingsArray = [null, null]; // Assuming no headers for the data

        downloadExcel1(dataArray, namesArray, headingsArray);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    requestCities("GET", "admin/vendor/getCity");
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Users"} links={[{ to: "/", name: "Home" }]} />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-1">
                  <h6>{"Users"}</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      name="search"
                      placeholder="Search"
                      onChange={handleSearch}
                    />
                  </div>
                </div>{" "}
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="cities">City:</label>
                    <Select
                      options={
                        cities &&
                        cities.length > 0 &&
                        cities.map((obj) => ({
                          label: obj.name,
                          value: obj._id,
                        }))
                      }
                      name="cities"
                      onChange={(value) => {
                        setCityId(value.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="cities">Device:</label>
                    <select
                      name="device"
                      className="form-control"
                      onChange={(e) => {
                        setDeviceType(e.target.value);
                      }}
                      value={deviceType}
                    >
                      <option value={""}>--Select Device Type--</option>
                      <option value={"android"}>Android</option>
                      <option value={"ios"}>Ios</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="cities">Status:</label>
                    <select
                      name="device"
                      className="form-control"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      value={status}
                    >
                      <option value={""}>--Select Status--</option>
                      <option value={"online"}>Active</option>
                      <option value={"blocked"}>Blocked</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">From Date:</label>
                    <input
                      type="date"
                      value={startDate}
                      className="form-control"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </div>
                </div>{" "}
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">End Date:</label>
                    <input
                      type="date"
                      value={endDate}
                      className="form-control"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                  </div>
                </div>{" "}
                <div className="col-md-2">
                  <div className="d-flex">
                    <button
                      className="mx-3 btn btn-primary burron"
                      onClick={DownloadExcel}
                    >
                      Download Excel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>
                            <Link
                              to={{
                                pathname: `/users/orders/${item._id}`,
                                state: {
                                  userId: item._id,
                                },
                              }}
                            >
                              {item.name
                                ? capitalizeFirstLetter(item.name)
                                : ""}
                            </Link>
                          </td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                          <td>
                            {item.device
                              ? capitalizeFirstLetter(item.device)
                              : ""}
                          </td>
                          <td>{item.rewardPoint}</td>
                          <td>
                            {item.status == "blocked" ? "Blocked" : "Unblocked"}
                          </td>
                          <td>
                            {item.regDate}
                          </td>
                          <td>
                            {permission?.webAccessEdit &&
                            item.status == "blocked" ? (
                              <>
                                <Link
                                  to="#"
                                  onClick={() => handleStatus(item._id, false)}
                                >
                                  <i
                                    title="Unblock"
                                    className="flaticon-tick-inside-circle"
                                  ></i>
                                </Link>
                                <Link
                                  to={`/orderList/${item._id}/${item.name}`}
                                >
                                  <i
                                    title="View"
                                    className="flaticon-information"
                                  ></i>
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link
                                  to="#"
                                  onClick={() => handleStatus(item._id, true)}
                                >
                                  <i
                                    title="Block"
                                    className="fa fa-ban ms-text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link
                                  to={`/orderList/${item._id}/${item.name}`}
                                >
                                  <i
                                    title="View"
                                    className="flaticon-information"
                                  ></i>
                                </Link>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No users has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
