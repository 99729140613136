import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Swal from "sweetalert2";
import { FaEdit } from "react-icons/fa";
import { BASEURL, upperCase } from "../../constant/api";
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { toast } from "react-toastify";
import axios from "axios";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "400px",
};
const center = {
  lat: 26.916804657627374,
  lng: 75.77984904488004,
};
const AllOutlet = (props) => {
  const params = useLocation();
  let page = params?.search?.split("?page=")[1];

  const { id: vendorId } = props.match.params;
  const history = useHistory();
  const { request: requestStatusUpdate, response: responseStatusUpdate } =
    useRequest();
  const { request: request, response: response } = useRequest();
  const { request: requestAddressUpdate, response: responseUpdateAddress } =
    useRequest();

  const [outletList, setOutletList] = useState([]);
  const [showCommisionModal, setShowCommisionModal] = useState(false);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [showNameModal, setModalActive] = useState(false);
  const [modalData, setData] = useState(false);
  const [outletName, setName] = useState("");
  const [sliderValue, setSliderValue] = useState(0);
  const [rewardStatus, setRewardStatus] = useState();
  const { request: UpdateNameAPI, response: responseNameAPI } = useRequest();

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [settingsAddressDetails, setAddressDetails] = React.useState("");
  const [countries, setCountries] = React.useState([]);

  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(center);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDaQcyEyDvVeB8GG3NOzqtXB1i8ukYqcgs",
    libraries,
  });
  const [cityOption, setCityOptions] = useState([]);
  const [states, setStates] = useState([]);
  const [countryID, setCountryId] = useState("");
  const [cities, setCities] = useState([]);
  const [cityID, setCityId] = useState("");
  const [outletId, setOutletId] = useState("");
  const [stateId, setStateID] = useState("");
  const { request: requestCountry, response: responseCountry } = useRequest();

  const { response: responseState, request: requestState } = useRequest();
  const { response: responseCity, request: requestCity } = useRequest();

  const [latLong, setLatLong] = React.useState({
    latitude: "",
    longitude: "",
  });
  useEffect(() => {
    request("GET", `admin/vendor/getOutlets?vendorId=${vendorId}`);
    setModalActive(false);
  }, [vendorId, responseStatusUpdate, responseNameAPI,showCommisionModal]);

  useEffect(() => {
    if (response) {
      if (response.status && response.data) {
        setOutletList(response.data);
      }
    }
  }, [response]);

  const Savecommission = () => {
    const data = {
      outletId: outletId,
      commission: sliderValue,
    };
    request("POST", `admin/vendor/updateCommissionPercent`, data);
    setOutletId("")
    setShowCommisionModal(!showCommisionModal);

  };
  const handleShowCommisionModal = (commision) => {
    setSliderValue(commision);
    setShowCommisionModal(!showCommisionModal);
  };

  const handleShowRewardModal = (rewardStatus) => {
    setRewardStatus(rewardStatus);
    setShowRewardModal(!showRewardModal);
  };

  const renderRewardModal = () => {
    return (
      <Modal
        show={setShowRewardModal}
        onHide={handleShowRewardModal}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reward point partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          5% reward point will be offered as reward loyalty program
          <br />
          <br />
          <b>Status:</b> {rewardStatus}
        </Modal.Body>
      </Modal>
    );
  };

  const renderCommisionModal = (outletId) => {
    return (
      <Modal
        show={setShowCommisionModal}
        onHide={handleShowCommisionModal}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Commision</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginBottom: "25px" }}>
            <Col md={6}>
              <label htmlFor="foodLicence" className="form-label customLabel">
                Commision
              </label>
              <Slider
                orientation="horizontal"
                min={0}
                max={100}
                value={sliderValue}
                onChange={(val) => setSliderValue(val)}
              />
              {sliderValue}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="savebtn" onClick={Savecommission}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleShowCommisionModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ActiveDeactive = async (id, status) => {
    let newStatus = status == "active" ? "inActive" : "active";
    Swal.fire({
      title: "Are you sure?",
      text: "You want to " + newStatus?.toLocaleLowerCase() + " this outlet",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.value) {
        requestStatusUpdate("POST", "admin/vendor/updateOutletStatus", {
          outletId: id,
          status: newStatus,
        });
      }
    });
  };

  useEffect(() => {
    if (vendorId) {
      requestCountry("GET", "admin/getCountry");
    }
  }, [vendorId]);

  useEffect(() => {
    if (stateId) {
      requestCity("POST", "admin/getCitys", {
        stateId: stateId,
      });
    }
  }, [stateId]);

  useEffect(() => {
    if (countryID) {
      requestState("POST", "admin/getState", {
        countryId: countryID,
      });
    }
  }, [countryID]);

  useEffect(() => {
    if (responseState) {
      if (responseState?.status == "success" || responseState?.status == true) {
        setStates(responseState?.data?.length ? responseState?.data : []);
      }
    }
  }, [responseState]);

  useEffect(() => {
    if (responseCountry) {
      if (
        responseCountry?.status == "success" ||
        responseCountry?.status == true
      ) {
        setCountries(
          responseCountry?.data?.length ? responseCountry?.data : []
        );
      }
    }
  }, [responseCountry]);
  useEffect(() => {
    if (responseCity) {
      if (responseCity?.status == "success" || responseCity?.status == true) {
        setCities(responseCity?.data?.length ? responseCity?.data : []);
      }
    }
  }, [responseCity]);

  useEffect(() => {
    if (responseUpdateAddress) {
      if (
        responseUpdateAddress?.status == "success" ||
        responseUpdateAddress?.status == true
      ) {
        setCountryId("");
        setStateID("");
        setCityId("");
        setInitialValues("");
        setModalActive(false);
      }
    }
  }, [responseUpdateAddress]);
  const [initialValues, setInitialValues] = React.useState({
    country: settingsAddressDetails?.country || "",
    state: settingsAddressDetails?.state || "",
    city: settingsAddressDetails?.city || "",
    address: settingsAddressDetails?.address || "",
    street: settingsAddressDetails?.street,
    landMark: settingsAddressDetails?.landMark || "",
    pincode: settingsAddressDetails?.pincode || "",
  });

  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    address: Yup.string().required("Address is required"),
    landMark: Yup.string().required("LandMark is required"),
    // street: Yup.string().required("Street is required"),
    pincode: Yup.string().required("Postal Code is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let data = { ...values };
      delete data.city;
      delete data.address;
      data["city"] = cityID;
      data["address"] = address;
      // Update API

      UpdateNameAPI("POST", "admin/vendor/updateOutletName", {
        name: outletName,
        outletId: modalData?.outletId,
      });
      requestAddressUpdate("POST", "admin/vendor/updateVendorAddress", {
        ...data,
        ...latLong,
        vendorId: vendorId,
        outletId: outletId,
      });
    },
  });
  const onHandleChange = (e) => {
    formik.handleChange(e);
    if (e.target.name == "country") {
      setCountryId(e.target.value);
    } else if (e.target.name === "state") {
      setStateID(e.target.value);
    }
  };
  useEffect(() => {
    const cityOptions = cities?.map((city) => ({
      value: city?._id,
      label: city?.name,
    }));
    setCityOptions(cityOptions);
  }, [cities]);

  const getAddress = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const settingsAddressDetails = await axios.get(
      `${BASEURL.PORT}/admin/vendor/getVendorAddress?vendorId=${vendorId}`,
      config
    );

    setAddress(settingsAddressDetails?.data?.data?.address);
    setLatLong({
      latitude: settingsAddressDetails?.data?.data?.latitude,
      longitude: settingsAddressDetails?.data?.data?.longitude,
    });
    setMarkerPosition({
      lat: Number(settingsAddressDetails?.data?.data?.latitude),
      lng: Number(settingsAddressDetails?.data?.data?.longitude),
    });
    setInitialValues({
      country: settingsAddressDetails?.data?.data?.country || "",
      state: settingsAddressDetails?.data?.data?.state || "",
      city: settingsAddressDetails?.data?.data?.city || "",
      address: settingsAddressDetails?.data?.data?.address || "",
      street: settingsAddressDetails?.data?.data?.street || "",
      landMark: settingsAddressDetails?.data?.data?.landMark || "",
      pincode: settingsAddressDetails?.data?.data?.pincode || "",
    });
    setAddress(settingsAddressDetails?.data?.data?.address);
    setCountryId(settingsAddressDetails?.data?.data?.country);
    setCityId(settingsAddressDetails?.data?.data?.city);
    setStateID(settingsAddressDetails?.data?.data?.state);
  };

  useEffect(() => {
    if (showNameModal && vendorId) {
      getAddress();
    }
  }, [showNameModal]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setMarkerPosition(latLng);
    setLatLong({
      latitude: latLng.lat,
      longitude: latLng.lng,
    });
    setAddress(value);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  const geocodeLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          // formik.setFieldValue(
          //   "pincode",
          //   results[0].formatted_address?.split(",")[2]?.split(" ")[1]
          // );
          setAddress(results[0].formatted_address);
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  };

  const UpdateName = () => {
    return (
      <Modal
        show={showNameModal}
        onHide={() => setModalActive(false)}
        style={{ height: "100%", width: "100%", overflow: "hidden" }}
        scrollable
        size="xl"
        fullscreen={true}
      >
        <Modal.Header>
          <Modal.Title>Update Outlet Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {/* <Col md={12}>
              <Button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  UpdateNameAPI("POST", "admin/vendor/updateOutletName", {
                    name: outletName,
                    outletId: modalData?.outletId,
                  });
                }}
              >
                Update
              </Button>
            </Col> */}
          </Row>
          <div className="">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div className="ms-panel-header">
                    <h6>Address</h6>
                  </div>
                  <div className="ms-panel-body">
                    <form className="needs-validation clearfix" noValidate>
                      <div className="form-row">
                        <Col md={6}>
                          <label htmlFor="validationCustom03">
                            Name<span className="error">*</span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={outletName}
                              name="vendorName"
                              onChange={(e) => {
                                e.preventDefault();
                                setName(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="country">
                            Country<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <select
                              className="form-control"
                              name="country"
                              value={formik.values.country}
                              onChange={onHandleChange}
                              style={{
                                borderColor:
                                  formik.errors.country &&
                                  formik.touched.country
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            >
                              <option disabled>Select Country</option>
                              {countries.length > 0 ? (
                                countries.map((country) => {
                                  return (
                                    <option value={country._id}>
                                      {country.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value={0}>No Country Found</option>
                              )}
                            </select>
                            {formik?.errors?.country && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="state">
                            State<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <select
                              className="form-control capitalize"
                              name="state"
                              aria-readonly={true}
                              onChange={onHandleChange}
                              value={formik.values.state}
                              style={{
                                borderColor:
                                  formik.errors.state && formik.touched.state
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            >
                              <option>Select State</option>
                              {states.length > 0 ? (
                                states.map((state) => {
                                  return (
                                    <option value={state._id}>
                                      {state.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value={0}>No State Found</option>
                              )}
                            </select>
                            {formik?.errors?.state && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom03">
                            City<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <Select
                              id="city"
                              name="city"
                              className={`border-blue w-100 `}
                              options={cityOption}
                              onChange={({ value }) => {
                                setCityId(value);
                              }}
                              value={cityOption?.filter(
                                ({ value }) => value == cityID
                              )}
                              placeholder="Select City"
                            />

                            {!cityID && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom03">
                            House NO./ Flat No.
                            <span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name="street"
                              onChange={formik.handleChange}
                              value={formik.values.street}
                              id="validationCustom03"
                              placeholder="street"
                              style={{
                                borderColor:
                                  formik.errors.street && formik.touched.street
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            />
                            {formik?.errors?.street && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3 ">
                          <label htmlFor="validationCustom04">
                            Address<span className="error">*</span>
                          </label>

                          <div className="mb-3">
                            <PlacesAutocomplete
                              value={address}
                              onChange={setAddress}
                              onSelect={handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Search Places ...",
                                      className: "form-control",
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </div>

                        <div className="col-md-12 mb-3 ">
                          <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={10}
                            center={markerPosition}
                            onClick={(event) => {
                              geocodeLatLng(
                                event.latLng.lat(),
                                event.latLng.lng()
                              );
                              setMarkerPosition({
                                lat: event.latLng.lat(),
                                lng: event.latLng.lng(),
                              });
                              setLatLong({
                                latitude: event.latLng.lat(),
                                longitude: event.latLng.lng(),
                              });
                            }}
                          >
                            <Marker position={markerPosition} />
                          </GoogleMap>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom04">
                            Area <span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name="landMark"
                              onChange={formik.handleChange}
                              value={formik.values.landMark}
                              id="validationCustom04"
                              placeholder="Land Mark"
                              style={{
                                borderColor:
                                  formik.errors.landMark &&
                                  formik.touched.landMark
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            />
                            {formik?.errors?.landMark && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom04">
                            Postal code<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name="pincode"
                              onChange={formik.handleChange}
                              value={formik.values.pincode}
                              id="validationCustom04"
                              placeholder="Postal code"
                              style={{
                                borderColor:
                                  formik.errors.pincode &&
                                  formik.touched.pincode
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            />
                            {formik?.errors?.pincode && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>

                        {latLong.latitude && latLong.longitude && (
                          <>
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom03">
                                Latitude
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="latitude"
                                  onChange={formik.handleChange}
                                  value={latLong.latitude}
                                  id="validationCustom03"
                                  placeholder="Latitude"
                                  disabled
                                />
                                {/* {formik?.errors?.street && (
                                        <div style={{"display":"block"}} className="invalid-feedback">
                                          This field is required.
                                        </div>
                                      )} */}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom04">
                                Longitude
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="longitude"
                                  onChange={formik.handleChange}
                                  value={latLong.longitude}
                                  id="validationCustom04"
                                  placeholder="Longitude"
                                  disabled
                                />
                                {/* {formik?.errors?.pincode && (
                                      <div style={{"display":"block"}} className="invalid-feedback">
                                        This field is required.
                                      </div>
                                    )} */}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="ms-panel-header new">
                    {/* <button className="btn btn-secondary d-block" type="submit">
                                                          Save
                                                      </button> */}
                    <button
                      className="btn btn-primary d-block"
                      type="submit"
                      onClick={formik.handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb
              title={"Outlets"}
              links={[
                { to: "/dashboard", name: "Home" },
                { to: `/vendors?page=${page}`, name: "Vendors" },
              ]}
            />
            <div className="row">
              {outletList &&
                outletList.length > 0 &&
                outletList.map((item, i) => (
                  <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div className="ms-card">
                      <div className="ms-card-img">
                        {item && item.image && (
                          <img
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                            }}
                            src={item.image}
                            alt="card_img"
                          />
                        )}
                      </div>
                      <div className="ms-card-body">
                        <div className="new">
                          <h6 className="mb-0">
                            {item.outletName}{" "}
                            <span
                              className="mx-3 cursor-pointer"
                              onClick={() => {
                                setModalActive(true);
                                setData(item);
                                setName(item?.outletName);
                                setOutletId(item?.outletId);
                              }}
                            >
                              <FaEdit />
                            </span>{" "}
                          </h6>
                          <h6 className="ms-text-primary mb-0">{i + 1}</h6>
                        </div>
                        <div classNamtotalCuisinee="new meta">
                          {/* <p>Qty:{item.qyt} </p> */}
                          {/* <span className={item.statuscls}>{item.status}</span> */}
                        </div>
                        <p>{item.description}</p>
                        <div className="new mb-0 mt-3">
                          <Link
                            // to={`/vendor/menus/${item.outletId}`}
                            to={{
                              pathname: `/vendor/view-outlet/${item.outletId}`,
                              state: { vendorId: vendorId },
                            }}
                            className="btn grid-btn mt-0 btn-sm steps-btn"
                          >
                            View
                          </Link>

                          <Link
                            className={
                              item?.menuActive
                                ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                                : "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set "
                            }
                            // to={`/vendor/menus/${item.outletId}`}
                            to={{
                              pathname: `/vendor/edit/${item.outletId}`,
                              state: { vendorId: vendorId },
                            }}
                          >
                            Edit
                          </Link>
                        </div>

                        <div className="new mt-4">
                          <Link
                            to={{
                              pathname: `/vendor/orders/${item.outletId}`,
                              state: { vendorId: vendorId },
                            }}
                            className="btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                          >
                            Orders
                          </Link>
                          <button
                            type="button"
                            className="btn grid-btn mt-0 btn-sm steps-btn"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            onClick={() => {
                              handleShowCommisionModal(item.commissionPoint);
                              setOutletId(item?.outletId);
                            }}
                          >
                            Commision
                          </button>
                        </div>
                        <div className="new mt-4">
                          <button
                            type="button"
                            className={
                              item?.rewardActive
                                ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                                : "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set red-color-btn"
                            }
                            onClick={() => handleShowRewardModal(item.isReward)}
                          >
                            Reward point
                          </button>
                          <Link
                            to={{
                              pathname: `/vendor/discounts/${item.outletId}`,
                              state: { vendorId: vendorId },
                            }}
                            className={
                              item?.discountActive
                                ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                                : "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set red-color-btn"
                            }
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          >
                            Discount
                          </Link>

                          {showRewardModal && renderRewardModal()}
                          {showCommisionModal &&
                            renderCommisionModal(item.outletId)}
                        </div>
                        <div className="new mt-4">
                          <Link
                            to={{
                              pathname: `/vendor/promotions/${item.outletId}`,
                              state: { vendorId: vendorId },
                            }}
                            className="btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                          >
                            Promotion
                          </Link>

                          <Link
                            to={{
                              pathname: `/vendor/reports/${item.outletId}`,
                              state: { vendorId: vendorId },
                            }}
                            className="btn grid-btn mt-0 btn-sm steps-btn"
                          >
                            Report
                          </Link>
                        </div>
                        <div className="new mt-4">
                          <Link
                            to={{
                              pathname: `/vendor/trends/${item.outletId}`,
                              state: { vendorId: vendorId },
                            }}
                            className="btn grid-btn mt-0 btn-sm steps-btn"
                          >
                            Trends
                          </Link>

                          <Link
                            to={{
                              pathname: `/vendor/finance-report/${item.outletId}`,
                              state: { vendorId: vendorId },
                            }}
                            className="btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                          >
                            Finance Report
                          </Link>
                        </div>
                        <div className="new mt-4">
                          <button
                            className="btn grid-btn mt-0 btn-sm steps-btn"
                            onClick={() =>
                              ActiveDeactive(item?.outletId, item?.status)
                            }
                          >
                            {" "}
                            {item?.status == "inActive"
                              ? "Active"
                              : "Inactive"}{" "}
                          </button>
                          <Link
                            className={
                              item?.menuActive
                                ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                                : "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set "
                            }
                            // to={`/vendor/menus/${item.outletId}`}
                            to={{
                              pathname: `/vendor/menus/${item.outletId}`,
                              state: { vendorId: vendorId },
                            }}
                          >
                            Menu
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {showNameModal && UpdateName()}
    </>
  );
};

export default AllOutlet;
