import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import useRequest from "../../../hooks/useRequest";
import { downloadExcel, stringLimit } from "../../../util/fn";
import Pagination from "../../Pagination/Pagination";
import { Col, Row } from "react-bootstrap";

const ViewAll = (props) => {
  const { outlets, vendorType, startDate, endDate } = props?.location?.state;
  const [outletId, setOutletId] = useState(
    outlets?.length ? outlets[0]?._id : ""
  );
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: request, response: response } = useRequest();
  const [reportData, setReportData] = useState({
    totalBooking: 0,
    totalCommission: 0,
    totalPerson: 0,
    totalRevenue: 0,
  });
  const [startDateVal, setStartDateVal] = useState(startDate);
  const [endDateVal, setEndDateVal] = useState(endDate);
  const [minEndDate, setMinEndDate] = useState(startDate);
  const [maxStartDate, setMaxStartDate] = useState(endDate);
  const [cities, setCities] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Name" },
    { title: "Total Guest" },
    { title: "Total Billing" },
    { title: "Sortyfy Commission" },
    { title: "Outlet Pay Balance" },
    { title: "Payment Status" },
  ]);
  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  const [search, setSearch] = useState();

  useEffect(() => {
    document.title = "Finance Report - Sportyfy";

    requestCities("GET", "admin/vendor/getCity");
    request("POST", `admin/report/financeReportOutlet`, {
      perPage,
      page,
      searchString: "",
      outletId,
      startDate: startDate,
      endDate: endDate,
    });
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  const outletHandler = (e) => {
    e.preventDefault();
    if (e.target.value) {
      const startDate = document.getElementById("startDate").value;
      const endDate = document.getElementById("endDate").value;
      request("POST", `admin/report/financeReportOutlet`, {
        perPage,
        page,
        outletId: e.target.value,
        searchString: "",
        startDate,
        endDate,
      });
      setOutletId(e.target.value);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    if (response) {
      if (response.status && response.data) {
        // if(response.data && response.data.length>0){
        setData(response.data);
        setReportData({
          totalBooking: response?.totalBooking || 0,
          totalCommission: response?.totalCommission || 0,
          totalPerson: response?.totalPerson || 0,
          totalRevenue: response?.totalRevenue || 0,
        });
        setTotalDocuments(response.totalDocument);
        // }
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request("POST", `admin/report/financeReportOutlet`, {
      perPage,
      page: selected + 1,
      searchString: search,
      outletId,
    });
  };
  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value);
      setStartDateVal(e.target.value);
    } else {
      setMinEndDate("");
    }
  };

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value);
      setEndDateVal(e.target.value);
    } else {
      setMaxStartDate("");
    }
  };
  const downloadExcelHandler = () => {
    let xlData = [];
    if (data && data.length > 0) {
      let count = 1;
      data.forEach((obj) => {
        xlData.push({
          "Sr.": count,
          Name: obj?.name,
          "Total Guest": obj?.totalGuest,
          "Total Billing": obj?.totalBilling,
          "Sortyfy Commission": obj?.sortyfyCommission,
          "Outlet Pay Balance": obj?.outletPaybal,
          "Payment Status": obj?.paymentStatus,
        });
        count = count + 1;
      });
    } else {
      xlData.push({
        "Sr.": "",
        Name: "",
        "Total Guest": "",
        "Total Billing": "",
        "Sortyfy Commission": "",
        "Outlet Pay Balance": "",
        "Payment Status": "",
      });
    }
    downloadExcel(xlData, "Finance Reports");
  };
  const filterHandler = () => {
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    // const status = document.getElementById('status').value;
    // const city = document.getElementById("cities").value;
    request("POST", `admin/report/financeReportOutlet`, {
      perPage,
      page,

      searchString: search,
      startDate,
      endDate,
      outletId,
    });
    // dispatch(
    //   getBusinessReports({
    //     startDate,
    //     endDate,
    //     status,
    //     outletId: selectedOutletId,
    //   })
    // );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let searchString = search;
    const city = document.getElementById("cities").value;
    if (e.target.name == "search") {
      setSearch(e.target.value);
      searchString = e.target.value;
    }
    searchString = searchString ?? "";
    request("POST", `admin/report/financeReportOutlet`, {
      perPage,
      page,
      searchString: searchString,
      outletId,
    });
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Finance Report Outlet"}
            links={[
              { to: "/", name: "Home" },
              { to: "/finance-report", name: "Report" },
            ]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-6">
                  <h6>{"Finance Report History"}</h6>
                </div>
                <div className="col-md-4">
                  {vendorType === "multiple" && (
                    <div className="form-group">
                      <label htmlFor="outlet">Outlet:</label>
                      <select
                        className="form-control"
                        id="outlet"
                        onChange={outletHandler}
                        value={outletId}
                      >
                        <option value={""}>Select</option>
                        {outlets &&
                          outlets.length > 0 &&
                          outlets.map((obj) => (
                            <option key={obj._id} value={obj._id}>
                              {obj.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      name="search"
                      placeholder="Search by Name"
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="date-filter">
                <Row>
                  <Col md={4}>
                    <div className="startDate">
                      {/* date picker input */}
                      <label htmlFor="validationCustom01" class="form-label">
                        Start Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="startDate"
                        name="startDate"
                        value={startDateVal}
                        placeholder="Start Date"
                        max={maxStartDate}
                        onChange={handleStartDate}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="endDate">
                      <label htmlFor="validationCustom01" class="form-label">
                        End Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="endDate"
                        name="endDate"
                        placeholder="End Date"
                        value={endDateVal}
                        min={minEndDate}
                        onChange={handleEndDate}
                        // onChange={filterData}
                      />
                    </div>
                  </Col>
                  {/* <Col md={2}>
                                        <div className="endDate">
                                            <label htmlFor="validationCustom01" class="form-label">
                                                Status
                                            </label>
                                            <select
                                                className="form-control"
                                                id="status"
                                                name="status"
                                            // onChange={filterData}
                                            >
                                                <option value={""}>Please select</option>
                                                <option value={"accepted"}>Accepted</option>
                                                <option value={"pending"}>Pending</option>
                                                <option value={"decline"}>Decline</option>
                                            </select>
                                        </div>
                                    </Col> */}
                  <Col md={2}>
                    <button
                      type="button"
                      onClick={filterHandler}
                      className="excle-dowm"
                    >
                      {" "}
                      Filter{" "}
                    </button>
                  </Col>
                  <Col md={2}>
                    {/* <DownloadTableExcel
                        filename="Business Reports"
                        sheet="Business Reports"
                        currentTableRef={tableRef.current}
                      >
                        <button
                          className="excle-dowm"
                          disabled={!(currentItems?.length > 0)}
                        >
                          {" "}
                          Export excel{" "}
                        </button>
                      </DownloadTableExcel> */}
                    <button
                      type="button"
                      onClick={downloadExcelHandler}
                      className="excle-dowm"
                    >
                      {" "}
                      Export excel{" "}
                    </button>
                  </Col>
                </Row>
              </div>
              <div className="dh-section mt-4">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                      <div className="ms-card-body media">
                        <div className="media-body">
                          <span className="black-text">
                            <strong>Total Booking</strong>
                          </span>
                          <h2>{reportData?.totalBooking}</h2>
                        </div>
                      </div>
                      {/* <LineChart data={this.state.data1} options={options} /> */}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                      {/* <span className="ms-chart-label bg-red">
                            <i className="material-icons">arrow_downward</i>{" "}
                            4.5%
                          </span> */}
                      <div className="ms-card-body media">
                        <div className="media-body">
                          <span className="black-text">
                            <strong>Total Person</strong>
                          </span>
                          <h2>{reportData?.totalPerson}</h2>
                        </div>
                      </div>
                      {/* <LineChart data={this.state.data2} options={options} /> */}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                      <div className="ms-card-body media">
                        <div className="media-body">
                          <span className="black-text">
                            <strong>Total Revenue</strong>
                          </span>
                          <h2>{reportData?.totalRevenue}</h2>
                        </div>
                      </div>
                      {/* <LineChart data={this.state.data3} options={options} /> */}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                      <div className="ms-card-body media">
                        <div className="media-body">
                          <span className="black-text">
                            <strong>Total Commission</strong>
                          </span>
                          <h2>{reportData?.totalCommission}</h2>
                        </div>
                      </div>
                      {/* <LineChart data={this.state.data3} options={options} /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>{item.userName}</td>
                          <td>{item.totalGuest}</td>
                          <td>{item.totalBilling}</td>
                          <td>{item.sortyfyCommission}</td>
                          <td>{item.outletPaybal}</td>
                          <td>{item.paymentStatus}</td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No finance report has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length != 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
