



import React, { useEffect, useState } from 'react'
import Select from "react-select";

import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { downloadExcel1, getPermissionDetails, stringLimit } from '../../util/fn';
import useRequest from '../../hooks/useRequest';
import axios from 'axios';
import { BASEURL } from '../../constant/api';
import { toast } from 'react-toastify';
import BarChart from './BarChart';
import LineGraph from './LineGraph';

function Discount() {
  const { request: requestCities, response: responseCities } = useRequest();
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState('');
  const [Dates, setDates] = useState([]);
  const [Dates1, setDates1] = useState([]);
  const [area, setArea] = useState('');
  const [seriesData, setSeriesData] = useState([{ name: "Commission Area Wise", data: [''] }]);
  const [seriesData1, setSeriesData1] = useState([{ name: "Commission City Wise", data: [''] }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { request: request, response: response } = useRequest();
  const [permissions, setPermissions] = useState([])
  const [data, setData] = useState('')
  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);

  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    requestCities("GET", "admin/vendor/getCity");
  }, []);
  useEffect(() => {
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 15);
    setStartDate(todayDate.toISOString().split('T')[0]);

    todayDate.setDate(todayDate.getDate() + 15);
    setEndDate(todayDate.toISOString().split('T')[0]);
  }, [])
  useEffect(() => {

    GetData()
  }, [startDate, endDate]);
  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);

      }
    }
  }, [responseCities]);

  const DownloadExcel = async () => {

    const data = {
      city: city,
      area: area,
      startDate: startDate,
      endDate: endDate
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("token")
      }
    };

    try {
      const response = await axios.post(`${BASEURL.PORT}/admin/report/discountReport`, data, config);
      if (response?.data?.status == true) {
        let Ranking = response?.data?.data?.discountAreaWise?.map((obj, index) => ({
          'Sr.': index + 1,
          'outlet': obj?.outlet,
          'discount': obj?.discount,
    
        }));
        let CityWise = response?.data?.data?.discountCityWise?.map((obj, index) => ({
          'Sr.': index + 1,
          'outlet': obj?.outlet,
          'discount': obj?.discount,
    
        }));
    
    
        const dataArray = [Ranking, CityWise];
        const namesArray = ["Discount Area Wise Report", 'Discount City Wise Report'];
        const headingsArray = [null, null]; // Assuming no headers for the data
    
        downloadExcel1(dataArray, namesArray, headingsArray);
      }

    } catch (error) {
      toast.error(error.message)
    }

  }

  const GetData = async () => {
    const data = {
      city: city,
      area: area,
      startDate: startDate,
      endDate: endDate
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("token")
      }
    };

    try {
      const response = await axios.post(`${BASEURL.PORT}/admin/report/discountReport`, data, config);
      if (response?.data?.status == true) {
        setData(response?.data?.data)
        setDates(response?.data?.data?.discountAreaWise?.map((val) => (
          val.outlet
        )))
        setDates1(response?.data?.data?.discountCityWise?.map((val) => (
          val.outlet
        )))

        const dataa = response?.data?.data?.discountAreaWise?.map((val) => (
          parseInt(val.discount)
        ))

        const dataa1 = response?.data?.data?.discountCityWise?.map((val) => (
          parseInt(val.discount)
        ))
        setSeriesData(
          { name: "Discount Area Wise", data: [...dataa] }
        );
        setSeriesData1(
          { name: "Discount City Wise", data: [...dataa1] }
        )
      }

    } catch (error) {
      toast.error(error.message)
    }
  }


  return (
    <div>

      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb
              title={"Discount Report"}
              links={[{ to: "/", name: "Home" }]}
            />
            <div className="ms-panel">
            <div className="ms-panel-header">
                <div className="row">
                  <div className="col-md-3">
                    <h6>{"Discount Report"}</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="cities">From Date:</label>
                      <input type='date'
                        value={startDate}
                        className='form-control'

                        onChange={(e) => {
                          // Calculate end date as 15 days from the start date
                          const endDate = new Date(e.target.value);
                          endDate.setDate(endDate.getDate() + 15);
                          setEndDate(endDate.toISOString().split('T')[0]);
                          setStartDate(e.target.value)
                        }
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-2">

                    <div className="form-group">
                      <label htmlFor="cities">To Date:</label>
                      <input type='date'
                        className='form-control'
                        value={endDate}
                        disabled
                        onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="cities">Area:</label>
                      <input type='text'
                        className='form-control'
                        value={area}

                        onChange={(e) => setArea(e.target.value)} />
                    </div>
                  </div>

                  <div className="col-md-2">

                    <div className="form-group">
                      <label htmlFor="cities">City:</label>
                      <Select
                        options={
                          cities &&
                          cities.length > 0 &&
                          cities.map((obj) => ({
                            label: obj.name,
                            value: obj._id,
                          }))
                        }
                        name="cities"
                        onChange={(value) => {
                          setCity(value.value)
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className='d-flex'>
                      <button className="mx-3 btn btn-primary burron" onClick={DownloadExcel}>Download Excel</button>
                      <button className="mx-3 btn btn-primary burron" onClick={GetData}>Filter</button>
                    </div>

                  </div>
                </div>



              </div>

              <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-6'>
                  <div className='mt-4'><h5>Discount Status - Area Wise</h5></div>
                </div>
              </div>
              <div className="row">
                <div className='col-md-6'>
                  <div className="ms-panel-body">
                    <div className="table-responsive">
                      <table className="table table-hover thead-primary">
                        <thead>
                          <tr>

                            <th scope="col">OutletLet</th>
                            <th scope="col">Discount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            data?.discountAreaWise?.length > 0 &&
                            data?.discountAreaWise?.map((item, i) => (
                              <tr key={item._id}>

                                <td>{item.outlet}</td>
                                <td>{item.discount}</td>

                              </tr>
                            ))}
                          {data.length == 0 && (
                            <tr>
                              <td colspan="10" style={{ textAlign: "center" }}>
                                {" "}
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <LineGraph title='Discount Area Wise' series={seriesData} categories={Dates} />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-6'>
                  <div><h5>Discount Status - City Wise</h5></div>
                </div>
              </div>
              <div className="row">
                <div className='col-md-6'>
                  <div className="ms-panel-body">
                    <div className="table-responsive">
                      <table className="table table-hover thead-primary">
                        <thead>
                          <tr>

                            <th scope="col">OutletLet</th>
                            <th scope="col">Discount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data?.discountCityWise.length > 0 &&
                            data?.discountCityWise?.map((item, i) => (
                              <tr key={item._id}>

                                <td>{item.outlet}</td>
                                <td>{item.discount}</td>

                              </tr>
                            ))}
                          {data.length == 0 && (
                            <tr>
                              <td colspan="10" style={{ textAlign: "center" }}>
                                {" "}
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <BarChart title='Discount City Wise' series={seriesData1} categories={Dates1} />

                </div>
              </div>



              {/* <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-6'>
                  <div><h5>Average Discount Status - Location Wise</h5></div>
                </div>
              </div>
              <div className="row">
                <div className='col-md-6'>
                  <div className="ms-panel-body">
                    <div className="table-responsive">
                      <table className="table table-hover thead-primary">
                        <thead>
                          <tr>

                            <th scope="col">OutletLet</th>
                            <th scope="col">Discount %</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.length > 0 &&
                            data.map((item, i) => (
                              <tr key={item._id}>

                                <td>{item.outlet}</td>
                                <td>{item.discount}</td>

                              </tr>
                            ))}
                          {data.length == 0 && (
                            <tr>
                              <td colspan="10" style={{ textAlign: "center" }}>
                                {" "}
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  Graph
                </div>
              </div>

              <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-6'>
                  <div><h5>Average Discount Status - City Wise</h5></div>
                </div>
              </div>
              <div className="row">
                <div className='col-md-6'>
                  <div className="ms-panel-body">
                    <div className="table-responsive">
                      <table className="table table-hover thead-primary">
                        <thead>
                          <tr>

                            <th scope="col">OutletLet</th>
                            <th scope="col">Discount %</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.length > 0 &&
                            data.map((item, i) => (
                              <tr key={item._id}>

                                <td>{item.date}</td>
                                <td>{item.registration}</td>

                              </tr>
                            ))}
                          {data.length == 0 && (
                            <tr>
                              <td colspan="10" style={{ textAlign: "center" }}>
                                {" "}
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  Graph
                </div>
              </div> */}


            </div>

          </div>
          {/* <div className="col-md-12">
            {perPage !== 0 &&
              data.length != totalDocuments &&
              data.length != 0 && (
                <Pagination
                  page={page}
                  totalDocuments={totalDocuments}
                  getNewData={fetchMoreData}
                  perPage={perPage}
                  currentDocLength={data.length}
                />
              )}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Discount