import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import useRequest from "../../../hooks/useRequest";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../util/fn";
import Pagination from "../../Pagination/Pagination";

const ViewAll = (props) => {
  const { userId } = props?.location?.state;
  const { request: request, response: response } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Outlet" },
    { title: "Booking Type" },
    { title: "Image" },
    { title: "City" },
    { title: "Feedback" },
    { title: "Address" },
    { title: "Country" },
    { title: "Date" },
    { title: "Status" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Users order history - Sportyfy";
    request("GET", `admin/user/orderHistory?userId=${userId}`);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    let searchString = search;

    if (e.target.name == "search") {
      setSearch(e.target.value);
      searchString = e.target.value;
    }
    searchString = searchString ?? "";
    request(
      "GET",
      `admin/user/orderHistory?userId=${userId}&page=${page}&per_page=${perPage}`
    );
  };

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDocuments);
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request(
      "GET",
      `admin/user/orderHistory?userId=${userId}&page=${
        selected + 1
      }&per_page=${perPage}`
    );
  };

  const handleStatus = (id, status) => {
    if (status == false) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to unblock this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Unblock!",
      }).then(function (result) {
        if (result.value) {
          requestStatus("POST", "admin/user/blockUser", {
            userId: id,
            status: false,
          });
        }
      });
    } else if (status == true) {
      Swal.fire({
        title: "Are you sure you want to block user?",
        text: "Enter reason to block",
        input: "text",
        inputPlaceholder: "Enter text here",
        inputAttributes: {
          //   maxlength: 8,
          autocapitalize: "off",
          autocorrect: "off",
          required: "true",
        },
        showCancelButton: true,
        inputValidator: (value) => {
          if (value === "") {
            return "Please enter reason!";
          }
        },
        confirmButtonText: "Yes, Block",
        confirmButtonColor: "#3ea99f",
      }).then((result) => {
        if (result.value) {
          const blockReason = result.value;
          requestStatus("POST", "admin/user/blockUser", {
            userId: id,
            status: "blocked",
            blockReason: blockReason,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.status) {
        const { id, updatedStatus } = responseStatus;
        toast.success("User status has been updated successfully.");

        const oldRecords = data;
        const indexToChange = oldRecords.findIndex((r) => r._id == id);
        oldRecords[indexToChange].status = updatedStatus;

        setData(oldRecords);
      }
    }
  }, [responseStatus]);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Orders"}
            links={[
              { to: "/", name: "Home" },
              { to: "/users", name: "Users" },
            ]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-9">
                  <h6>{"Users Orders History"}</h6>
                </div>
                {/* <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      name="search"
                      placeholder="Search by Name, Email or Phone"
                      onChange={handleSearch}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>
                            {item.outletName
                              ? capitalizeFirstLetter(item.outletName)
                              : ""}
                          </td>
                          <td>{item.bookingType}</td>
                          <td>
                            <img src={item.coverImage}></img>
                          </td>
                          <td>
                            {item.city ? capitalizeFirstLetter(item.city) : ""}
                          </td>
                          <td>{item.isFeedback ? "True" : "False"}</td>
                          <td>{item.address}</td>
                          <td>{item.country}</td>
                          <td>{item.dateTime}</td>
                          <td>{item.status}</td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="10" style={{ textAlign: "center" }}>
                          {" "}
                          No orders has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
