import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { authSuccess, setAllPermissions } from "../../store/auth/action";
import useRequest from "../../hooks/useRequest";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();

  const { response, request } = useRequest();
  const [rememberMe, setRememberMe] = useState(0);
  const [rememberMeEmail, setRememberMeEmail] = useState("");
  const [rememberMePassword, setRememberMePassword] = useState("");
  const [touchedError, setTouchedError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    document.title = "Login - Sportyfy";
    setValue("email", localStorage.getItem("isRememberEmail"));
    setValue("password", localStorage.getItem("isRememberPassword"));
    if (localStorage.getItem("isRememberMe")) {
      setRememberMe(localStorage.getItem("isRememberMe"));
    }
  }, []);

  const onSubmit = (data) => {
    const { email, password } = data;

    if (rememberMe) {
      setRememberMeEmail(email);
      setRememberMePassword(password);
    }
    request("POST", "admin/login", { email, password });
  };

  useEffect(() => {
    if (response) {
      if (response.status) {
        toast.success(response.message);

        let result = document.getElementById("rememberCheck").checked;
        if (result) {
          localStorage.setItem("isRememberEmail", rememberMeEmail);
          localStorage.setItem("isRememberPassword", rememberMePassword);
          localStorage.setItem("isRememberMe", 1);
        } else {
          localStorage.setItem("isRememberEmail", "");
          localStorage.setItem("isRememberPassword", "");
          localStorage.setItem("isRememberMe", 0);
        }

        if (response.data) {
          dispatch(
            setAllPermissions(response.data?.permission?.permission || [])
          );
          localStorage.setItem(
            "permissions",
            JSON.stringify(response.data?.permission?.permission || [])
          );
          dispatch(
            authSuccess({
              token: response.data.accessToken,
              userId: response.data.userId,
              name: response.data.name,
              email: response.data.email,
              image: response.data.image,
            })
          );
        }
      }
    }
  }, [response]);
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <form
              className="needs-validation"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <h3>Login to Account</h3>
              <p>Please enter your email and password to continue</p>
              <div className="mb-3">
                <label htmlFor="email">
                  Email Address<span className="field-required">*</span>
                </label>
                {/* className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 ${
                    errors.email && "is-invalid"
                  }`} */}
                <div className="input-group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    className={`form-control ${
                      touchedError
                        ? errors.email
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <div className="invalid-field">
                      The email field is required.
                    </div>
                  )}
                  {errors.email?.type === "pattern" && (
                    <div className="invalid-field">
                      The email must be a valid email address.
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="password">
                  Password<span className="field-required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Password"
                    className={`form-control ${
                      touchedError
                        ? errors.password
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    {...register("password", {
                      required: true,
                      // pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                    })}
                  />

                  <Link
                    to="#"
                    onClick={() => setIsPasswordVisible((prev) => !prev)}
                  >
                    {isPasswordVisible ? (
                      <i class="fas fa-eye"></i>
                    ) : (
                      <i class="fas fa-eye-slash"></i>
                    )}
                  </Link>
                  {errors.password?.type === "required" && (
                    <div className="invalid-field">
                      The password field is required.
                    </div>
                  )}
                  {/* {errors.password?.type === "pattern" && (
                                    <div className="invalid-field">
                                        Password must be of 8 characters long with atleast one
                                        uppercase, one lowercase and one number.
                                    </div>
                                )} */}
                </div>
              </div>
              <div className="form-group">
                <label className="ms-checkbox-wrap">
                  <input
                    className="form-check-input"
                    id="rememberCheck"
                    checked={rememberMe == 0 ? "" : "checked"}
                    onChange={() => setRememberMe((prev) => !prev)}
                    type="checkbox"
                    defaultValue
                  />{" "}
                  <i className="ms-checkbox-check" />
                </label>{" "}
                <span> Remember Password </span>
                <label className="d-block mt-3">
                  <Link to="/forgot-password" className="btn-link">
                    Forgot Password?
                  </Link>
                </label>
              </div>
              <button
                className="btn btn-primary mt-4 d-block w-100"
                type="submit"
                onClick={() => setTouchedError(true)}
              >
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        className="modal-min"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <button type="button" className="close">
            <span aria-hidden="true">×</span>
          </button>
          <i className="flaticon-secure-shield d-block" />
          <h1>Forgot Password?</h1>
          <p>Enter your email to recover your password</p>
          <form method="post">
            <div className="ms-form-group has-icon">
              <input
                type="text"
                placeholder="Email Address"
                className="form-control"
                name="forgot-password"
              />{" "}
              <i className="material-icons">email</i>
            </div>
            <button type="submit" className="btn btn-primary shadow-none">
              Reset Password
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
