import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import { downloadExcel, stringLimit } from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";

const ViewAll = () => {
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: request, response: response } = useRequest();

  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [cities, setCities] = useState([]);
  const [curCity, setCurCity] = useState("");
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Name" },
    { title: "Net Amount" },
    { title: "Rest Amount" },
    { title: "Bookings" },
    { title: "Paid To Vendor" },
    { title: "View History" },
  ]);
  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  const [search, setSearch] = useState();

  useEffect(() => {
    document.title = "Finance Report - Sportyfy";

    requestCities("GET", "admin/vendor/getCity");
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  const cityHandler = (e) => {
    e.preventDefault();
    if (e.target.value) {
      request("POST", `admin/report/financeReportSortyfy`, {
        perPage,
        page,
        cityId: e.target.value,
        searchString: "",
      });
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    if (response) {
      if (response.status) {
        // if(response.data && response.data.length>0){
        setData(response.data);
        setTotalDocuments(response.totalDocument);
        // }
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }

    request("POST", `admin/report/financeReportSortyfy`, {
      perPage,
      page: selected + 1,
      cityId: curCity,
      searchString: search,
    });
  };
  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value);
    } else {
      setMinEndDate("");
    }
  };

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value);
    } else {
      setMaxStartDate("");
    }
  };
  const downloadExcelHandler = () => {
    let xlData = [];
    if (data && data.length > 0) {
      let count = 1;
      data.forEach((obj) => {
        xlData.push({
          "Sr.": count,
          Name: obj?.name,
          "Net Amount": obj?.netAmount,
          "Rest Amount": obj?.restAmount,
          Bookings: obj?.bookingCount,
          "Paid to Vendor": obj?.paidToVendor,
        });
        count = count + 1;
      });
    } else {
      xlData.push({
        "Sr.": "",
        Name: "",
        "Net Amount": "",
        "Rest Amount": "",
        Bookings: "",
        "Paid to Vendor": "",
      });
    }
    downloadExcel(xlData, "Finance Reports");
  };
  const filterHandler = () => {
    const startDate = document.getElementById("startDate")?.value || "";
    const endDate = document.getElementById("endDate")?.value || "";
    // const status = document.getElementById('status').value;

    request("POST", `admin/report/financeReportSortyfy`, {
      perPage,
      page,
      cityId: curCity,
      searchString: search,
      startDate,
      endDate,
    });
    // dispatch(
    //   getBusinessReports({
    //     startDate,
    //     endDate,
    //     status,
    //     outletId: selectedOutletId,
    //   })
    // );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let searchString = search;

    if (e.target.name == "search") {
      setSearch(e.target.value);
      searchString = e.target.value;
    }
    searchString = searchString ?? "";
    request("POST", `admin/report/financeReportSortyfy`, {
      perPage,
      page,
      cityId: curCity,
      searchString: searchString,
    });
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Finance Report"}
            links={[{ to: "/", name: "Home" }]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-6">
                  <h6>{"Finance Report"}</h6>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="cities">City:</label>
                    <Select
                      options={
                        cities &&
                        cities.length > 0 &&
                        cities.map((obj) => ({
                          label: obj.name,
                          value: obj._id,
                        }))
                      }
                      name="cities"
                      onChange={(value) => {
                        request("POST", `admin/report/financeReportSortyfy`, {
                          perPage,
                          page,
                          cityId: value.value,
                          searchString: "",
                        });
                        setCurCity(value.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      name="search"
                      placeholder="Search by Name"
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="date-filter">
                <Row>
                  <Col md={4}>
                    <div className="startDate">
                      {/* date picker input */}
                      <label htmlFor="validationCustom01" class="form-label">
                        Start Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="startDate"
                        name="startDate"
                        placeholder="Start Date"
                        max={maxStartDate}
                        onChange={handleStartDate}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="endDate">
                      <label htmlFor="validationCustom01" class="form-label">
                        End Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="endDate"
                        name="endDate"
                        placeholder="End Date"
                        min={minEndDate}
                        onChange={handleEndDate}
                        // onChange={filterData}
                      />
                    </div>
                  </Col>
                  {/* <Col md={2}>
                    <div className="endDate">
                      <label htmlFor="validationCustom01" class="form-label">
                        Status
                      </label>
                      <select
                        className="form-control"
                        id="status"
                        name="status"
                      // onChange={filterData}
                      >
                        <option value={""}>Please select</option>
                        <option value={"accepted"}>Accepted</option>
                        <option value={"pending"}>Pending</option>
                        <option value={"decline"}>Decline</option>
                      </select>
                    </div>
                  </Col> */}
                  <Col md={2}>
                    <button
                      type="button"
                      onClick={filterHandler}
                      className="excle-dowm"
                    >
                      {" "}
                      Filter{" "}
                    </button>
                  </Col>
                  <Col md={2}>
                    {/* <DownloadTableExcel
                        filename="Business Reports"
                        sheet="Business Reports"
                        currentTableRef={tableRef.current}
                      >
                        <button
                          className="excle-dowm"
                          disabled={!(currentItems?.length > 0)}
                        >
                          {" "}
                          Export excel{" "}
                        </button>
                      </DownloadTableExcel> */}
                    <button
                      type="button"
                      onClick={downloadExcelHandler}
                      className="excle-dowm"
                    >
                      {" "}
                      Export excel{" "}
                    </button>
                  </Col>
                </Row>
              </div>
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>{item.name}</td>
                          <td>{item.netAmount}</td>
                          <td>{item.restAmount}</td>
                          <td>{item.bookingCount}</td>
                          <td>{item.paidToVendor}</td>
                          <td>
                            {" "}
                            <Link
                              to={{
                                pathname: `/finance-report/outlet/${item._id}`,
                                state: {
                                  outletId: item._id,
                                  outlets: item?.outlets || [],
                                  vendorType: item?.vendorType || "",
                                  startDate: minEndDate || "",
                                  endDate: maxStartDate || "",
                                },
                              }}
                            >
                              <i
                                title="View"
                                className="flaticon-information"
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No finance report has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length != 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
