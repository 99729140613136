import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { capitalizeFirstLetter, downloadExcel1, getPermissionDetails } from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import Select from "react-select";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { BASEURL } from "../../constant/api";
import axios from "axios";

const ViewAll = (props) => {
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: request, response: response } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [cities, setCities] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Label" },
    { title: "Role Name" },
    { title: "Master Role" },
    { title: "Created" },
    { title: "Status" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);

  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Banners - Sportyfy";
    requestCities("GET", "admin/vendor/getCity");
    request("GET", `admin/role/getAllRoles`);
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDocument);
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }

    const city = document.getElementById("cities").value;
    request(
      "GET",
      `admin/getBanner?city=${city}&page=${selected + 1}&per_page=${perPage}`
    );
  };

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);


  const DownloadExcel = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("token")
      }
    };

    try {
      const response = await axios.get(`${BASEURL.PORT}/admin/role/getAllRoles?isExcelDownload=${true}`, config);
      if (response?.data?.status == true) {
        let Ranking = response?.data?.data?.map((item, index) => ({
          'Sr.': index + 1,

          'isMaster': item.isMaster,
          'label': item.label,
          'name': item.name,
          'status': item.status,


        }));



        const dataArray = [Ranking];
        const namesArray = ["Venodrs"];
        const headingsArray = [null, null]; // Assuming no headers for the data

        downloadExcel1(dataArray, namesArray, headingsArray);
      }

    } catch (error) {
      toast.error(error.message)
    }

  }
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Roles"} links={[{ to: "/", name: "Home" }]} />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-4">
                  <h6>{"Roles"}</h6>
                </div>

                {permission?.webAccessWrite && (
                  <div className="col-md-4 justify-content-end d-flex">
                    <div className="form-group">
                      <Link to="/role/add" className="btn btn-primary">
                        {" "}
                        Add Role
                      </Link>
                    </div>
                  </div>
                )}
                <div className="col-md-2">
                  <div className="form-group">
                    <Button className="btn btn-primary" onClick={DownloadExcel}>
                      {" "}
                      Download
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>{item.label}</td>
                          <td>{item.name}</td>
                          <td>{item.isMaster}</td>
                          <td>{item.createdAt}</td>
                          <td>
                            {item.status == "active" ? (
                              <span className="badge badge-success">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : item.status == "inactive" ? (
                              <span className="badge badge-danger">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          {permission?.webAccessEdit && (
                            <td>
                              <Link
                                to={{
                                  pathname: `/role/edit:id${item._id}`,
                                  state: { roleId: item._id },
                                }}
                                className=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              >
                                <i
                                  title="Status"
                                  className="fas fa-pencil-alt text-secondary"
                                ></i>
                              </Link>
                            </td>
                          )}
                          {/* {onSerialInc()} */}
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No roles has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
