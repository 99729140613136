import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// import {
//   getOrderDetails
// } from "../../../redux/slices/Orders";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FaInfoCircle, FaMapMarkedAlt } from "react-icons/fa";

import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
// import { downloadExcel, getNewOrderFromSocket } from "../../../util/fn";

import { useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { upperCase } from "../../constant/api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import AudioFile from "../../../assets/audio/audio.mp3";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props?.cancelReason}
  </Tooltip>
);
const BookingList = (props) => {
  const { hashKey } = useParams();

  const { request: requestAllOrders, response: responseAllOrders } =
    useRequest();
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: requestOutlet, response: responseOutlet } = useRequest();

  // const { orders } = useSelector((state) => state.orders);
  const [cities, setCityList] = useState([]);
  const [cityId, setCityId] = useState("");
  const [outletId, setOutletId] = useState("");
  const history = useHistory();
  const [outletList, setOutletList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  //Pagination
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState([]);
  const tableRef = useRef(null);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrnetPage] = useState(1);
  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: localStorage.getItem("outletId"),
    startDate: "",
    endDate: "",
  });
  const [selectedOutletId, setSelectedOutletId] = useState(
    localStorage.getItem("outletId")
  );
  const [minEndDate, setMinEndDate] = useState();
  const [bookingText, setBookingText] = useState("");
  const [userText, setUserText] = useState("");
  const [maxStartDate, setMaxStartDate] = useState();
  const [outletName, setOutletName] = useState(null);

  const [allOrders, setAllOrders] = useState([]);
  const [orders, setOrders] = useState();
  const [statusId, setStatus] = useState("");

  useEffect(() => {
    requestCities("GET", "admin/vendor/getCity");
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCityList(responseCities.data);
      }
    }
  }, [responseCities]);

  useEffect(() => {
    if (responseOutlet) {
      if (responseOutlet.status) {
        setOutletList(responseOutlet?.data);
      }
    }
  }, [responseOutlet]);

  useEffect(() => {
    if (cityId) {
      requestOutlet(
        "GET",
        `admin/vendor/getAllOutlet?cityId=${cityId ? cityId : "cityId"}`
      );
    }
  }, [cityId]);

  useEffect(() => {
    requestAllOrders(
      "GET",
      `admin/order/orderHistory?outletId=${outletId}&per_page=10&page=${currentPage}${
        cityId ? `&city=${cityId}` : ""
      }${maxStartDate ? `&startDate=${maxStartDate}` : ""}${
        minEndDate ? `&endDate=${minEndDate}` : ""
      }${statusId ? `&status=${statusId}` : ""}${
        userText ? `&userName=${userText}` : ""
      }${bookingText ? `&bookingNumber=${bookingText}` : ""}`
    );
  }, [cityId, outletId, statusId, currentPage]);

  useEffect(() => {
    if (responseAllOrders) {
      setOrders(responseAllOrders);

      // if (responseAllOrders?.data?.length) {
      setAllOrders(responseAllOrders?.data);

      setPageCount(Math.ceil(responseAllOrders?.totalOrder / 10));
    } else {
      setCurrentItems([]);
      setPageCount(0);
    }
    // }
  }, [responseAllOrders, itemOffset, itemsPerPage]);

  const handlePageClick = ({ selected }) => {
    setCurrnetPage(selected + 1);
  };

  const filterHandler = () => {
    requestAllOrders(
      "GET",
      `admin/order/orderHistory?outletId=${outletId}&per_page=10&page=${currentPage}${
        cityId ? `&city=${cityId}` : ""
      }${maxStartDate ? `&startDate=${maxStartDate}` : ""}${
        minEndDate ? `&endDate=${minEndDate}` : ""
      }${statusId ? `&status=${statusId}` : ""}${
        userText ? `&userName=${userText}` : ""
      }${bookingText ? `&bookingNumber=${bookingText}` : ""}`
    );
  };

  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value);
    } else {
      setMinEndDate("");
    }
  };

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value);
    } else {
      setMaxStartDate("");
    }
  };
  const handleBooking = (e) => {
    setBookingText(e.target.value);
  };

  const handleUser = (e) => {
    setUserText(e.target.value);
  };
  const status = [
    { name: "Cancel", _id: "cancel" },
    { name: "Accepted", _id: "accepted" },
    { name: "Pending", _id: "pending" },
  ];

  return (
    <div className="col-12">
      <div className="ms-panel">
        <div className="ms-panel-header">
          <h6>
            {" "}
            Order List {outletName ? `( ${outletName?.outletName} )` : ""}
          </h6>
        </div>
        <Row className="px-3 mt-3 mb-3">
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="cities">City:</label>
              <Select
                options={
                  cities &&
                  cities.length > 0 &&
                  cities.map((obj) => ({
                    label: obj.name,
                    value: obj._id,
                  }))
                }
                name="cities"
                onChange={(value) => {
                  setCityId(value.value);
                }}
              />
            </div>
          </div>
          {outletList?.length > 0 && (
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="cities">Select Outlet:</label>
                <Select
                  options={outletList?.map((obj) => ({
                    label: obj?.outletName,
                    value: obj?.outletId,
                  }))}
                  name="cities"
                  onChange={(value) => {
                    setOutletId(value?.value);
                  }}
                />
              </div>
            </div>
          )}
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="cities">Status:</label>
              <Select
                options={
                  status?.length > 0 &&
                  status?.map((obj) => ({
                    label: obj.name,
                    value: obj._id,
                  }))
                }
                name="cities"
                onChange={(value) => {
                  setStatus(value.value);
                }}
              />
            </div>
          </div>
          <Col md={2}>
            <div className="startDate">
              {/* date picker input */}
              <label htmlFor="validationCustom01" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                max={maxStartDate}
                onChange={handleStartDate}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="endDate">
              <label htmlFor="validationCustom01" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                name="endDate"
                placeholder="End Date"
                min={minEndDate}
                onChange={handleEndDate}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="endDate">
              <label htmlFor="validationCustom01" className="form-label">
                Booking Id
              </label>
              <input
                type="text"
                className="form-control"
                id="bookingId"
                name="bookingId"
                placeholder="Booking Id"
                onChange={handleBooking}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="endDate">
              <label htmlFor="validationCustom01" className="form-label">
                User's Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="User Name"
                onChange={handleUser}
              />
            </div>
          </Col>
          <Col md={2}>
            <button
              type="button"
              onClick={filterHandler}
              className="excle-dowm"
            >
              {" "}
              Filter{" "}
            </button>
          </Col>
        </Row>

        <div className="ms-panel-body">
          <div className="table-responsive">
            <table className="table table-hover thead-primary" ref={tableRef}>
              <thead>
                <tr>
                  <th scope="col">Sr.</th>
                  <th scope="col">View</th>

                  <th scope="col">Outlet Name</th>
                  <th scope="col">Outlet No.</th>
                  <th scope="col">Booking Number</th>
                  <th scope="col">User Name</th>
                  <th scope="col">User Phone Number</th>
                  <th scope="col">Members</th>
                  <th scope="col">Table Amount</th>
                  <th scope="col">Menu Amount</th>

                  <th scope="col">Booking Made On</th>
                  <th scope="col">Date & Time</th>
                  {/* <th scope="col">City</th> */}
                  <th scope="col">Booking For</th>
                  <th scope="col">Order Status</th>
                  <th scope="col">Is Verified</th>
                </tr>
              </thead>
              <tbody>
                {allOrders ? (
                  allOrders?.map((order, index) => {
                    return (
                      <tr key={index}>
                        <td scope="row">
                          {(currentPage - 1) * 10 + index + 1}
                        </td>

                        <td>
                          <button
                            type="button"
                            className="ms-btn-icon-outline btn-square btn-info ml-2"
                            onClick={() => {
                              history.push(
                                `/order-detail/${order.bookingId}`
                              );
                            }}
                          >
                            <i className="flaticon-information mr-0"></i>
                          </button>

                          {order?.bookingType == "delivery" && (
                            <button
                              type="button"
                              className="ms-btn-icon-outline btn-square btn-info ml-2"
                            >
                              <Link
                                to={{
                                  pathname: `/track-order/${order.deliveryId}`,
                                }}
                              >
                                <FaMapMarkedAlt />
                              </Link>
                            </button>
                          )}
                        </td>
                        <td>{order?.outletName}</td>
                        <td>{order?.outletMobile}</td>
                        <td>{order?.bookingNumber}</td>

                        <td>{order.userName}</td>
                        <td>{order.userMobile}</td>
                        <td>{order.person}</td>
                        <td>{order?.tablePaidAmount}</td>
                        <td>{order?.menuPaidAmount}</td>
                        <td>{order?.bookingMadeOn}</td>

                        <td>{order.date}</td>
                        <td>{order.bookingType}</td>

                        <td>
                          <div className="d-flex align-items-center justify-center gap-3">
                            <span
                              className={
                                order?.status == "pending"
                                  ? "badge badge-primary"
                                  : order?.status == "Cancel" ||
                                    order?.status == "cancel"
                                  ? "badge badge-danger"
                                  : order?.status == "Completed" ||
                                    order?.status == "completed"
                                  ? "badge badge-completed"
                                  : order?.status == "Upcoming"
                                  ? "badge badge-info"
                                  : order?.status == "accepted"
                                  ? "badge badge-success"
                                  : order?.status == "decline"
                                  ? "badge badge-danger"
                                  : ""
                              }
                            >
                              {upperCase(
                                order?.bookingLabel == "Cancel" ||
                                  order?.bookingLabel == "cancel"
                                  ? "Cancelled"
                                  : order?.bookingLabel
                              )}
                            </span>
                            {order?.status == "cancel" && (
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip({
                                  cancelReason: order?.cancelReason,
                                })}
                              >
                                <p className="m-0 ml-2">
                                  <FaInfoCircle />
                                </p>
                              </OverlayTrigger>
                            )}
                          </div>
                        </td>
                        <td>
                          <span
                            className={
                              order?.isVerified == "yes"
                                ? "badge badge-success"
                                : "badge badge-primary"
                            }
                          >
                            {upperCase(order?.isVerified)}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7">No Orders Found</td>
                  </tr>
                )}
              </tbody>
            </table>
            {currentItems && (
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingList;
