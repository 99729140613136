import React, {useEffect, useState} from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";

const ResetPassword = (props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        resetField,
        setError
      } = useForm();

    const { token } = props.match.params;
    const history   = useHistory();

    const { response, request } = useRequest();
    const [touchedError, setTouchedError]               = useState(false)
    const [isPasswordVisible, setIsPasswordVisible]     = useState({
                                                            newPassword:false,
                                                            confirmNewPassword:false
                                                        });


    useEffect(() => {
        document.title = "Reset Password - Sportyfy";
      }, []);

    const onSubmit = (data) => {
        const { newPassword, confirmNewPassword } = data;

        if(newPassword != confirmNewPassword){
            setError("confirmNewPassword",{type:"manual"})
            return;
        }
        request("POST", "admin/resetPassword", { resetToken:token,newPassword});
    };

    useEffect(() => {
        if (response) {
            if(response.status){
                toast.success("Password has been reset successfully.");
                history.push("/login");
            }
        }
      }, [response]);

    return (
        <div className="ms-content-wrapper ms-auth">
        <div className="ms-auth-container">
        
            <div className="ms-auth-col">
                <div className="ms-auth-form">
                    <form className="needs-validation" noValidate  onSubmit={handleSubmit(onSubmit)}>
                        {/* <div className="mb-3"><img src={"/assets/img/sortyfy2.jpeg"} /></div> */}
                        <h3>Reset Password</h3>
                        <div className="mb-2">
                            <label htmlFor="newPassword">New Password<span className="field-required">*</span></label>
                            <div className="input-group">
                                <input type={isPasswordVisible.newPassword ? "text" : "password"} name="newPassword" id="newPassword" placeholder="New Password" 
                                  className={`form-control ${
                                    touchedError ? ( errors.newPassword ? "is-invalid" : "is-valid" ): "" 
                                  }`}
                                 {...register("newPassword", {
                                    required: true,
                                    // pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                                  })} />

                                  
                                <Link to="#" onClick={() => {
                                    const update = {...isPasswordVisible, newPassword : isPasswordVisible.newPassword ? false :true}
                                    setIsPasswordVisible(update)
                                    }}>
                                    {isPasswordVisible.newPassword? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                                </Link>
                                 {errors.newPassword?.type === "required" && (
                                    <div className="invalid-field">
                                        The password field is required.
                                    </div>
                                )}
                                {/* {errors.newPassword?.type === "pattern" && (
                                    <div className="invalid-field">
                                        Password must be of 8 characters long with atleast one uppercase, one lowercase and one number.
                                    </div>
                                )} */}
                            </div>
                        </div>
                      
                        <div className="mb-2">
                            <label htmlFor="confirmNewPassword">Confirm New Password<span className="field-required">*</span></label>
                            <div className="input-group">
                                <input type={isPasswordVisible.confirmNewPassword ? "text" : "password"} name="confirmNewPassword" id="confirmNewPassword" placeholder="Confirm New Password" 
                                  className={`form-control ${
                                    touchedError ? ( errors.confirmNewPassword ? "is-invalid" : "is-valid" ): "" 
                                  }`}
                                 {...register("confirmNewPassword", {
                                    required: true,
                                    // pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                                  })} />

                                  
                                <Link to="#" onClick={() => {
                                    const update = {...isPasswordVisible, confirmNewPassword : isPasswordVisible.confirmNewPassword ? false :true}
                                    setIsPasswordVisible(update)
                                    }}>
                                    {isPasswordVisible.confirmNewPassword? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                                </Link>
                                 {errors.confirmNewPassword?.type === "required" && (
                                    <div className="invalid-field">
                                        The confirm password field is required.
                                    </div>
                                )}
                                 {errors.confirmNewPassword?.type === "manual" && (
                                    <div className="invalid-field">
                                        New password and confirm new password does not match.
                                    </div>
                                )}
                                {/* {errors.confirmNewPassword?.type === "pattern" && (
                                    <div className="invalid-field">
                                        Password must be of 8 characters long with atleast one uppercase, one lowercase and one number.
                                    </div>
                                )} */}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-primary mt-4 d-block w-100" type="submit" onClick={() => setTouchedError(true)}>Reset</button>
                            </div>
                            <div className="col-md-3">
                                <Link className="btn btn-primary mt-4 d-block w-100" to="/login">Cancel</Link>
                            </div>
                            <div className="col-md-8"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ResetPassword
