import React, { useState } from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import AddRewardModal from "./AddRewardModal";
import { Button } from "react-bootstrap";

function List() {
  const [columns] = useState([
    { title: "S.No." },
    { title: "Amount" },
    { title: "Point" },
    { title: "Action" },
  ]);
  const [data, setData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [rewardData, setRewardData] = useState({ amount: 100, point: 50 });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSaveChanges = (data) => {
    // Save the data (CRUD operation)
    setRewardData(data);
    handleCloseModal();
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title={"Rewards"} links={[{ to: "/", name: "Home" }]} />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-1">
                  <h6>{"Rewards"}</h6>
                </div>
                <div className="col-md-9"></div>
                <div className="col-md-2">
                  <div className="form-group">
                    <Button
                      className="btn btn-primary"
                      onClick={handleShowModal}
                    >
                      {" "}
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {" "}
                          No data found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddRewardModal
        show={showModal}
        handleClose={handleCloseModal}
        handleSave={handleSaveChanges}
        initialData={rewardData}
      />
    </div>
  );
}

export default List;
