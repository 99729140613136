import React from 'react'
import { Carousel } from 'react-bootstrap'

const ImageSlider = ({displayImage,image}) => {

  return (
    <>
         {(displayImage.length>0 || image.length>0) && (
            <Carousel id="imagesSlider" className="ms-image-slider carousel slide" data-ride="carousel" indicators={false}>
                {displayImage.length>0 && displayImage.map((obj,i) => (
                    <Carousel.Item key={i}>
                    <img className="d-block w-100" src={obj} alt="First slide" style={{height:'200px'}} />
                    </Carousel.Item>
                ))}
            </Carousel>
        )}
     </>
    
  )
}

export default ImageSlider
