import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import useRequest from "../../../hooks/useRequest";
import { capitalizeFirstLetter } from "../../../util/fn";
import Pagination from "../../Pagination/Pagination";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { BASEURL } from "../../../constant/api";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";

const ViewAll = (props) => {
  const { vendorId } = props?.location?.state;
  const { id: outletId } = props.match.params;
  const { request: requestCategories, response: responseCategories } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  const { request: request, response: response } = useRequest();
  const { request: requestImportMenu, response: responseImportMenu } =
    useRequest();

  const [pdfData, setPdfData] = useState("");
  const [data, setData] = useState([]);
  const [menuIds, setMenuIds] = useState([]);

  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "" },
    { title: "Cuisine" },
    { title: "Menu Items" },
    { title: "Category" },
    { title: "Ingredients" },
    { title: "Description" },
    { title: "Price" },
    { title: "Picture" },
    { title: "Status" },
    { title: "Type" },
    { title: "Action" },
  ]);
  const [cuisineCategories, setCuisineCategories] = useState([]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [cuisine, setCuisine] = useState();
  const [foodType, setFoodType] = useState();
  const [headerData, setHeaderData] = useState({
    totalItem: 0,
    totalFood: 0,
    totalBeverage: 0,
  });
  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    document.title = "Menus - Sportyfy";
    requestCategories("POST", "manageCategory/getCategorys", {
      type: "cuisine",
    });
    request("POST", `admin/menu/getAllMenu`, {
      outletId,
      cusineId: "",
      foodType: "",
      page,
      per_page: perPage,
    });
  }, []);

  useEffect(() => {
    if (responseImportMenu) {
      if (responseImportMenu.status == "success") {
        request("POST", `admin/menu/getAllMenu`, {
          outletId,
          cusineId: "",
          foodType: "",
          page,
          per_page: perPage,
        });
        toast.success(responseImportMenu.message);
      }
    }
  }, [responseImportMenu]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setPdfData(response?.pdfMenuArray);
        setTotalDocuments(response.totalDocument);
        setHeaderData({
          totalItem: response.totalItem ? response.totalItem : 0,
          totalFood: response.totalFood ? response.totalFood : 0,
          totalBeverage: response.totalBeverage ? response.totalBeverage : 0,
        });
      }
    }
  }, [response]);

  useEffect(() => {
    if (responseCategories && responseCategories.status) {
      setCuisineCategories(responseCategories.data);
    }
  }, [responseCategories]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }
    request("POST", `admin/menu/getAllMenu`, {
      outletId: outletId,
      cusineId: cuisine ? cuisine : "",
      foodType: foodType ? foodType : "",
      page: selected + 1,
      per_page: perPage,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let searchCuisine = cuisine;
    let searchType = foodType;

    if (e.target.name == "cuisine") {
      setCuisine(e.target.value);
      searchCuisine = e.target.value;
    } else if (e.target.name == "foodType") {
      setFoodType(e.target.value);
      searchType = e.target.value;
    }

    searchCuisine = searchCuisine ?? "";
    searchType = searchType ?? "";

    request("POST", `admin/menu/getAllMenu`, {
      outletId: outletId,
      cusineId: searchCuisine ? searchCuisine : "",
      foodType: searchType ? searchType : "",
      page,
      per_page: perPage,
    });
  };

  const handleDownloadAll = () => {
    pdfData.forEach((pdf, index) => {
      setTimeout(() => {
        window.open(pdf.src, "_blank");
      }, index * 500);
    });
  };

  const UpdateImage = async (files, id) => {
    const token = localStorage.getItem("token");

    const formdata = new FormData();
    formdata.append("menuId", id);
    // Append all files to FormData
    files.forEach((file) => {
      formdata.append("image", file);
    });
    const response = await axios.post(
      `${BASEURL.PORT}/admin/menu/updateMenuImage`,
      formdata,

      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (response?.data?.status == "success") {
      request("POST", `admin/menu/getAllMenu`, {
        outletId: outletId,
        cusineId: cuisine ? cuisine : "",
        foodType: foodType ? foodType : "",
        page,
        per_page: perPage,
      });
    }
  };

  const handleDelete = () => {
    console.log("dddd");
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        requestDelete("POST", "admin/menu/removeMenu", { menuId: menuIds });
      }
    });
  };

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status) {
        toast.success("Banner has been deleted successfully.");
        request("POST", `admin/menu/getAllMenu`, {
          outletId: outletId,
          cusineId: "",
          foodType: "",
          page,
          per_page: perPage,
        });
      }
    }
  }, [responseDelete]);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Menus"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/vendors", name: "Vendors" },
              { to: `/vendor/total-outlets/${vendorId}`, name: "Outlets" },
            ]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-1">
                  <h6>{"Menus"}</h6>
                </div>

                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="cuisine">Cuisine:</label>
                    <select
                      className="form-control"
                      id="cuisine"
                      name="cuisine"
                      onChange={handleSearch}
                    >
                      <option value={""}>Select Cuisine</option>
                      {cuisineCategories &&
                        cuisineCategories.length > 0 &&
                        cuisineCategories.map((obj) => (
                          <option key={obj._id} value={obj._id}>
                            {obj.name ? capitalizeFirstLetter(obj.name) : ""}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="foodType">Food Type:</label>
                    <select
                      className="form-control"
                      id="foodType"
                      name="foodType"
                      onChange={handleSearch}
                    >
                      <option value={""}>Select Food Type</option>
                      <option value={"food"}>Food</option>
                      <option value={"beverage"}>Beverage</option>
                    </select>
                  </div>
                </div>
                {pdfData && (
                  <div className="col-md-2">
                    {/* <a
                      href={pdfData}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    > */}
                    <button
                      onClick={handleDownloadAll}
                      className="btn btn-primary mt-4 d-block w-100"
                    >
                      Download PDF
                    </button>
                    {/* </a> */}
                  </div>
                )}
                <div className="col-md-2 mt-4">
                  <span className="uploadInput">
                    <input
                      type="file"
                      name="uploadfile"
                      id="uploadFile"
                      accept=".xlsx"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        // if (cuisine) {
                        const form = new FormData();
                        form.append("outletId", outletId);
                        // form.append("cusineId", cuisine);
                        if (e.target.files && e.target.files[0]) {
                          form.append("image", e.target.files[0]);
                        }
                        setPage(1);
                        requestImportMenu(
                          "POST",
                          "admin/menu/uploadExcelMenu",
                          form
                        );
                        // } else {
                        //   toast.error("Please Select Cuisine");
                        // }
                      }}
                    />
                    <label
                      className="excle-dowm-new  m-0 text-center"
                      htmlFor="uploadFile"
                    >
                      Import Menu
                    </label>
                  </span>
                  <span id="uploadedfileName" />
                </div>
                <div className="col-md-2 mt-2">
                  {menuIds?.length > 0 && (
                    <div >
                      <span className="text-delete">
                        <button
                          className="btn btn-primary"
                          onClick={handleDelete}
                        >
                          Delete
                        </button>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                    <div className="ms-card-body media">
                      <div className="media-body">
                        <span className="black-text">
                          <strong>Total Items</strong>
                        </span>
                        <h2>{headerData.totalItem}</h2>
                      </div>
                    </div>
                    {/* <LineChart data={this.state.data1} options={options} /> */}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                    {/* <span className="ms-chart-label bg-red">
                                        <i className="material-icons">arrow_downward</i>{" "}
                                        4.5%
                                    </span> */}
                    <div className="ms-card-body media">
                      <div className="media-body">
                        <span className="black-text">
                          <strong>Total Food</strong>
                        </span>
                        <h2>{headerData.totalFood}</h2>
                      </div>
                    </div>
                    {/* <LineChart data={this.state.data2} options={options} /> */}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                    <div className="ms-card-body media">
                      <div className="media-body">
                        <span className="black-text">
                          <strong>Total Beverage</strong>
                        </span>
                        <h2>{headerData.totalBeverage}</h2>
                      </div>
                    </div>
                    {/* <LineChart data={this.state.data3} options={options} /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item._id}>
                          <th scope="row">{i + startIndex}</th>
                          <td>
                            <input
                              type="checkbox"
                              checked={menuIds?.includes(item?._id)}
                              onChange={(e) => {
                                if (menuIds?.includes(item?._id)) {
                                  setMenuIds((pre) =>
                                    pre.filter((id) => id !== item?._id)
                                  );
                                } else {
                                  setMenuIds((pre) => [...pre, item?._id]);
                                }
                              }}
                            />
                          </td>
                          <td>
                            {item.cuisine
                              ? capitalizeFirstLetter(item.cuisine)
                              : ""}
                          </td>
                          <td>
                            {item.name ? capitalizeFirstLetter(item.name) : ""}
                          </td>
                          <td>
                            {item.foodCategory
                              ? capitalizeFirstLetter(item.foodCategory)
                              : ""}
                          </td>
                          <td>
                            {item.ingredients
                              ? capitalizeFirstLetter(item.ingredients)
                              : ""}
                          </td>
                          <td>
                            {item.description
                              ? capitalizeFirstLetter(item.description)
                              : ""}
                          </td>
                          <td>{item.fullPrice}</td>

                          <td>
                            {item?.image[0] != "" ? (
                              <img src={item?.image[0]} alt="" />
                            ) : (
                              <input
                                type="file"
                                name="image"
                                multiple
                                accepr="image/*"
                                placeholder="Select images..."
                                onChange={(e) => {
                                  const files = [...e.target.files];

                                  UpdateImage(files, item._id);
                                  {
                                    /* Passing individual file */
                                  }
                                }}
                              />
                            )}
                          </td>
                          <td>
                            {item.status == "active" ? (
                              <span className="badge badge-success">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : item.status == "inactive" ? (
                              <span className="badge badge-danger">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {item.foodType
                              ? capitalizeFirstLetter(item.foodType)
                              : ""}
                          </td>
                          <td>
                            <Link
                              to={`/vendor/edit-menu/${item?._id}/${item?.outletId}`}
                            >
                              <FaEdit />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colspan="8" style={{ textAlign: "center" }}>
                          {" "}
                          No menu has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {perPage !== 0 &&
            data.length != totalDocuments &&
            data.length !== 0 && (
              <Pagination
                page={page}
                totalDocuments={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                currentDocLength={data.length}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
