import React,{useEffect, useState} from 'react'
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from '../Breadcrumb/Breadcrumb';

const Add = () => {
    const history = useHistory();

    const { response:responseUpdate, request:requestUpdate }                            =   useRequest();
    const {request:requestMainCat, response: responseMainCat}                           =   useRequest();
    const [touchedError, setTouchedError]                                               =   useState(false);
    const [mainCategory, setMainCategory]                                               =   useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        getValues,
        control,
        clearErrors
    } = useForm();

    useEffect(() => {
        document.title = "Add Category - Sportyfy";
        requestMainCat("GET","manageCategory/GetAllParentCategory")
    },[])

    useEffect(() => {
      if(responseMainCat){
        if(responseMainCat.status && responseMainCat.data){
            setMainCategory(responseMainCat.data);
        }
      }
    },[responseMainCat])
    
    const onSubmit = (data) => {
        const {type, name}   =   data;
        requestUpdate("POST", "manageCategory/addCategory",{type, name, status:"active"} );
    }

    useEffect(() => {
        if (responseUpdate) {
            if(responseUpdate.status){
                toast.success("Category has been added successfully.");
                history.push("/categories")
            }
        }
    }, [responseUpdate]);
    
  
    return (
      <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
        <Breadcrumb
                  title={"Add"}
                  links={[
                      { to: "/dashboard", name: "Home" },
                      { to: "/categories", name: "Categories" },
                  ]}
              />
           
        </div>
        <div className="col-xl-12 col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Add Category</h6>
                </div>
                <div className="ms-panel-body">
                    <form className="needs-validation clearfix" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="type">Main Category<span className="field-required">*</span></label>
                                <div className="input-group">
                                  <select {...register("type", {
                                        required: true,
                                    })}  className={`form-control ${
                                        touchedError ? ( errors.type ? "is-invalid" : "is-valid" ): "" 
                                      }`}  id="mainCategory">
                                        <option value={""}>Select</option>
                                        {mainCategory && mainCategory.length>0 && mainCategory.map(obj => (
                                          <option key={obj._id} value={obj._id}>{obj.name}</option>
                                        ))}
                                    </select>
                                    
                                      {errors.type?.type === "required" && (
                                        <div className="invalid-field">
                                            The main category field is required.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="name">Name<span className="field-required">*</span></label>
                                <div className="input-group">
                                    <input type="text" id="name" placeholder="Name" 
                                        className={`form-control ${
                                            touchedError ? ( errors.name ? "is-invalid" : "is-valid" ): "" 
                                        }`}
                                        {...register("name", {
                                            required: true,
                                        })}/>
                                         {errors.name?.type === "required" && (
                                            <div className="invalid-field">
                                                The name field is required.
                                            </div>
                                        )}
                                </div>
                            </div>
                            <button className="btn btn-primary mt-4 d-block w-50d" onClick={() => setTouchedError(true)} type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
        </div>
    </div>
  )
}

export default Add
